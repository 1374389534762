import {
  Form,
  Carousel,
  Col,
  message,
  Input,
  Modal,
  Row,
  Button,
  Switch,
} from "antd";
import "antd/dist/antd.css";
import React, { useState, useRef, useEffect } from "react";

import { toAddBeautyPro } from "./APIs";
import Autocomplete from "react-google-autocomplete";

const BecamePro = ({ isModalVisible, closeModal }) => {
  const [form] = Form.useForm();
  const [displayError, setDisplayError] = useState(false);
  const [tabs, setTabs] = useState([
    {
      name: "L",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
    {
      name: "Ma",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
    {
      name: "M",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
    {
      name: "J",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
    {
      name: "V",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
    {
      name: "S",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
    {
      name: "D",
      start: { h: 10, m: 0 },
      startBreak: { h: 12, m: 0 },
      endBreak: { h: 14, m: 0 },
      end: { h: 20, m: 0 },
    },
  ]);
  const [department, setDepartment] = useState(-1);

  const save = () => {
    if (department == -1 || department == "") {
      return;
    }

    var json = form.getFieldsValue();

    json.adress = department;
    toAddBeautyPro(json).then((res) => {
      window.fbq("track", "Subscribe", {
        value: "0.00",
        currency: "EUR",
      });
      if (res.status == 303) {
        message.error({
          content: "Cette adresse mail est déjà utilisée",
        });
      } else {
        message.success({
          content: "Ton compte a été crée",
        });
        closeModal();
      }
    });
  };

  const verifCGU = (rule, value, callback) => {
    if (value != true) {
      callback("");
      setDisplayError(true);
      return;
    }
    setDisplayError(false);
    callback();
  };

  const toUpdateDepartment = (place) => {
    setDepartment(place.formatted_address);
    var json = form.getFieldsValue();

    json.adress = place.formatted_address;
    form.setFieldsValue(json);
  };

  return (
    <>
      <Modal
        className="bam--modal-big"
        visible={isModalVisible}
        footer={null}
        onCancel={() => closeModal()}
      >
        <Row>
          <Col span="1" />
          <Col span="22">
            <Form onFinish={save} form={form} layout={"vertical"}>
              <Row>
                <Col xl={24} md={24} xs={24}>
                  <Row style={{ marginTop: "2vh" }}>
                    <span className="bam--home-title">Devenir pro</span>
                  </Row>
                  <Row style={{ marginTop: "3vh" }}>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre nom",
                          },
                        ]}
                        name="firstName"
                        label="Nom"
                        className="bam--label-form"
                      >
                        <Input
                          className="bam--input"
                          placeholder="Tapez votre nom"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre prénom",
                          },
                        ]}
                        name="lastName"
                        label="Prénom"
                        className="bam--label-form"
                      >
                        <Input
                          className="bam--input"
                          placeholder="Tapez votre prenom"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre email",
                          },
                        ]}
                        name="email"
                        label="Email"
                        className="bam--label-form"
                      >
                        <Input
                          className="bam--input"
                          placeholder="Tapez votre adresse email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre mot de passe",
                          },
                        ]}
                        name="password"
                        label="Mot de passe"
                        className="bam--label-form"
                      >
                        <Input.Password
                          className="bam--input"
                          visibilityToggle={false}
                          placeholder="Tapez votre mot de passe"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre mot de passe",
                          },
                        ]}
                        name="password_confirm"
                        label="Confirmez le mot de passe"
                        className="bam--label-form"
                      >
                        <Input.Password
                          className="bam--input"
                          visibilityToggle={false}
                          placeholder="Veuillez confirmer votre mot de passe"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>{" "}
              <Row>
                <Col xl={24} md={24} xs={24}>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "merci d'entrer le nom de votre entreprise",
                          },
                        ]}
                        name="name"
                        label="Nom de l'entreprise"
                        className="bam--label-form"
                      >
                        <Input
                          placeholder="Tapez le nom de votre entreprise"
                          className="bam--input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre adresse",
                          },
                        ]}
                        label="Adresse"
                        name="adress"
                        className="bam--label-form"
                      >
                        <Autocomplete
                          apiKey={"AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0"}
                          placeholder="Renseignez votre adresse"
                          className="bam--input ant-input"
                          options={{ types: ["establishment", "geocode"] }}
                          style={{ width: "100%" }}
                          onPlaceSelected={(place) => {
                            toUpdateDepartment(place);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "merci d'entrer votre téléphone",
                          },
                        ]}
                        name="tel"
                        label="Téléphone"
                        className="bam--label-form"
                      >
                        <Input
                          className="bam--input"
                          type="tel"
                          placeholder="Tapez votre téléphone"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span="19">
                      <span className="bam--label-form">
                        En cochant la case suivante, j’accepte les{" "}
                        <a href="/cgu" target="_blank">
                          {" "}
                          Conditions Générales de BAM.
                        </a>
                      </span>
                      {displayError && (
                        <div
                          class="ant-form-item-explain ant-form-item-explain-error"
                          style={{ float: "right" }}
                        >
                          <div role="alert">
                            Veuillez cocher la case ci-dessus
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col span="5">
                      <Form.Item
                        style={{ float: "right" }}
                        rules={[
                          {
                            required: true,
                            message: "",
                            validator: verifCGU,
                          },
                        ]}
                        name="cgu"
                        className="bam--label-form"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="end">
                <Form.Item>
                  <Button
                    className="bam--button-carrousel-text"
                    htmlType="submit"
                  >
                    Valider
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Col>
          <Col span="1" />
        </Row>
      </Modal>
    </>
  );
};
export default BecamePro;
