import { Form, Col, Button, Row, Input, Divider, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  createPrestation,
  getDetails,
  getPrestationsAll,
  getPrestationsBeautyAll,
  addCategoryAPI,
  addPrestationAPI,
  toDeletePrestationBeautyPro,
  getActifPrestations
} from "../ProfBeaute/APIs";
import ReactGA from "react-ga";

import PrestationItem from "./PrestationItem";
import { openNotification } from "../../utils/Utils";
import { LoadingOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MesPrestations = () => {
  const [form] = Form.useForm();
  const [formP] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [prestations, setPrestations] = useState([]);
  const [list, setList] = useState([]);
  const [forms, setForms] = useState([]);
  const [selectedId, setSelectedId] = useState(-1);
  const [disabled, setDisabled] = useState(true);
  const [details, setDetails] = useState([]);
  const [categoryToAdd, setCategoryToAdd] = useState("");
  const [load, setLoad] = useState(true);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getListOfPrestations();
    getPrestationsBeauty();
    getAllDetails();
    setLoad(false);
  }, []);
  const getListOfPrestations = () => {
    getPrestationsAll().then((res) => {
      setPrestations(res);
    });
  };
  const getAllDetails = () => {
    getDetails().then((res) => {
      setDetails(res);
    });
  };
  const getPrestationsBeauty = () => {
    getPrestationsBeautyAll().then((res) => {
      setList(res);
      if (
        null != form.getFieldValue("prestationId") &&
        form.getFieldValue("prestationId") != "Tout"
      ) {
        setForms(
          res.filter(
            (el) => el.categoryId == form.getFieldValue("prestationId")
          )
        );
      } else {
        setForms(res);
      }
    });
  };

  const onChange = (e) => {
    setLoad(true);
    setForms([]);

    if (e == "Tout") {
      setTimeout(() => {
        setForms(list);
        setSelectedId(-1);
        setLoad(false);
      }, 500);
      return;
    }
    setTimeout(() => {
      setForms(list.filter((el) => el.categoryId == e));
      setSelectedId(e);
      setLoad(false);
    }, 500);
  };
  const addForm = () => {
    var l__forms = [...forms];

    l__forms.push({
      name: "",
      description: "",
      price: "",
      duration: "",
      hour: 0,
      minute: 0,
      prestationId: ""
    });
    setForms(l__forms);
  };

  const deleteForm = (i) => {
    var l__forms = [...forms];
    toDeletePrestationBeautyPro(l__forms[i].id).then(() => {
      l__forms.splice(i, 1);
      setForms(l__forms);
      openNotification("Succès!", "La prestation a été suprimée avec succès.");
    });
  };
  const onChangeParent = (i, data) => {
    var l__forms = [...forms];
    l__forms[i] = data;
    setForms(l__forms);
    setDisabled(checkIfEmpty(l__forms));
  };

  const checkIfEmpty = (l__forms) => {
    var res = false;

    l__forms.forEach((element) => {
      if (
        element.prestationId == "" ||
        element.price == "" ||
        (element.hour == "" && element.minute == "")
      ) {
        res = true;
      }
    });
    return res;
  };

  const save = () => {
    createPrestation({ datas: forms, prestationId: selectedId }).then(() => {
      openNotification("Succès!", "La prestation a été ajoutée avec succès.");
      window.location.reload();
    });
  };

  const addCategory = () => {
    addCategoryAPI({ name: formP.getFieldValue("prestation") }).then((res) => {
      setCategoryToAdd("");
      getListOfPrestations();
      openNotification(
        "Succès!",
        "La demande d'ajout de catégorie a bien été prise en compte. Une fois cette catégorie validée par notre équipe, elle sera visible sur votre profil."
      );
    });
    setModalVisible(false);
  };

  const addPrestation = (value, selectedId) => {
    addPrestationAPI({ name: value, id: selectedId }).then((res) => {
      openNotification(
        "Succès!",
        "La demande d'ajout de prestation a bien été prise en compte. Une fois cette prestation validée par notre équipe, elle sera visible sur votre profil."
      );
      getAllDetails();
    });
  };

  return (
    <>
      <Row>
        <Col xl={6} md={10} xs={24}>
          <Form form={form}>
            <Form.Item
              label="Choisir la catégorie"
              className="bam--label-form"
              name="prestationId"
            >
              <Select
                onChange={onChange}
                defaultValue={"Tout"}
                dropdownRender={(menu) => (
                  <div>
                    {menu}

                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8
                      }}
                    >
                      <Button
                        className=""
                        style={{
                          flex: "none",
                          marginLeft: "8px",
                          display: "block",
                          cursor: "pointer"
                        }}
                        onClick={() => setModalVisible(true)}
                        className="bam--button-carrousel-text"
                      >
                        Ajouter une catégorie
                      </Button>
                    </div>
                  </div>
                )}
              >
                <Select.Option value="Tout">Tout</Select.Option>

                {prestations.map((p, i) => (
                  <Select.Option value={p.id}>{p.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col xl={1} md={1} xs={0} />
        <Col xl={6} md={10} xs={24}>
          <Button disabled={selectedId == -1} onClick={() => addForm()}>
            Ajouter une prestation
          </Button>
        </Col>
      </Row>
      {load && <Spin indicator={antIcon} />}

      {selectedId != -1 && forms.length > 0 && (
        <p className="bam--text">
          Afin de prévoir le temps de déplacement d’un rendez-vous à un autre,
          nous vous invitons à rajouter <b>15</b> minutes de durée à chaque
          prestation, ou plus selon la ville où vous vous situez
        </p>
      )}
      {forms.length > 0 &&
        forms.map((f, i) => (
          <div style={{ marginTop: "2vh" }}>
            <PrestationItem
              addPrestation={(value) => addPrestation(value, selectedId)}
              details={
                selectedId == -1
                  ? details
                  : details.filter((el) => el.categoryId == selectedId)
              }
              selectedId={selectedId}
              onChangeParent={onChangeParent}
              json={f}
              i={i}
              deleteForm={deleteForm}
            />
          </div>
        ))}
      {selectedId != -1 && forms.length > 0 && (
        <div style={{ textAlign: "center" }}>
          <Button
            disabled={selectedId == -1 || forms.length == 0}
            style={{ marginTop: "2vh", textAlign: "center" }}
            onClick={() => save()}
            disabled={disabled}
            className="bam--button-carrousel-text"
          >
            Enregistrer
          </Button>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <Row justify="center">
            <Button
              loading={loading}
              htmlType="submit"
              className="bam--button-carrousel-text"
              disabled={formP.getFieldValue("prestation") == ""}
              onClick={() =>
                formP.getFieldValue("prestation") != "" && addCategory()
              }
            >
              <span>Enregistrer</span>
            </Button>
          </Row>
        }
      >
        <Form form={formP} layout={"vertical"} style={{ marginTop: "2vh" }}>
          <Form.Item
            name="prestation"
            label="Catégorie"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "Champ obligatoire"
              }
            ]}
          >
            <Input
              className="bam--input"
              visibilityToggle={false}
              placeholder="Tapez le nom de la catégorie"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default MesPrestations;
