import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import history from "../../utils/History";
import LeftNavLayout from "./LeftNavLayout";
import Nav from "./Nav";

const EspacePro = ({ location, ...props }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/espacepro/**"
          exact={true}
          render={({ location }) => (
            <LeftNavLayout>
              <Nav location={location} />
            </LeftNavLayout>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default EspacePro;
