import React, { useEffect, useLayoutEffect, useState } from "react";
import { Row, Col, Button, Collapse } from "antd";
import ReactGA from "react-ga";
import bampro from "../Styles/images/bampro.png";
import bampro1 from "../Styles/images/imgBamPro1.svg";
import bampro1Mobile from "../Styles/images/imgBamPro1Mobile.svg";
import capture from "../Styles/images/captureBam.svg";
import captureMobile from "../Styles/images/captureMobile.svg";
import site from "../Styles/images/site.svg";
import agendaOrange from "../Styles/images/agendaOrange.svg";
import visibilite from "../Styles/images/visibilite.svg";
import tableau from "../Styles/images/tableau.svg";
import client from "../Styles/images/client.svg";
import avisClient from "../Styles/images/avisClient.svg";
import up from "../Styles/images/up.svg";
import plus from "../Styles/images/plus.svg";
import close from "../Styles/images/close.svg";
import img1 from "../Styles/images/img1.png";
import img2 from "../Styles/images/img2.png";
import BecamePro from "./ProfBeaute/BecamePro";

const { Panel } = Collapse;
const style = {
  height: 44,
  width: 44,
  lineHeight: "40px",
  borderRadius: 6,
  backgroundColor: "#52C99F",
  textAlign: "center"
};
const BAMPro = () => {
  const [mobile, setMobile] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const [isBecameModalVisible, setIsBecameModalVisible] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.scroll(0, 0);
  }, []);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 800) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <>
      <Row justify="center" style={{ marginTop: "6vh", paddingBottom: "4vh" }}>
        <Col md={12} xl={12} xs={24}>
          <div className={!mobile && "bam--content"}>
            <p
              className="bam--pro-title1"
              style={{ textAlign: mobile ? "center" : "" }}
            >
              Augmentez votre chiffre d’affaires,
              {!mobile && <br />}
            </p>
            <p
              className="bam--pro-title2"
              style={{ textAlign: mobile ? "center" : "" }}
            >
              grâce à votre nouvel outil de gestion d’activité.
            </p>
            <p
              className="bam--pro-description"
              style={{ marginTop: mobile ? "2vh" : "4vh" }}
            >
              Simple, rapide et adapté aux professionnels de la beauté et du
              bien-être à domicile.
            </p>
            <div
              style={{ marginTop: mobile ? "4vh" : "6vh", textAlign: "center" }}
            >
              <Button
                onClick={() => {
                  window.fbq("track", "Subscribe", {
                    value: "0.00",
                    currency: "EUR"
                  });
                  setIsBecameModalVisible(true);
                }}
                className="bam--pro-bt"
              >
                Essayer gratuitement
              </Button>

              <p
                className="bam--pro-description"
                style={{ fontSize: "1rem", marginTop: "4vh" }}
              >
                3 mois d'abonnement offerts.
              </p>
            </div>
          </div>
        </Col>
        <Col md={12} xl={12} xs={24} style={{ textAlign: "center" }}>
          <img
            style={{
              marginTop: mobile ? "6vh" : "0vh"
            }}
            src={mobile ? bampro1Mobile : bampro1}
          />
        </Col>
      </Row>
      <div className="bam--pro-background" style={{ marginTop: "6vh" }}>
        <Row>
          <Col md={1} xl={1} xs={1}></Col>
          <Col md={22} xl={22} xs={22}>
            <img src={bampro} />
          </Col>
        </Row>
        {mobile ? (
          <Row style={{ paddingBottom: "6vh" }}>
            <Col md={12} xl={12} xs={24} style={{ textAlign: "center" }}>
              <img src={mobile ? captureMobile : capture} />
            </Col>
            <Col md={12} xl={12} xs={24}>
              <p className="bam--bampro-patagraph">
                BAM est un outil de gestion destiné aux professionnels de la
                beauté et du bien-être qui exercent à domicile, chez eux ou chez
                le client.
              </p>
              <p className="bam--bampro-patagraph">
                BAM vous met également en relation avec les clients pour la pour
                réservation de prestatios à domicile en quelques clics, 24h/24
                et 7j/7.
              </p>

              <p className="bam--bampro-patagraph">
                En tant qu’entreprise à taille humaine, BAM vous propose une
                solution de gestion sans commission sur vos prestations et un
                SAV multicanal à votre disposition (Livechat, téléphone, e-mail,
                réseaux sociaux…). Parce que nous avons compris que votre
                développement professionnel ne doit pas dépendre des
                commissionnements de vos outils.
              </p>
            </Col>
          </Row>
        ) : (
          <Row style={{ paddingBottom: "4vh" }}>
            <Col md={3} xl={3} xs={3}></Col>

            <Col md={20} xl={8} xs={20}>
              <p className="bam--bampro-patagraph">
                BAM est un outil de gestion destiné aux professionnels de la
                beauté et du bien-être qui exercent à domicile, chez eux ou chez
                le client.
              </p>
              <p className="bam--bampro-patagraph">
                BAM vous met également en relation avec les clients pour la pour
                réservation de prestatios à domicile en quelques clics, 24h/24
                et 7j/7.
              </p>

              <p className="bam--bampro-patagraph" style={{ marginTop: "4vh" }}>
                En tant qu’entreprise à taille humaine, BAM vous propose une
                solution de gestion sans commission sur vos prestations et un
                SAV multicanal à votre disposition (Livechat, téléphone, e-mail,
                réseaux sociaux…). Parce que nous avons compris que votre
                développement professionnel ne doit pas dépendre des
                commissionnements de vos outils.
              </p>
            </Col>
            <Col md={2} xl={2} xs={2}></Col>
            <Col md={23} xl={11} xs={23} style={{ textAlign: "center" }}>
              <img src={capture} />
            </Col>
          </Row>
        )}
      </div>
      <Row style={{ marginTop: "6vh" }}>
        <Col span="24" style={{ textAlign: "center" }}>
          {mobile ? (
            <span className="bam--bampro-title">
              Concentrez-vous sur vos prestations et gagnez du temps entre vos
              rendez-vous, grâce à BAM Pro !
            </span>
          ) : (
            <span className="bam--bampro-title">
              Concentrez-vous sur vos prestations et gagnez du
              <br /> temps entre vos rendez-vous, grâce à BAM Pro !
            </span>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "4vh" }}>
        <Col span="4"></Col>
        <Col span="16">
          <Row style={{ marginTop: "2vh", textAlign: "center" }}>
            <Col md={12} xl={8} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  <img src={site} />
                </div>

                <p className="bam--bampro-title-section">
                  Profil personnalisable
                </p>
                <p className="bam--bampro-paragraph-section">
                  Personnalisez toutes les informations relatives à votre
                  activité professionnelle : photos, prestations, horaires,
                  prix, zone d'activité...
                </p>
              </div>
            </Col>
            <Col md={12} xl={8} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  <img src={agendaOrange} />
                </div>

                <p className="bam--bampro-title-section">Agenda en ligne</p>
                <p className="bam--bampro-paragraph-section">
                  Organisez votre travail selon vos disponibilités. Avec votre
                  agenda en ligne, vous validez ou non les demandes de
                  réservation.
                </p>
              </div>
            </Col>
            <Col md={12} xl={8} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  <img src={visibilite} />
                </div>

                <p className="bam--bampro-title-section">Visibilité</p>
                <p className="bam--bampro-paragraph-section">
                  BAM vous met en relation avec de nouveaux clients qui
                  recherchent des prestations autour de chez eux, selon vos
                  disponibilités.
                </p>
              </div>
            </Col>

            <Col md={12} xl={8} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  <img src={tableau} />
                </div>

                <p className="bam--bampro-title-section">Tableau de bord</p>
                <p className="bam--bampro-paragraph-section">
                  Retrouvez votre bilan et les statistiques relatives à votre
                  activité sur votre tableau de bord BAM : votre chiffre
                  d’affaires, vos prestations du mois, le nombre de rendez-vous
                  prévu dans la journée et la note globale.
                </p>
              </div>
            </Col>
            <Col md={12} xl={8} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  <img src={client} />
                </div>

                <p className="bam--bampro-title-section">Fichier client</p>
                <p className="bam--bampro-paragraph-section">
                  Votre fichier client est un annuaire des particuliers ayant
                  déjà fait appel à vous. Il vous permet de garder une trace de
                  l’ensemble de vos clients et de leurs coordonnées, pour les
                  contacter plus facilement.
                </p>
              </div>
            </Col>
            <Col md={12} xl={8} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  <img src={avisClient} />
                </div>

                <p className="bam--bampro-title-section">Avis clients</p>
                <p className="bam--bampro-paragraph-section">
                  Après chaque prestation, le client a la possibilité de vous
                  laisser une note et un avis. Les avis constituent la note
                  globale de chaque professionnel et sont visibles sur la page
                  de chacun.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span="4"></Col>
      </Row>
      <div
        style={{
          backgroundColor: "#FFF7F3",
          marginTop: "6vh"
        }}
      >
        <Row style={{ paddingTop: "6vh" }} justify="center">
          <span className="bam--bampro-title">Les tarifs BAM Pro</span>
        </Row>
        {mobile ? (
          <Row style={{ marginTop: "2vh" }} justify="center">
            <Col xl={12} md={24} xs={24} justify="end">
              <div>
                <div className="bam--pro-prix-border">
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    3 mois offerts, puis:
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    <span style={{ fontSize: "25px", lineHeight: "20px" }}>
                      24,99€ HT
                    </span>
                    /mois
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "1vh" }}
                  >
                    sans engagement
                  </p>
                  <ul style={{ marginTop: "2vh" }} className="bam--pro-ul">
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Création de votre profil en ligne
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Agenda en ligne automatisé
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Fichier clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Mise en relation/nouveaux clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Gain de visibilité
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Automatisation de la prise de rendez-vous
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Avis clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Livret: BAM Pro.
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    marginTop: "4vh",
                    marginBottom: "4vh",
                    textAlign: "center"
                  }}
                >
                  <Button
                    onClick={() => {
                      window.fbq("track", "Subscribe", {
                        value: "0.00",
                        currency: "EUR"
                      });
                      setIsBecameModalVisible(true);
                    }}
                    className="bam--pro-bt"
                  >
                    Essayer gratuitement
                  </Button>
                </div>
              </div>{" "}
            </Col>
            <Col xl={12} md={24} xs={24} justify="end">
              <div>
                <div className="bam--pro-prix-border">
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    3 mois offerts, puis:
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    <span style={{ fontSize: "25px", lineHeight: "20px" }}>
                      199,99€ HT
                    </span>
                    /mois
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "1vh" }}
                  >
                    engagement 1 an
                  </p>
                  <ul style={{ marginTop: "2vh" }} className="bam--pro-ul">
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Création de votre profil en ligne
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Agenda en ligne automatisé
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Fichier clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Mise en relation/nouveaux clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Gain de visibilité
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Automatisation de la prise de rendez-vous
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Avis clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Livret: BAM Pro.
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    marginTop: "4vh",
                    marginBottom: "6vh",
                    textAlign: "center"
                  }}
                >
                  <Button
                    onClick={() => {
                      window.fbq("track", "Subscribe", {
                        value: "0.00",
                        currency: "EUR"
                      });
                      setIsBecameModalVisible(true);
                    }}
                    className="bam--pro-bt"
                  >
                    Essayer gratuitement
                  </Button>
                </div>{" "}
              </div>{" "}
            </Col>
          </Row>
        ) : (
          <Row style={{ marginTop: "2vh" }} justify="center">
            <Col
              xl={12}
              md={24}
              xs={24}
              justify="end"
              style={{ textAlign: "center", padding: "4vh" }}
            >
              <div style={{ width: "80%", marginLeft: "20%" }}>
                <div className="bam--pro-prix-border">
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    3 mois offerts, puis:
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    <span style={{ fontSize: "40px", lineHeight: "30px" }}>
                      24,99€ HT
                    </span>
                    /mois
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "1vh" }}
                  >
                    sans engagement
                  </p>
                  <ul style={{ marginTop: "2vh" }} className="bam--pro-ul">
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Création de votre profil en ligne
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Agenda en ligne automatisé
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Fichier clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Mise en relation/nouveaux clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Gain de visibilité
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Automatisation de la prise de rendez-vous
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Avis clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Livret: BAM Pro.
                      </span>
                    </li>
                  </ul>
                </div>
                <div style={{ marginTop: "2vh", textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      window.fbq("track", "Subscribe", {
                        value: "0.00",
                        currency: "EUR"
                      });
                      setIsBecameModalVisible(true);
                    }}
                    className="bam--pro-bt"
                  >
                    Essayer gratuitement
                  </Button>
                </div>
              </div>{" "}
            </Col>
            <Col
              xl={12}
              md={24}
              xs={24}
              style={{ textAlign: "right", padding: "4vh" }}
            >
              <div style={{ width: "80%" }}>
                <div className="bam--pro-prix-border">
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    3 mois offerts, puis:
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "2vh" }}
                  >
                    <span style={{ fontSize: "40px", lineHeight: "30px" }}>
                      199,99€ HT
                    </span>
                    /mois
                  </p>
                  <p
                    className="bam--pro-prix-title"
                    style={{ marginTop: "1vh" }}
                  >
                    engagement 1 an
                  </p>
                  <ul style={{ marginTop: "2vh" }} className="bam--pro-ul">
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Création de votre profil en ligne
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Agenda en ligne automatisé
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Fichier clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Mise en relation/nouveaux clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Gain de visibilité
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Automatisation de la prise de rendez-vous
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Avis clients
                      </span>
                    </li>
                    <li
                      className="bam--pro-prix-title"
                      style={{ textAlign: "left" }}
                    >
                      <span style={{ marginLeft: mobile ? "3vh" : "20px" }}>
                        Livret: BAM Pro.
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    marginTop: "2vh",
                    textAlign: "center"
                  }}
                >
                  <Button
                    onClick={() => {
                      window.fbq("track", "Subscribe", {
                        value: "0.00",
                        currency: "EUR"
                      });
                      setIsBecameModalVisible(true);
                    }}
                    className="bam--pro-bt"
                  >
                    Essayer gratuitement
                  </Button>
                </div>{" "}
              </div>{" "}
            </Col>
          </Row>
        )}
      </div>

      <Row
        style={{ marginTop: "4vh", marginBottom: "2vh", textAlign: "center" }}
        justify="center"
        align="middle"
      >
        <span className="bam--green-text">FAQ Professionnels</span>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={0} md={1} xl={2} />
        <Col
          xs={23}
          md={6}
          xl={6}
          style={{ marginLeft: "1vh", paddingBottom: "2vh", marginTop: "2vh" }}
        >
          <span className="bam--title-faq">Frequently asked questions</span>
        </Col>
        <Col
          xs={23}
          md={15}
          xl={10}
          style={{ marginTop: "2vh", marginLeft: "1vh" }}
        >
          <Collapse
            bordered={false}
            defaultActiveKey={activeKey}
            onChange={(key) => setActiveKey(key)}
          >
            <div className="bam--faq-hr" />
            <Panel
              key="1"
              showArrow={false}
              className="bam--faq-title"
              header="Comment puis-je m’inscrire sur BAM en tant que professionnel ?"
              extra={
                activeKey.includes("1") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Cliquez sur « Devenir Professionnel » à partir de la page
                d’accueil et renseignez les informations nécessaires à la
                création de votre compte « nom, prénom, mail…).
                <ul>
                  <li>
                    Une fois vos accès créés, configurez votre espace
                    professionnel en ajoutant vos horaires, vos prestations, vos
                    photos, etc.
                  </li>
                  <li>
                    Choisissez ensuite de payer l’abonnement mensuel ou annuel
                  </li>
                  <li>
                    Une fois votre compte configuré et le paiement effectué,
                    notre équipe procédera à la vérification de votre compte.
                  </li>
                  <li>
                    Une fois votre compte validé, vous recevrez un email de
                    validation.
                  </li>
                  <li>
                    {" "}
                    Vous êtes désormais prêt à recevoir des réservations !
                  </li>
                </ul>
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="2"
              className="bam--faq-title"
              header={
                <span>
                  {" "}
                  Puis-je définir mes propres conditions ? (Prestations,
                  <br /> prix, zone de déplacement…)
                </span>
              }
              extra={
                activeKey.includes("2") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Absolument ! Vous définissez vos prestations, vos tarifs, vos
                photos, vos horaires et toutes autres informations présentes sur
                votre profil.
              </p>
              <p className="bam--faq-paragraph">
                BAM est un outil qui sert à faciliter votre gestion et à vous
                mettre en relation avec vos clients. Vous restez décisionnaires
                de vos conditions.
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="3"
              className="bam--faq-title"
              header="Puis-je accueillir mes clients à mon domicile ?"
              extra={
                activeKey.includes("3") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Certains professionnels reçoivent également à leur domicile /
                institut. Vous pouvez le voir grâce à l’indication « reçoit à
                son domicile » dans les résultats de recherche
              </p>
            </Panel>

            <Panel
              showArrow={false}
              key="5"
              className="bam--faq-title"
              header="Quel est le prix ?"
              extra={
                activeKey.includes("5") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Vous bénéficiez d’un mois d’essai gratuit.
              </p>{" "}
              <p className="bam--faq-paragraph">
                Après cela, le forfait mensuel s’élève à 24,99€ HT / mois, ou
                199,99€ HT / an.
              </p>
            </Panel>

            <Panel
              showArrow={false}
              key="6"
              className="bam--faq-title"
              header="Les clients peuvent-ils payer sur BAM directement ?"
              extra={
                activeKey.includes("6") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Aucun paiement ne transite sur BAM, les clients réservent leur
                RDV et vous paient directement sur place.
              </p>{" "}
              <p className="bam--faq-paragraph">
                Pour recevoir un acompte, vous pouvez indiquer votre lien paypal
                sur votre profil.
              </p>
            </Panel>

            <Panel
              showArrow={false}
              key="7"
              className="bam--faq-title"
              header="Comment sont calculées mes disponibilités ?"
              extra={
                activeKey.includes("7") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Vous indiquez vos jours et horaires de disponibilité, ainsi que
                le temps nécessaire à effectuer chaque prestation, et notre
                algorithme calcule et met à jour en temps réel vos
                disponibilités en fonction des réservations de votre agenda.
              </p>{" "}
            </Panel>
          </Collapse>
        </Col>
        <Col span="6" />
      </Row>

      <Row
        style={{
          marginTop: "6vh",
          paddingBottom: "4vh"
        }}
        className="bam--pro-background"
        justify="center"
      >
        <Col span="24">
          <Row>
            <Col span="24">
              {mobile ? (
                <p className="bam--bampro-quote">
                  REJOIGNEZ DES CENTAINES DE PROFESSIONNELS DE LA BEAUTÉ ET DU
                  BIEN-ÊTRE À DOMICILE
                </p>
              ) : (
                <p className="bam--bampro-quote">
                  REJOIGNEZ DES CENTAINES DE PROFESSIONNELS <br />
                  DE LA BEAUTÉ ET DU BIEN-ÊTRE À DOMICILE
                </p>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "4vh", textAlign: "center" }}>
            <Col span="24">
              <li
                class="ant-menu-item ant-menu-item-only-child bam--menu-item"
                role="menuitem"
                style={{ textAlign: "center" }}
              >
                <button type="button" class="ant-btn bam--menu-button-pro">
                  <span>Devenir Pro</span>
                </button>
              </li>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "4vh",
          textAlign: "center"
        }}
        justify="center"
        align="middle"
      >
        <Col span="24">
          <Row
            style={{ marginTop: "2vh", textAlign: "center" }}
            justify="center"
            align="middle"
          >
            <span className="bam--green-text">
              Vous souhaitez nous contacter ?
            </span>
          </Row>
          <div style={{ marginTop: "4vh" }}>
            <span className="bam--text-contact">
              Contactez l'équipe BAM sur Instagram: @bam.france.pro,
              <br />
              par e-mail : contact@bambeaute.com,
              <br />
              ou sur notre chat en ligne.
              <br />
              <br />
              Pour toute question, n’hésitez pas à consulter notre FAQ.
            </span>
          </div>
        </Col>
      </Row>
      <BecamePro
        isModalVisible={isBecameModalVisible}
        //isModalVisible={true}
        closeModal={() => setIsBecameModalVisible(false)}
      />
    </>
  );
};

export default BAMPro;
