import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./App.css";
import TopNavLayout from "./Layouts/TopNavigationLayout";
import Admin from "./pages/Admin";
import "./Styles/components.scss";
import history from "./utils/History";
import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-LSWPJZWWGE");
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route
          path=""
          exact={true}
          render={({ location }) => (
            <TopNavLayout location={location}>
              <Admin location={location} />
            </TopNavLayout>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
