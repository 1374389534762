import { Row, Col } from "antd";
import React, { Component, useEffect, useState } from "react";

const HourMinute = ({ m, h, updateHourMinute, open }) => {
  const [hour, setHour] = useState(h);
  const [minute, setMinute] = useState(m);

  useEffect(() => {
    setHour(h);
    setMinute(m);
  }, [h, m]);
  const toChangeMinute = (pas) => {
    var l__minute = minute;
    var l__hour = hour;
    l__minute += pas;
    if (l__minute > 59) {
      l__minute = 0;
      l__hour++;
    }
    if (l__minute < 0) {
      l__minute = 59;
      l__hour--;
    }
    setMinute(l__minute);
    setHour(l__hour);
    updateHourMinute(l__hour, l__minute);
  };
  return (
    <>
      <Row>
        <Col xl={6} md={6} xs={8}>
          {open ? (
            <img
              src={require("../Styles/images/minus.svg")}
              onClick={() => toChangeMinute(-15)}
              className="bam--cursor"
            />
          ) : (
            <img
              src={require("../Styles/images/minus.svg")}
              style={{ opacity: "0.5" }}
            />
          )}
        </Col>
        <Col xl={8} md={8} xs={8}>
          <span className="bam--text-time" style={{ marginTop: "2vh" }}>
            {hour.toLocaleString("fr-fr", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }) +
              ":" +
              minute.toLocaleString("fr-fr", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
          </span>
        </Col>
        <Col xl={6} md={6} xs={8}>
          {open ? (
            <img
              src={require("../Styles/images/plus-small.svg")}
              onClick={() => toChangeMinute(+15)}
              className="bam--cursor"
            />
          ) : (
            <img
              src={require("../Styles/images/plus-small.svg")}
              style={{ opacity: "0.5" }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default HourMinute;
