import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/fr_FR";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Bibliotheque from "../components/Bibliotheque";
import PrivateRoute from "../components/PrivateRoute";
import About from "./About";
import Article from "./Article";
import Blog from "./Blog";
import CGU from "./CGU";
import EspaceAdmin from "./EspaceAdmin/EspaceAdmin";
import EspacePro from "./EspacePro/EspacePro";
import EspaceUser from "./EspaceUser/EspaceUser";
import FAQ from "./FAQ";
import Home from "./Home";
import Profile from "./ProfBeaute/Profile";
import Pwd from "./Pwd";
import pwd from "./Pwd";
import Results from "./Results";
import BAMPro from "./BAMPro";

const Admin = ({ location, ...props }) => {
  return (
    <Switch>
      <ConfigProvider locale={locale}>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/article">
          <Article />
        </Route>
        <Route exact path="/devenirpro">
          <Home />
        </Route>
        <Route exact path="/bibliotheque">
          <Bibliotheque />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/cgu">
          <CGU />
        </Route>
        <Route exact path="/profil/:id/:name">
          <Profile />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route>
        <Route exact path="/espacepro/**">
          <EspacePro />
        </Route>
        <Route exact path="/espaceadmin/**">
          <EspaceAdmin />
        </Route>
        <Route exact path="/faq">
          <FAQ />
        </Route>
        <Route exact path="/results">
          <Results />
        </Route>
        <Route exact path="/bampro">
          <BAMPro />
        </Route>
        <Route exact path="/key/:key">
          <Pwd />
        </Route>
        <PrivateRoute exact path="/espaceuser/**">
          <EspaceUser />
        </PrivateRoute>
      </ConfigProvider>
    </Switch>
  );
};

export default Admin;
