import {
  Form,
  Col,
  Input,
  Popconfirm,
  Divider,
  Row,
  Select,
  Button
} from "antd";
import React, { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import HourMinute from "../../components/HourMinute";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const PrestationItem = ({
  json,
  deleteForm,
  i,
  onChangeParent,
  details,
  addPrestation,
  selectedId
}) => {
  const [f] = Form.useForm();
  const [h, setHour] = useState(json.hour);
  const [prestationtoAdd, setPrestationToAdd] = useState("");
  const [prestationId, setPrestationId] = useState(json.prestationId);
  const [m, setMinutes] = useState(json.minute);
  const [formP] = Form.useForm();
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    f.setFieldsValue(json);
    setPrestationId(json.prestationId);
  }, [json, details]);

  const onChange = (e, hour, minutes) => {
    var json = f.getFieldsValue();
    json.hour = hour;
    json.minute = minutes;
    json.prestationId = e;
    setPrestationId(e);
    onChangeParent(i, json);
  };

  const updateHourMinute = (h, m) => {
    setHour(h);
    setMinutes(m);
    onChange(prestationId, h, m);
  };

  return (
    <>
      <Form
        name={"form"}
        layout={"vertical"}
        form={f}
        onChange={() => onChange(prestationId, h, m)}
      >
        <Row gutter={24}>
          <Col xl={7} md={7} xs={24}>
            <Form.Item
              label={
                !json.actif
                  ? "Prestation (en cours de validation)"
                  : "Prestation"
              }
              name="prestationId"
              className="bam--label-form"
            >
              <div className="bam--select-form">
                <Select
                  placeholder="Choisir la prestation"
                  onChange={(e) => onChange(e, h, m)}
                  defaultValue={prestationId}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}

                      <Divider style={{ margin: "4px 0" }} />
                      {selectedId != -1 && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Button
                            className=""
                            style={{
                              flex: "none",
                              marginLeft: "8px",
                              display: "block",
                              cursor: "pointer"
                            }}
                            onClick={() => setModalVisible(true)}
                            className="bam--button-carrousel-text"
                          >
                            Ajouter une prestation
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                >
                  {details.map((p, i) => (
                    <Select.Option value={p.id}>{p.name}</Select.Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col xl={8} md={8} xs={24}>
            <Form.Item
              label="Description"
              name="description"
              className="bam--label-form"
            >
              <Input className="bam--input" />
            </Form.Item>
          </Col>
          <Col xl={4} md={4} xs={24}>
            <Form.Item label="Durée" name="duree" className="bam--label-form">
              <div id="hour_minute" style={{ marginTop: "10px" }}>
                <HourMinute
                  open={true}
                  updateHourMinute={(h, m) => updateHourMinute(h, m)}
                  m={m}
                  h={h}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xl={3} md={3} xs={24}>
            <Form.Item label="Prix" name="price" className="bam--label-form">
              <Input className="bam--input" type="number" suffix={"€"} />
            </Form.Item>
          </Col>
          <Col xl={2} md={2} xs={24}>
            <Form.Item
              label=" "
              name=""
              className="bam--label-form"
              style={{ marginTop: "5px" }}
            >
              <Popconfirm
                title={`Voulez vous vraiment supprimer cette prestation?`}
                onConfirm={() => deleteForm(i)}
                okText="Oui"
                cancelText="Non"
                placement="bottom"
              >
                <Button danger icon={<DeleteOutlined />}></Button>
              </Popconfirm>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <Row justify="center">
            <Button
              loading={loading}
              htmlType="submit"
              className="bam--button-carrousel-text"
              disabled={formP.getFieldValue("prestation") == ""}
              onClick={() => {
                if (formP.getFieldValue("prestation") != "") {
                  addPrestation(formP.getFieldValue("prestation"));
                  setLoading(true);
                  setModalVisible(false);
                }
              }}
            >
              <span>Enregistrer</span>
            </Button>
          </Row>
        }
      >
        <Form form={formP} layout={"vertical"} style={{ marginTop: "2vh" }}>
          <Form.Item
            name="prestation"
            label="Prestation"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "Champ obligatoire"
              }
            ]}
          >
            <Input
              className="bam--input"
              visibilityToggle={false}
              placeholder="Tapez le nom de la prestation"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default PrestationItem;
