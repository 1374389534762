import { Button, Form, Input, Modal } from "antd";
import "antd/dist/antd.css";
import React from "react";

const AddCategory = ({ isModalVisible, cancel, save }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="Ajouter une catégorie"
      visible={isModalVisible}
      onCancel={() => cancel()}
      footer={null}
    >
      <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
        <Form.Item
          name="name"
          label="Nom catégorie"
          className="bam--label-form"
          rules={[{ required: true, message: "Champ obligatoire" }]}
        >
          <Input className="bam--input" placeholder="Nom catégorie" />
        </Form.Item>

        <Button
          style={{ marginTop: "2vh" }}
          className="bam--button-carrousel-text"
          htmlType="submit"
          onClick={() => save(form.getFieldsValue())}
        >
          Enregistrer
        </Button>
      </Form>
    </Modal>
  );
};

export default AddCategory;
