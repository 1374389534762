import axios from "axios";
import cookie from "react-cookies";
import CAxios from "../../utils/CAxios";
import { openNotification } from "../../utils/Utils";

export const toAddBeautyPro = (data) => {
  return CAxios.post("/beautypro/create", data);
};

export const toSaveInfoBeautyPro = (data) => {
  return CAxios.post("/beautypro/save", data);
};
export const toSaveReservation = (data) => {
  return CAxios.post("/reservations", data);
};
export const toAddReservation = (data) => {
  return CAxios.post("/reservations/add", data);
};
export const toUpdateReservation = (data) => {
  return CAxios.post("/reservations/update", data);
};
export const toDelete = (id) => {
  return CAxios.post("/reservations/delete", { id: id });
};
export const toUploadPhotos = (files, avatar) => {
  var data = new FormData();
  for (let index = 0; index < files.length; index++) {
    const element = files[index];

    data.append("file" + index, element.file);
  }

  data.append("avatar", avatar);
  var setting = {
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "media/upload",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + cookie.load("token"),
    },
  };
  try {
    axios(setting).then((res) => {
      if (avatar) {
        openNotification("Succès!", "Modifications enregistrées avec succès !");
      } else {
        openNotification("Succès!", "Modifications enregistrées avec succès !");
      }
      return true;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAvis = () => {
  return CAxios.get("/avis");
};

export const createAvis = (note, avis, id) => {
  return CAxios.post("/avis", { note: note, avis: avis, id: id });
};

export const getBlog = async () => {
  const { data } = await CAxios.get("/blog");
  return data.success && data.success.list ? data.success.list : [];
};

export const getArticle = async (id) => {
  const { data } = await CAxios.post("/article", { id: id });
  return data.success && data.success.blog ? data.success.blog : [];
};

export const changeStatusBlog = async (data) => {
  return CAxios.post(`/blog/status`, data);
};

export const getPrestations = (status) => {
  return CAxios.get("/prestations/" + status);
};

export const getPrestationsAll = async () => {
  const { data } = await CAxios.post(`/prestations/all`);
  return data.success && data.success.prestations
    ? data.success.prestations
    : [];
};
export const getActifPrestations = async () => {
  const { data } = await CAxios.post(`/prestations/actif`);
  return data.success && data.success.prestations
    ? data.success.prestations
    : [];
};
export const getActifPrestationsBeautyPro = async () => {
  const { data } = await CAxios.post(`/prestations/beauty/actif`);
  return data.success && data.success.prestations
    ? data.success.prestations
    : [];
};

export const getDetails = async () => {
  const { data } = await CAxios.post(`/prestations/details`);
  return data.success && data.success.prestations
    ? data.success.prestations
    : [];
};

export const savePrestations = (data) => {
  return CAxios.post(`/prestations/save`, data);
};

export const savePrestationsDetail = (data) => {
  return CAxios.post(`/prestation/detail/save`, data);
};

export const getReservationsUser = async () => {
  const { data } = await CAxios.get("/reservations/user");
  return data.success && data.success.reservations
    ? data.success.reservations
    : {};
};
export const getAllBeautyPro = async () => {
  const { data } = await CAxios.get("/beautypro/getAll");
  return data.success ? data.success[0] : {};
};
export const getInfoIndex = () => {
  return CAxios.get("/beautypro/index");
};
export const getClients = async () => {
  const { data } = await CAxios.get("/reservations/client");
  return data.success && data.success.clients ? data.success.clients : [];
};
export const updateStatusPrestations = (data) => {
  return CAxios.put("/prestations", data);
};
export const createPrestation = (data) => {
  return CAxios.post("/prestations/add", data);
};

export const addCategoryAPI = (data) => {
  return CAxios.post("/category/create", data);
};
export const addPrestationAPI = (data) => {
  return CAxios.post("/prestation/new", data);
};

export const getPrestationsBeautyAll = async () => {
  const { data } = await CAxios.post("/prestations/beauty/list");
  return data.success && data.success.prestations
    ? data.success.prestations
    : [];
};

export const toDeletePrestation = async (id) => {
  const { data } = await CAxios.post(`/prestation/delete`, {
    id: id,
  });
  return data.success && data.success.success;
};
export const toDeletePrestationBeautyPro = async (id) => {
  const { data } = await CAxios.post(`/prestations/beauty/delete`, {
    id: id,
  });
  return data.success && data.success.success;
};
export const toGetInfosBeautyPro = async () => {
  const { data } = await CAxios.get(`/beautypro/get`);
  return data.success && data.success.infos ? data.success.infos : {};
};

export const toGetReservations = async () => {
  const { data } = await CAxios.get(`/reservations`);
  return data.success && data.success.reservations
    ? data.success.reservations
    : {};
};

export const toGetReservationsAll = async () => {
  const { data } = await CAxios.get(`/reservations/all`);
  return data.success && data.success.reservations
    ? data.success.reservations
    : {};
};

export const toValidateReservations = async (id, value) => {
  const { data } = await CAxios.put(`/reservations`, {
    idReservation: id,
    value,
  });
  return data.success && data.success.success;
};
export const toGetProfile = async (id) => {
  const { data } = await CAxios.post(`/beautypro/profile`, { beautyProId: id });
  return data.success && data.success.profile ? data.success.profile : {};
};
export const paiementAbonnement = async (values) => {
  const { data } = await CAxios.post("/stripe", values);
  return data.success && data.success.token ? data.success.token : null;
};

export const getAllMedias = async () => {
  const { data } = await CAxios.post("/media/all");
  return data.success && data.success.medias ? data.success.medias : [];
};
export const getHistoryPaiement = async () => {
  const { data } = await CAxios.get("/stripe");
  return data.success ? data.success[0] : [];
};
export const getHistoryPaiementAdmin = async () => {
  const { data } = await CAxios.get("/stripe/admin");
  return data.success ? data.success[0] : [];
};
export const getPriceAbonement = async () => {
  const { data } = await CAxios.get("/stripe/abonement");
  return data.success ? data.success[0] : [];
};

export const savePaiementAbonnement = async (val) => {
  const { data } = await CAxios.post("/stripe/abonement", val);
  return data.success ? data.success[0] : [];
};
export const changeStatusUser = async (val) => {
  const { data } = await CAxios.post("/beautypro/changeStatus", val);
  return data.success ? data.success[0] : [];
};
export const search = async (values) => {
  const { data } = await CAxios.post("/prestations/search", values);
  return data.success ? data.success.res : [];
};
export const changeStatusCategory = async (val) => {
  const { data } = await CAxios.put("/category/admin", val);
  return data.success ? data.success.prestations : [];
};

export const addArticle = async (
  id,
  title,
  text,
  description,
  files,
  category
) => {
  var data = new FormData();
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    data.append("file" + index, element.file);
  }
  data.append("title", title);
  data.append("text", text);
  data.append("description", description);
  data.append("category", category);
  data.append("id", id);
  var setting = {
    method: "post",
    url: process.env.REACT_APP_BASE_URL + "blog",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  axios(setting).then((res) => {
    openNotification("Succès!", "Modifications enregistrées avec succès !");
    return true;
  });
};

export const changeStatusPrestations = async (val) => {
  const { data } = await CAxios.put("/prestations/admin", val);
  return data.success ? data.success.prestations : [];
};

export const filterReservation = async (date, beautyProId) => {
  const { data } = await CAxios.post("/reservations/filter", {
    date: date,
    beautyProId,
  });
  return data.success && data.success.reservations
    ? data.success.reservations
    : [];
};
