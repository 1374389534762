import { Button, Form, Input, Modal, Select } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { getPrestationsAll } from "../ProfBeaute/APIs";

const AddPrestation = ({
  isModalVisible,
  cancelPrestation,
  savePrestation,
}) => {
  const [prestations, setPrestations] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    getPrestationsAll().then((res) => {
      setPrestations(res);
    });
  }, []);

  return (
    <Modal
      visible={isModalVisible}
      title="Ajouter une prestation"
      onCancel={() => cancelPrestation()}
      footer={null}
    >
      <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
        <Form.Item
          label="Catégorie"
          className="bam--label-form"
          name="categoryId"
          rules={[
            {
              required: true,
              message: "merci d'entrer la catégorie ",
            },
          ]}
        >
          <Select
            // onChange={onChange}
            style={{ border: "1px solid black", borderRadius: "5px" }}
          >
            {prestations.map((p, i) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Nom prestation"
          className="bam--label-form"
          rules={[{ required: true, message: "Champ obligatoire" }]}
        >
          <Input className="bam--input" placeholder="Nom prestation" />
        </Form.Item>

        <Button
          style={{ marginTop: "2vh" }}
          className="bam--button-carrousel-text"
          htmlType="submit"
          onClick={() => savePrestation(form.getFieldsValue())}
        >
          Enregistrer
        </Button>
      </Form>
    </Modal>
  );
};

export default AddPrestation;
