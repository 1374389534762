import axios from "axios";
import { makeAutoObservable } from "mobx";

class UserStore {
  showLoginModal = false;
  showRegisterModal = false;
  constructor() {
    makeAutoObservable(this);
  }

  setLoginModalVisible() {
    this.showLoginModal = !this.showLoginModal;
  }
  setRegisterModalVisible() {
    this.showRegisterModal = !this.showRegisterModal;
  }
  login(data) {
    return axios.post(process.env.REACT_APP_BASE_URL + "auth/login", data);
  }
  register(data) {
    return axios.post(process.env.REACT_APP_BASE_URL + "auth/register", data);
  }
  fbAuth(data) {
    return axios.post(
      process.env.REACT_APP_BASE_URL + "auth/facebookAuth",
      data
    );
  }
  regenerateKey(data) {
    return axios.post(process.env.REACT_APP_BASE_URL + "user/key", data);
  }
  googleAuth(data) {
    return axios.post(process.env.REACT_APP_BASE_URL + "auth/googleAuth", data);
  }
}

export default new UserStore();
