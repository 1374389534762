import { Button, Col, Collapse, DatePicker, Tooltip, Row, Tabs } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Gallery from "react-grid-gallery";
import LoadingScreen from "react-loading-screen";
import { useLocation, useParams } from "react-router-dom";
import Avis from "../../components/Avis";
import history from "../../utils/History";
import { errorNotification, openNotification } from "../../utils/Utils";
import { filterReservation, toGetProfile, toSaveReservation } from "./APIs";
import moment from "moment";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import ReactRoundedImage from "react-rounded-image";
import { FiMapPin, FiPhone } from "react-icons/fi";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { inject, observer } from "mobx-react";
import ReactGA from "react-ga";

const { Panel } = Collapse;

const { TabPane } = Tabs;

const Profile = ({ UserStore }) => {
  const location = useLocation();
  const myRef = useRef(null);
  const avisRef = useRef(null);
  const { id } = useParams();

  const [medias, setMedias] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [hour, setHour] = useState("");
  const [infos, setInfos] = useState(null);
  const [disponibility, setDisponibility] = useState(null);
  const [indisponibility, setIndisponibility] = useState(null);
  const [prestations, setPrestations] = useState([]);
  const [avis, setAvis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [date, setDate] = useState(
    moment(null != location.state ? location.state.date : new Date()).format(
      "YYYY-MM-DD"
    )
  );
  const [prestation, setPrestation] = useState({});
  const [roles, setRoles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [mobile, setMobile] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  useLayoutEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    function updateSize() {
      if (window.innerWidth > 900) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (cookie.load("token")) {
      var decodedHeader = jwt_decode(
        cookie.load("token").replace("Bearer ", "")
      );
      setRoles(decodedHeader.role);
    }

    if (null != id) {
      toGetProfile(id).then((res) => {
        try {
          setAvatar(res.medias.filter((e) => e.avatar == 1)[0].name);
        } catch (error) {
          setAvatar("profile.jpeg");
        }
        var l__images = getImages(res.medias.filter((e) => e.avatar == 0));
        var c = [];
        l__images.forEach((element) => {
          c.push(element.src);
        });
        setMedias(l__images);
        setImages(c);

        setInfos(res.infos[0]);
        try {
          setDisponibility(JSON.parse(res.infos[0].disponibilite));
        } catch (error) {
          setDisponibility([]);
        }
        try {
          setIndisponibility(JSON.parse(res.infos[0].indisponibilite));
        } catch (error) {
          setIndisponibility([]);
        }
        setPrestations(res.prestations);
        setAvis(res.avis);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
      filterReservation(date, id).then((res) => {
        setReservations(res);
      });
    } else {
      history.push("/");
    }
  }, []);
  const executeScroll = () => myRef.current.scrollIntoView();
  const executeScrollAvis = () => avisRef.current.scrollIntoView();

  const getImages = (list) => {
    var images = [];
    list.forEach((element, i) => {
      images.push({
        src: process.env.REACT_APP_BASE_URL + "images/" + element.name,
        thumbnailWidth: 320,
        thumbnailHeight: 150,

        thumbnail: process.env.REACT_APP_BASE_URL + "images/" + element.name,
      });
    });
    return images;
  };
  const getHoraire = (time, day) => {
    var h =
      null != infos &&
      JSON.parse(infos.disponibilite).filter((e) => e.name == day)[0][time].h;
    var m =
      null != infos &&
      JSON.parse(infos.disponibilite).filter((e) => e.name == day)[0][time].m;
    m < 10 && (m = "0" + m);
    return h + ":" + m;
  };
  const getOpen = (day) => {
    return (
      null != infos &&
      JSON.parse(infos.disponibilite).filter((e) => e.name == day)[0].open
    );
  };
  const getAvis = () => {
    if (avis.length == 0) {
      return 0;
    }
    var sum = avis.reduce((a, b) => a + b.vote, 0);
    return Math.round(sum / avis.length);
  };

  const validatePrestation = () => {
    var l__roles = "";
    if (cookie.load("token")) {
      var decodedHeader = jwt_decode(
        cookie.load("token").replace("Bearer ", "")
      );
      l__roles = decodedHeader.role;
    }
    if (!l__roles.includes("ROLE_USER")) {
      UserStore.setLoginModalVisible();

      return;
    }
    toSaveReservation({
      id: prestation.id,
      beautyProId: infos.id,
      date: moment(date + " " + hour).format("Y-M-D H:m:s"),
    }).then((res) => {
      openNotification(
        "Succès!",
        "Votre réservation a bien été prise en compte !"
      );
      setModalVisible(false);
    });
  };

  const getDay = (x) => {
    switch (x) {
      case 0:
        return "Lundi";
        break;
      case 1:
        return "Mardi";

        break;
      case 2:
        return "Mercredi";

        break;
      case 3:
        return "Jeudi";

        break;
      case 4:
        return "Vendredi";

        break;
      case 5:
        return "Samedi";

        break;
      case 6:
        return "Dimanche";

        break;

      default:
        break;
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onChangeDate = (d) => {
    if (d == "") {
      setDate(null);
      return;
    }
    filterReservation(d, infos.id).then((res) => setReservations(res));
    setHour("");
    var dayName = getDayName(d);
    setSelectedDay(disponibility.filter((el) => el.name == dayName));

    setDate(d);
  };

  const getDayName = (d) => {
    var day = moment(d).format("dddd");
    switch (day) {
      case "lundi":
        return "L";

      case "mardi":
        return "M";

      case "mercredi":
        return "Me";

      case "jeudi":
        return "J";

      case "vendredi":
        return "V";

      case "samedi":
        return "S";

      case "dimanche":
        return "D";

      default:
        break;
    }
  };

  const openModal = (id, name, p) => {
    onChangeDate(moment(new Date()).format("YYYY-MM-DD"));

    setPrestation({
      name: name,
      subName: p.name,
      price: p.price,
      id: id,
    });
    setModalVisible(true);
  };

  const getPrice = (list) => {
    var min = 0;
    list.forEach((element) => {
      if (element.price < min || min == 0) {
        min = element.price;
      }
    });
    return min;
  };

  const checkIfTimeAvailable = (h, m) => {
    var res = false;
    var actualDate = moment(date).set({ hour: h, minute: m, second: 0 });
    //quand le centre n'est pas disponible
    if (null != indisponibility) {
      indisponibility.forEach((element) => {
        if (
          moment(date).isBetween(element.start, element.end, undefined, "[]")
        ) {
          res = true;
        }
      });
      if (res) return true;
    }

    //quand le centre est fermé
    if (null != selectedDay && !selectedDay[0].open) {
      return true;
    }
    //entre le start et end
    if (null != selectedDay) {
      var startH = selectedDay[0].start.h;
      var startM = selectedDay[0].start.m;

      var endH = selectedDay[0].end.h;

      var endM = selectedDay[0].end.m;
      var start = moment(date).set({ hour: startH, minute: startM, second: 0 });
      var end = moment(date).set({ hour: endH, minute: endM, second: 0 });
    }

    if (
      null != selectedDay &&
      !actualDate.isBetween(start, end, undefined, "[]")
    ) {
      res = true;
    }

    //les réservations
    reservations.forEach((element) => {
      var endDate = moment(element.start)
        .add(element.hour, "hours")
        .add(element.minute, "minutes");

      if (
        actualDate.isBetween(moment(element.start), endDate, undefined, "[]")
      ) {
        res = true;
      }
    });
    return res;
    //les breaks
    // if (null != selectedDay) {
    //   var start = moment(date)
    //     .add(selectedDay[0].startBreak.h, "hours")
    //     .add(selectedDay[0].startBreak.m, "minutes")
    //     .format("YYYY-MM-DD H:m:s");

    //   var end = moment(date)
    //     .add(selectedDay[0].endBreak.h, "hours")
    //     .add(selectedDay[0].endBreak.m, "minutes")
    //     .format("YYYY-MM-DD H:m:s");
    //   if (moment(date + " " + time).isBetween(start, end, undefined, "[]")) {
    //     return true;
    //   }
    // }
  };

  function disabledDate(current) {
    return current < moment().subtract(1, "days");
  }

  return (
    <>
      <LoadingScreen
        loading={loading}
        bgColor="#f5f8fa"
        spinnerColor="#f87d6b"
        textColor="#2D2926"
      >
        <Row>
          <hr className="bam--hr-became" style={{ width: "100%" }} />
        </Row>
        <Row style={{ marginTop: "2vh", marginLeft: "2vh" }}>
          <Col xl={2} md={2} xs={0} />
          <Col xl={3} md={6} xs={4} id="rounded">
            <ReactRoundedImage
              style={{ backgroundColor: "transparent" }}
              roundedSize="0"
              imageWidth={mobile ? "50" : "150"}
              imageHeight={mobile ? "50" : "150"}
              image={process.env.REACT_APP_BASE_URL + "images/" + avatar}
            />
          </Col>
          <Col xl={12} md={9} xs={12} className="bam--margin-profil">
            <span className="bam--profile-name">
              {null != infos && infos.companyName}
            </span>
            <Row style={{ display: "block" }} className="bam--margin-avis">
              <span className="bam--profile-rate">{getAvis()}</span>
              <span style={{ marginLeft: "1vh" }} className="bam--profile-rate">
                {getAvis() > 0 && (
                  <img src={require("../../Styles/images/star.svg")} />
                )}
                {getAvis() > 1 && (
                  <img
                    style={{ marginLeft: "2px" }}
                    src={require("../../Styles/images/star.svg")}
                  />
                )}
                {getAvis() > 2 && (
                  <img
                    style={{ marginLeft: "2px" }}
                    src={require("../../Styles/images/star.svg")}
                  />
                )}
                {getAvis() > 3 && (
                  <img
                    style={{ marginLeft: "2px" }}
                    src={require("../../Styles/images/star.svg")}
                  />
                )}
                {getAvis() > 4 && (
                  <img
                    style={{ marginLeft: "2px" }}
                    src={require("../../Styles/images/star.svg")}
                  />
                )}
              </span>
              <span
                className="bam--profile-nb-rate"
                style={{ marginLeft: "5px", cursor: "pointer" }}
                onClick={() => executeScrollAvis({ behavior: "smooth" })}
              >
                ({avis.length + " avis"})
              </span>
            </Row>

            {/* {mobile && (
              <div>
                <span
                  className="bam--profile-nb-rate"
                  style={{ marginLeft: "2vh", cursor: "pointer" }}
                  onClick={() => executeScrollAvis({ behavior: "smooth" })}
                >
                  ({avis.length + " avis"})
                </span>
              </div>
            )} */}
          </Col>
          <Col
            xl={5}
            md={5}
            xs={7}
            className="bam--margin-profil"
            style={{ float: "right" }}
          >
            <div>
              {/* <img src={require("../../Styles/images/like.svg")} />
            <span style={{ marginLeft: "2vh" }} className="bam--like-text">
              Ajouter à mes favoris
            </span> */}
              {null != infos && (
                <Button
                  className="bam--button-carrousel-text"
                  style={{
                    marginTop: mobile ? "0.5vh" : "2vh",
                    marginLeft: "4vh",
                    float: "right",
                  }}
                  onClick={() => executeScroll({ behavior: "smooth" })}
                >
                  Prendre RDV
                </Button>
              )}
            </div>
          </Col>
          <Col xl={2} md={2} xs={1} />
        </Row>
        <Row style={{ marginTop: "2vh" }}>
          <Col xs={0} xl={2} md={2} />

          <Col xs={24} xl={20} md={20}>
            {mobile ? (
              <div id="slider">
                <Slider {...settings}>
                  {medias.map((m) => (
                    <div>
                      <div
                        className="bam--container-img"
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsOpen(true)}
                      >
                        <img
                          style={{ paddingLeft: "12px" }}
                          className="bam--profile-images-item"
                          src={m.src}
                        ></img>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <Row>
                <Col xl={8} md={8} xs={24}>
                  <img
                    className="bam--img-results"
                    src={medias.length > 0 && medias[0].src}
                  />
                </Col>
                <Col xl={16} md={16} xs={24}>
                  <Row>
                    <Col span={12}>
                      <div className="bam--container-img">
                        <img
                          style={{ paddingLeft: "12px" }}
                          className="bam--profile-images-item"
                          src={medias.length > 1 && medias[1].src}
                        ></img>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className="bam--container-img">
                        <img
                          className="bam--profile-images-item"
                          style={{
                            borderRadius: "0px 10px 00px 0px",
                            paddingLeft: "12px",
                          }}
                          src={medias.length > 2 && medias[2].src}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "12px" }}>
                    <Col span={12}>
                      <div className="bam--container-img">
                        <img
                          style={{ paddingLeft: "12px" }}
                          className="bam--profile-images-item"
                          src={medias.length > 3 && medias[3].src}
                        ></img>
                      </div>
                    </Col>{" "}
                    <Col span={12}>
                      <div className="bam--container-img">
                        <img
                          className="bam--profile-images-item"
                          style={{
                            paddingLeft: "12px",

                            borderRadius: "0px 0px 10px 0px",
                          }}
                          src={medias.length > 4 && medias[4].src}
                        ></img>
                        <Button
                          className="bam--container-button"
                          onClick={() => setIsOpen(true)}
                        >
                          Voir plus de photos
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* <Gallery
                  ref={galleryRef}
                  backdropClosesModal={medias.length > 0 && true}
                  showImageCount={false}
                  maxRows={2}
                  images={medias}
                  enableImageSelection={false}
                /> */}
                </Col>
              </Row>
            )}{" "}
          </Col>
          <Col xs={0} xl={2} md={2} />
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}

        <Row style={{ marginTop: "2vh" }}>
          <Col xl={2} md={2} xs={1} />

          <Col xl={21} md={21} xs={23}>
            {null != infos && infos.displayAdress && (
              <Row>
                <FiMapPin size="1.5em" />

                <span
                  className="bam--profile-address"
                  style={{ marginLeft: "2vh" }}
                >
                  {null != infos && infos.adress}
                </span>
              </Row>
            )}
            <Row style={{ marginTop: "2vh" }}>
              <FiPhone size="1.5em" />

              <span
                className="bam--profile-address"
                style={{ marginLeft: "2vh" }}
              >
                {null != infos && infos.tel}
              </span>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: "4vh" }}>
          <Col xl={2} md={2} xs={1}></Col>
          <Col xl={22} md={21} xs={23}>
            <span className="bam--profile-name">Horaires</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "4vh" }}>
          <Col xl={2} md={2} xs={1} />
          <Col xl={22} md={21} xs={23}>
            <table>
              {null != infos &&
                infos.disponibilite.length > 0 &&
                JSON.parse(infos.disponibilite).map((e, x) => (
                  <tr>
                    <td style={{ minWidth: "20vh" }}>
                      <span
                        className={
                          getOpen(e.name) ? "circle-green" : "circle-red"
                        }
                      ></span>

                      <span
                        className="bam--horaire"
                        style={{ marginLeft: "2vh" }}
                      >
                        {getDay(x)}
                      </span>
                    </td>
                    <td>
                      {!getOpen(e.name) ? (
                        <span className="bam--horaire"> Fermé</span>
                      ) : (
                        <span className="bam--horaire">
                          {getHoraire("start", e.name)}

                          {" - " + getHoraire("end", "L")}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </table>
          </Col>
        </Row>
        <Row style={{ marginTop: "1vh" }}>
          <Col span={2} />
          <Col span={3}></Col>
          <Col span={16}></Col>
        </Row>
        <Row style={{ marginTop: "4vh" }}>
          <Col xl={2} md={2} xs={1}></Col>
          <Col xl={21} md={21} xs={23}>
            <hr className="bam--hr-became" style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row style={{ marginTop: "4vh" }}>
          <Col xl={2} md={2} xs={1}></Col>
          <Col xl={21} md={21} xs={23}>
            <span className="bam--profile-name">Prestations</span>
          </Col>
        </Row>
        <Row>
          <Col xl={2} md={2} xs={1}></Col>

          <Col xl={21} md={21} xs={23} ref={myRef}>
            {mobile ? (
              prestations.map(
                (p, x) =>
                  p.actif && (
                    <Collapse
                      key={x}
                      bordered={false}
                      expandIconPosition="right"
                      className={
                        x == prestations.length - 1
                          ? "bam--no-border-bottom"
                          : ""
                      }
                    >
                      <Panel
                        header={
                          <>
                            <span className="bam--horaire">
                              {p.name + " (" + p.details.length + ")"}
                            </span>
                            <span
                              className="bam--horaire"
                              style={{
                                float: "right",
                                fontWeight: "lighter",
                                fontSize: "15px",
                              }}
                            >
                              <b>
                                {"à partir de " + getPrice(p.details) + "€"}
                              </b>
                            </span>
                          </>
                        }
                        key={x}
                      >
                        {p.details.map((el, y) => (
                          <Row style={{ marginTop: "1vh" }}>
                            <Col xs={12}>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    {el.details}
                                  </span>
                                }
                                color={"#fff"}
                              >
                                <div>
                                  <span className="bam--service-title">
                                    {el.name}
                                  </span>
                                </div>
                              </Tooltip>
                              <br />
                              <>
                                {el.hour > 0 && el.hour + " h"}
                                {el.minute > 0 && el.minute + " min"}
                              </>
                            </Col>
                            <Col xs={2}>{el.price + " €"}</Col>
                            <Col xs={10} style={{ textAlign: "center" }}>
                              <Button
                                className="bam--profile-select"
                                onClick={() =>
                                  openModal(
                                    el.idPrestationBeautyPro,
                                    p.name,
                                    el
                                  )
                                }
                              >
                                Séléctionner
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </Panel>
                    </Collapse>
                  )
              )
            ) : (
              <Tabs
                className="bam--profile-tabs"
                tabPosition={mobile ? "top" : "left"}
                style={{ marginTop: "4vh" }}
              >
                {prestations.map(
                  (p, i) =>
                    p.actif && (
                      <TabPane tab={p.name} key={i}>
                        {p.details.map((x, j) => (
                          <Row
                            className={
                              j == p.details.length - 1
                                ? ""
                                : "bam--border-bottom"
                            }
                            style={{
                              padding: "1vh",
                              width: "90%",
                            }}
                          >
                            <Col xl={10} md={10} xs={10}>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    {x.details}
                                  </span>
                                }
                                color={"#fff"}
                              >
                                <div>
                                  <span className="bam--service-title">
                                    {x.name}
                                  </span>
                                </div>
                              </Tooltip>

                              <span
                                className="bam--profile-time"
                                style={{ marginTop: "2vh" }}
                              >
                                {x.hour > 0 && x.hour + " heure(s) "}
                                {x.minute > 0 && x.minute + " minutes"}
                              </span>
                            </Col>
                            <Col xl={4} md={4} xs={4}>
                              <span className="bam--profile-price">
                                {x.price}€
                              </span>
                            </Col>
                            <Col xl={10} md={10} xs={10}>
                              <Button
                                className="bam--profile-select"
                                onClick={() =>
                                  openModal(x.idPrestationBeautyPro, p.name, x)
                                }
                              >
                                Séléctionner
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </TabPane>
                    )
                )}
              </Tabs>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: "4vh" }}>
          <Col xl={2} md={2} xs={1}></Col>
          <Col xl={21} md={21} xs={23}>
            <hr className="bam--hr-became" style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row style={{ marginTop: "4vh" }}>
          <Col xl={2} md={2} xs={1}></Col>
          <Col xl={21} md={21} xs={23} ref={avisRef}>
            <Avis list={avis} />
          </Col>
        </Row>
        <Modal
          className="bam--modal-profil"
          title={<span className="bam--profile-name"> Votre Réservation</span>}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={
            <Button
              disabled={hour == "" || date == null}
              className="bam--button-carrousel-text"
              onClick={() => validatePrestation()}
            >
              Réserver
            </Button>
          }
        >
          <span className="bam--profile-name" style={{ fontSize: "20px" }}>
            {prestation.name}
          </span>
          <p className="bam--service-title" style={{ marginTop: "1vh" }}>
            {prestation.subName} - {prestation.price}€
          </p>
          <span className="bam--service-title">Date: </span>{" "}
          <DatePicker
            format={"YYYY-MM-DD"}
            disabledDate={disabledDate}
            onChange={(e, d) => onChangeDate(d)}
            defaultValue={moment(date)}
          ></DatePicker>
          <div style={{ marginTop: "2vh" }}>
            <Button
              disabled={checkIfTimeAvailable(9, 0)}
              onClick={() => setHour("09:00")}
            >
              09:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(9, 15)}
              onClick={() => setHour("09:15")}
            >
              09:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(9, 30)}
              onClick={() => setHour("09:30")}
            >
              09:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(9, 45)}
              onClick={() => setHour("09:45")}
            >
              09:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(10, 0)}
              onClick={() => setHour("10:00")}
            >
              10:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(10, 15)}
              onClick={() => setHour("10:15")}
            >
              10:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(10, 30)}
              onClick={() => setHour("10:30")}
            >
              10:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(10, 45)}
              onClick={() => setHour("10:45")}
            >
              10:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(11, 0)}
              onClick={() => setHour("11:00")}
            >
              11:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(11, 15)}
              onClick={() => setHour("11:15")}
            >
              11:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(11, 30)}
              onClick={() => setHour("11:30")}
            >
              11:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(11, 45)}
              onClick={() => setHour("11:45")}
            >
              11:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(12, 0)}
              onClick={() => setHour("12:00")}
            >
              12:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(12, 15)}
              onClick={() => setHour("12:15")}
            >
              12:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable("12:30")}
              onClick={() => setHour("12:30")}
            >
              12:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(12, 45)}
              onClick={() => setHour("12:45")}
            >
              12:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(14, 0)}
              onClick={() => setHour("14:00")}
            >
              14:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(14, 15)}
              onClick={() => setHour("14:15")}
            >
              14:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(14, 30)}
              onClick={() => setHour("14:30")}
            >
              14:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(14, 45)}
              onClick={() => setHour("14:45")}
            >
              14:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(15, 0)}
              onClick={() => setHour("15:00")}
            >
              15:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(15, 15)}
              onClick={() => setHour("15:15")}
            >
              15:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(15, 30)}
              onClick={() => setHour("15:30")}
            >
              15:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(15, 45)}
              onClick={() => setHour("15:45")}
            >
              15:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(16, 0)}
              onClick={() => setHour("16:00")}
            >
              16:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(16, 15)}
              onClick={() => setHour("16:15")}
            >
              16:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(16, 30)}
              onClick={() => setHour("16:30")}
            >
              16:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(16, 45)}
              onClick={() => setHour("16:45")}
            >
              16:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(17, 0)}
              onClick={() => setHour("17:00")}
            >
              17:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(17, 15)}
              onClick={() => setHour("17:15")}
            >
              17:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(17, 30)}
              onClick={() => setHour("17:30")}
            >
              17:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(17, 45)}
              onClick={() => setHour("17:45")}
            >
              17:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(18, 0)}
              onClick={() => setHour("18:00")}
            >
              18:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(18, 15)}
              onClick={() => setHour("18:15")}
            >
              18:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(18, 30)}
              onClick={() => setHour("18:30")}
            >
              18:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(18, 45)}
              onClick={() => setHour("18:45")}
            >
              18:45
            </Button>
            <Button
              disabled={checkIfTimeAvailable(19, 0)}
              onClick={() => setHour("19:00")}
            >
              19:00
            </Button>
            <Button
              disabled={checkIfTimeAvailable(19, 15)}
              onClick={() => setHour("19:15")}
            >
              19:15
            </Button>
            <Button
              disabled={checkIfTimeAvailable(19, 30)}
              onClick={() => setHour("19:30")}
            >
              19:30
            </Button>
            <Button
              disabled={checkIfTimeAvailable(19, 45)}
              onClick={() => setHour("19:45")}
            >
              19:45
            </Button>
          </div>
        </Modal>
      </LoadingScreen>
    </>
  );
};

export default inject("UserStore")(observer(Profile));
