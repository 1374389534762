import { Button, Col, Form, Input, Modal, Popconfirm, Row } from "antd";
import "antd/dist/antd.css";
import jwt_decode from "jwt-decode";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import cookie from "react-cookies";
import SocialAuth from "../components/SocialAuth";
import UserStore from "../Datastores/UserStore";
import history from "../utils/History";
import { errorNotification, openNotification } from "../utils/Utils";
import { useLocation } from "react-router-dom";

const Login = ({ isModalVisible, openModalBecamePro }) => {
  const location = useLocation();

  const [form] = Form.useForm();
  const [formForget] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [forgetModal, setForget] = useState(false);
  const closeModal = () => {
    UserStore.setLoginModalVisible();
  };
  const login = () => {
    setLoading(true);
    UserStore.login(form.getFieldsValue()).then(
      (res) => {
        var decodedHeader = jwt_decode(res.data.token.replace("Bearer ", ""));

        cookie.save("token", res.data.token, { path: "/" });
        let msg = "Bienvenue sur BAM!";
        if (
          decodedHeader.statusPaiement < 20 &&
          decodedHeader.statusPaiement > 0
        ) {
          msg =
            msg +
            " Plus que " +
            decodedHeader.statusPaiement +
            " pour le prochain paiement";
        }
        openNotification("Connexion réussie", msg);
        UserStore.setLoginModalVisible();
        var roles = decodedHeader.role;
        if (!location.pathname.includes("/profil")) {
          if (roles.includes("ROLE_BEAUTY_PRO")) {
            history.push("/espacepro/");
          } else if (roles.includes("ROLE_ADMIN")) {
            history.push("/espaceadmin/");
          } else {
            history.push("/espaceuser/");
          }
        }
        setLoading(false);
      },
      (error) => {
        errorNotification("Erreur", "Email ou mot de passe incorrect");
        setLoading(false);
      }
    );
  };
  const forget = () => {
    setLoading(true);
    formForget.validateFields().then((values) => {
      UserStore.regenerateKey(values).then((res) => {
        setForget(false);
        openNotification("", "Un mail vous été envoyé!");
      });
    });
  };
  function confirm(e) {
    UserStore.setLoginModalVisible();
    UserStore.setRegisterModalVisible();
  }
  function openBecamePro(e) {
    UserStore.setLoginModalVisible();
    openModalBecamePro();
  }

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => closeModal()}
        footer={null}
      >
        <div>
          <span className="bam--section-title">Welcome back!</span>
        </div>
        <div>
          <span className="bam--nouveau--inscription">Nouveau sur BAM?</span>
          <Popconfirm
            title="Inscrivez vous en tant que client ?"
            onConfirm={confirm}
            onCancel={openBecamePro}
            okText="Oui"
            cancelText="Non"
          >
            <a className="bam--lien--nouveau--inscription" href="#">
              {" "}
              Inscrivez vous{" "}
            </a>
          </Popconfirm>
        </div>
        <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
          <Form.Item
            name="username"
            label="Identifiant"
            className="bam--label-form"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input
              className="bam--input"
              placeholder="Tapez votre adresse email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "Champ obligatoire",
              },
            ]}
          >
            <Input.Password
              className="bam--input"
              visibilityToggle={false}
              placeholder="Tapez votre mot de passe"
            />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Button
                loading={loading}
                htmlType="submit"
                className="bam--button-carrousel-text"
                onClick={() => {
                  login();
                }}
              >
                <span>Me connecter</span>
              </Button>
            </Col>
            <Col span="12" style={{ textAlign: "right", marginTop: "1vh" }}>
              <a
                onClick={() => {
                  setForget(true);
                  closeModal();
                }}
                className="bam--forget--password"
              >
                Mot de passe oublié{" "}
              </a>
            </Col>
          </Row>
        </Form>
        <SocialAuth />
      </Modal>
      <Modal
        visible={forgetModal}
        footer={null}
        onCancel={() => setForget(false)}
      >
        <div style={{ marginTop: "2vh" }}>
          <span className="bam--section-title">Mot de passe oublié</span>
        </div>
        <Form
          form={formForget}
          layout={"vertical"}
          style={{ marginTop: "2vh" }}
        >
          <Form.Item
            name="email"
            label="Identifiant"
            className="bam--label-form"
            rules={[
              {
                type: "email",
                required: true,
                message: "Champ obligatoire",
              },
            ]}
          >
            <Input
              className="bam--input"
              placeholder="Tapez votre adresse email"
            />
          </Form.Item>

          <Row justify="center" style={{ marginTop: "2vh" }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Button
                loading={loading}
                htmlType="submit"
                className="bam--button-carrousel-text"
                onClick={() => {
                  forget();
                }}
              >
                <span>Valider</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default inject("UserStore")(observer(Login));
