import { Anchor, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../../Styles/homepro.scss";
import agenda from "../../Styles/images/agenda.svg";
import avis from "../../Styles/images/avis.svg";
import ca from "../../Styles/images/ca.svg";
import rdv from "../../Styles/images/rdv.svg";
import history from "../../utils/History";
import { getInfoIndex } from "../ProfBeaute/APIs";
import ReactGA from "react-ga";

const Home = ({}) => {
  const { Link } = Anchor;
  const [info, setInfo] = useState({});
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (Object.keys(info).length === 0) {
      getInfoIndex().then((data) => {
        setInfo(data.data.success[0]);
      });
    }
  }, [info]);
  return (
    <>
      <Row>
        <Col xl={24} md={24} xs={24}>
          <Card className="bam--card" bordered={true}>
            <Row>
              <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
                <span className="bam--title--entreprise">
                  {info.companyName}
                </span>
              </Col>
            </Row>
            <Row>
              <Col
                xl={24}
                md={24}
                xs={24}
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                <span className="bam--adress--entreprise">{info.adress}</span>
              </Col>
            </Row>
            <Row>
              <Col
                xl={24}
                md={24}
                xs={24}
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                <a
                  onClick={() => {
                    history.push({
                      pathname: "/profil/" + info.id + "/" + info.companyName,
                      state: { id: info.id, date: new Date() },
                    });
                  }}
                  className="bam--link--profileEntreprise"
                  href=""
                  title="Visualiser mon profil"
                >
                  Visualiser mon profil
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[8, 8, 8, 8]} style={{ marginTop: "2vh" }}>
        <Col md={12} xl={12} xs={24}>
          <Card className="bam--card" bordered={true}>
            <Row
              style={{
                marginTop: "6vh",
              }}
            >
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <img src={avis} />
              </Col>
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <span className="bam--card--number">{info.avisNumber}</span>
                <Row>
                  <span className="bam--card--title">Note globale</span>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={12} xl={12} xs={24}>
          <Card className="bam--card" bordered={true}>
            <Row
              style={{
                marginTop: "6vh",
              }}
            >
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <img src={ca} />
              </Col>
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <span className="bam--card--number">{info.CA}€</span>
                <Row>
                  <span className="bam--card--title">
                    CA réalisé depuis le début du mois
                  </span>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={12} xl={12} xs={24}>
          <Card className="bam--card" bordered={true}>
            <Row
              style={{
                marginTop: "6vh",
              }}
            >
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <img src={agenda} />
              </Col>
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <span className="bam--card--number">
                  {info.reservationsToday}
                </span>
                <Row>
                  <span className="bam--card--title">
                    Nombre de RDV prévus dans la journée
                  </span>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={12} xl={12} xs={24}>
          <Card className="bam--card" bordered={true}>
            <Row
              style={{
                marginTop: "6vh",
              }}
            >
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <img src={rdv} />
              </Col>
              <Col xl={2} />
              <Col xl={5} md={5} xs={10}>
                <span className="bam--card--number">
                  {info.reservationsValider}
                </span>
                <Row>
                  <span className="bam--card--title">
                    Nombre de RDV réalisés depuis le début du mois
                  </span>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Home;
