import { Button, Col, Menu, Row } from "antd";
import jwt_decode from "jwt-decode";
import { inject, observer } from "mobx-react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import cookie from "react-cookies";
import Login from "../pages/Login";
import BecamePro from "../pages/ProfBeaute/BecamePro";
import Register from "../pages/Register";
import burger from "../Styles/images/BURGER.svg";
import { createBrowserHistory } from "history";

const Navigation = ({ props, UserStore, isProfile }) => {
  const history = createBrowserHistory({
    forceRefresh: true
  });

  const [current, setCurrent] = useState("mail");
  const [isBecameModalVisible, setIsBecameModalVisible] = useState(false);
  const [roles, setRoles] = useState([]);
  const [mobile, setMobile] = useState(false);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 900) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleClick = (e) => {
    setCurrent(e.key);
  };
  useEffect(() => {
    if (window.location.pathname.includes("devenirpro")) {
      window.fbq("track", "Subscribe", {
        value: "0.00",
        currency: "EUR"
      });
      setIsBecameModalVisible(true);
    }
    if (cookie.load("token")) {
      var decodedHeader = jwt_decode(
        cookie.load("token").replace("Bearer ", "")
      );
      setRoles(decodedHeader.role);
    }
  }, [cookie.load("token")]);

  return (
    <>
      {mobile ? (
        <Row>
          <Col xl={14} md={14} xs={8}>
            <Menu
              onClick={handleClick}
              mode="horizontal"
              collapsedWidth="100"
              overflowedIndicator={<img src={burger} />}
            >
              <Menu.Item
                key="blog"
                className="bam--menu-item"
                onClick={() =>
                  history.replace({
                    pathname: "/blog",
                    state: {
                      refresh: true
                    }
                  })
                }
              >
                Blog
              </Menu.Item>
              <Menu.Item
                key="about"
                className="bam--menu-item"
                onClick={() => history.push("/about")}
              >
                À propos
              </Menu.Item>
              <Menu.Item
                key="bamPro"
                className="bam--menu-item"
                onClick={() => history.push("/bampro")}
              >
                BAM Pro
              </Menu.Item>

              <Menu.Item key="connect" className="bam--menu-item">
                {roles == null || roles.length === 0 ? (
                  <span
                    className="bam--menu-connect"
                    onClick={() => {
                      UserStore.setLoginModalVisible();
                    }}
                  >
                    Me connecter
                  </span>
                ) : (
                  <span
                    className="bam--menu-connect"
                    onClick={() => {
                      if (roles.includes("ROLE_BEAUTY_PRO")) {
                        history.push("/espacepro/");
                      } else if (roles.includes("ROLE_ADMIN")) {
                        history.push("/espaceadmin/");
                      } else {
                        history.push("/espaceuser/");
                      }
                    }}
                  >
                    Mon espace
                  </span>
                )}
              </Menu.Item>
            </Menu>
          </Col>
          <Col xl={10} md={10} xs={8} style={{ textAlign: "center" }}>
            <img
              style={{ cursor: "pointer", width: "60%" }}
              onClick={() => history.push("/")}
              src="/images/bam-icon.svg"
            />
          </Col>
          <Col xl={10} md={10} xs={8} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                window.fbq("track", "Subscribe", {
                  value: "0.00",
                  currency: "EUR"
                });
                setIsBecameModalVisible(true);
              }}
              className="bam--menu-button-pro"
            >
              Devenir Pro
            </Button>
          </Col>
        </Row>
      ) : isProfile ? (
        <Row>
          <Col xl={2} md={2} xs={0} />

          <Col xl={10} md={10} xs={19}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
              src="/images/bam-icon.svg"
            />
          </Col>
          <Col xl={10} md={10} xs={5}>
            <Menu
              onClick={handleClick}
              mode="horizontal"
              style={{ float: "right" }}
              collapsedWidth="100"
              overflowedIndicator={<img src={burger} />}
            >
              <Menu.Item
                key="blog"
                className="bam--menu-item"
                onClick={() =>
                  history.replace({
                    pathname: "/blog",
                    state: {
                      refresh: true
                    }
                  })
                }
              >
                Blog
              </Menu.Item>
              <Menu.Item
                key="about"
                className="bam--menu-item"
                onClick={() => history.push("/about")}
              >
                À propos
              </Menu.Item>
              <Menu.Item
                key="bamPro"
                className="bam--menu-item"
                onClick={() => history.push("/bampro")}
              >
                BAM Pro
              </Menu.Item>
              <Menu.Item
                key="pro"
                className="bam--menu-item"
                onClick={() => {
                  window.fbq("track", "Subscribe", {
                    value: "0.00",
                    currency: "EUR"
                  });
                  setIsBecameModalVisible(true);
                }}
              >
                <Button className="bam--menu-button-pro">Devenir Pro</Button>
              </Menu.Item>
              <Menu.Item key="connect" className="bam--menu-item">
                {roles == null || roles.length === 0 ? (
                  <span
                    className="bam--menu-connect"
                    onClick={() => {
                      UserStore.setLoginModalVisible();
                    }}
                  >
                    Me connecter
                  </span>
                ) : (
                  <span
                    className="bam--menu-connect"
                    onClick={() => {
                      if (roles.includes("ROLE_BEAUTY_PRO")) {
                        history.push("/espacepro/");
                      } else if (roles.includes("ROLE_ADMIN")) {
                        history.push("/espaceadmin/");
                      } else {
                        history.push("/espaceuser/");
                      }
                    }}
                  >
                    Mon espace
                  </span>
                )}
              </Menu.Item>
            </Menu>
          </Col>
          <Col xl={2} md={2} xs={0} />
        </Row>
      ) : (
        <Row>
          <Col xl={1} md={1} xs={1} />

          <Col xl={10} md={10} xs={19}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
              src="/images/bam-icon.svg"
            />
          </Col>
          <Col xl={13} md={13} xs={4}>
            <Menu
              onClick={handleClick}
              mode="horizontal"
              style={{ float: "right" }}
              collapsedWidth="100"
              overflowedIndicator={<img src={burger} />}
            >
              <Menu.Item
                key="blog"
                className="bam--menu-item"
                onClick={() =>
                  history.replace({
                    pathname: "/blog",
                    state: {
                      refresh: true
                    }
                  })
                }
              >
                Blog
              </Menu.Item>
              <Menu.Item
                key="about"
                className="bam--menu-item"
                onClick={() => history.push("/about")}
              >
                À propos
              </Menu.Item>
              <Menu.Item
                key="bamPro"
                className="bam--menu-item"
                onClick={() => history.push("/bampro")}
              >
                BAM Pro
              </Menu.Item>
              <Menu.Item
                key="pro"
                className="bam--menu-item"
                onClick={() => setIsBecameModalVisible(true)}
              >
                <Button className="bam--menu-button-pro">Devenir Pro</Button>
              </Menu.Item>
              <Menu.Item key="connect" className="bam--menu-item">
                {roles == null || roles.length === 0 ? (
                  <span
                    className="bam--menu-connect"
                    onClick={() => {
                      UserStore.setLoginModalVisible();
                    }}
                  >
                    Me connecter
                  </span>
                ) : (
                  <span
                    className="bam--menu-connect"
                    onClick={() => {
                      if (roles.includes("ROLE_BEAUTY_PRO")) {
                        history.push("/espacepro/");
                      } else if (roles.includes("ROLE_ADMIN")) {
                        history.push("/espaceadmin/");
                      } else {
                        history.push("/espaceuser/");
                      }
                    }}
                  >
                    Mon espace
                  </span>
                )}
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      )}

      <BecamePro
        isModalVisible={isBecameModalVisible}
        //isModalVisible={true}
        closeModal={() => setIsBecameModalVisible(false)}
      />

      <Login
        isModalVisible={UserStore.showLoginModal}
        openModalBecamePro={() => setIsBecameModalVisible(true)}
      />
      <Register isModalVisible={UserStore.showRegisterModal} />
    </>
  );
};

export default inject("UserStore")(observer(Navigation));
