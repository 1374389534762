import { Button, Switch, Row, Col, Form, Input, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import htmlToDraft from "html-to-draftjs";
import DataTable from "react-data-table-component";
import {
  addArticle,
  changeStatusBlog,
  getBlog,
  getPrestationsAll,
} from "../ProfBeaute/APIs";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { EditorState, convertToRaw } from "draft-js";
import { convertFromHTML, ContentState } from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { errorNotification, openNotification } from "../../utils/Utils";

const BlogAdmin = ({}) => {
  const [open, setOpenModal] = useState(false);
  const [image, setImage] = useState([]);

  const [prestations, setPrestations] = useState([]);
  const [list, setList] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [form] = Form.useForm();
  const [allFiles, setAllFiles] = useState([]);
  const [id, setId] = useState(-1);

  const onEditorStateChange = (editor) => {
    setEditorState(editor);
  };

  const onEditorDescriptionChange = (editor) => {
    setDescription(editor);
  };

  const changeStatus = (id) => {
    changeStatusBlog({ id: id }).then(() => {
      openNotification("Informations", "Article modifié avec succès");
      getBlog().then((res) => {
        setList(res.data);
        console.log(res);
      });
    });
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
  };

  const modifier = (value) => {
    setId(value.id);
    var blocksFromHTML = convertFromHTML(value.text);
    var content = ContentState.createFromBlockArray(blocksFromHTML);

    setEditorState(EditorState.createWithContent(content));
    blocksFromHTML = convertFromHTML(value.description);
    content = ContentState.createFromBlockArray(blocksFromHTML);

    setDescription(EditorState.createWithContent(content));
    form.setFieldsValue(value);
    setOpenModal(true);

    toDataUrl(
      process.env.REACT_APP_BASE_URL + "images/" + value.media,
      (myBase64) => {
        fetch(myBase64).then((res) => {
          res.arrayBuffer().then((buf) => {
            const file = new File([buf], value.media, {
              type: "image/jpeg",
            });
            console.log(value);
            setImage(file);
          });
        });
      }
    );
  };

  const columns = [
    {
      name: "Date",
      selector: "date",
      sortable: true,
    },
    {
      name: "Titre",
      selector: "title",
      sortable: true,
    },
    {
      name: "Catégory",
      selector: "categoryName",
      sortable: true,
    },
    {
      cell: (p) => (
        <Button
          onClick={() => {
            changeStatus(p.id, p.actif);
          }}
          checked={p.actif}
          onClick={() => modifier(p)}
        >
          Modifier
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (p) => (
        <Switch
          primary={!p.actif}
          onClick={() => {
            changeStatus(p.id, p.actif);
          }}
          danger={p.actif}
          checked={p.actif}
        ></Switch>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    cells: {
      style: {
        fontSize: "1rem",
      },
    },
  };

  useEffect(() => {
    getBlog().then((res) => {
      setList(res.data);
    });
    getPrestationsAll().then((res) => {
      setPrestations(res);
    });
  }, []);

  const saveBlog = () => {
    if (allFiles.length == 0) {
      errorNotification("Error", "Il faut ajouter ou valider l'image");
      return;
    }

    addArticle(
      id,
      form.getFieldValue("title"),
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
      draftToHtml(convertToRaw(description.getCurrentContent())),
      allFiles,
      form.getFieldValue("categoryId")
    ).then(() => {
      setTimeout(() => {
        getBlog().then((res) => {
          setList(res.data);
        });
      }, 1500);
    });
    setOpenModal(false);
  };
  const handleSubmitAvatar = (files, allFiles) => {
    setAllFiles(allFiles);
  };

  const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  return (
    <>
      <DataTable
        striped
        customStyles={customStyles}
        title={
          <>
            <Row>
              <Col span={18}>Liste des Blogs</Col>
              <Col span={6}>
                <Button
                  style={{
                    marginTop: "2vh",
                    float: "right",
                    marginRight: "",
                  }}
                  className="bam--button-carrousel-text"
                  onClick={() => {
                    setId(-1);
                    setOpenModal(true);
                  }}
                >
                  {" "}
                  Ajouter
                </Button>
              </Col>
            </Row>
          </>
        }
        columns={columns}
        data={list}
        fixedHeader
        noDataComponent={<span>Pas de blog trouvé(s)</span>}
      />
      <Modal
        width="150vh"
        onCancel={() => setOpenModal(false)}
        visible={open}
        title={id == -1 ? "Ajouter un article" : "Modifier l'article"}
        style={{ minHeight: "80vh" }}
        footer={[]}
      >
        <Form form={form} layout={"vertical"}>
          <div>
            <span className="bam--section-title">Photo de l'article</span>
          </div>
          <Dropzone
            id="myDropzone"
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmitAvatar}
            maxFiles={1}
            initialFiles={[image]}
            accept="image/*"
            inputContent="Ajouter la photo de l'article"
            submitButtonDisabled={(files) => files.length < 1}
            dictDefaultMessage="envoyer"
            submitButtonContent="Valider"
          />
          <Form.Item
            label="Catégorie"
            className="bam--label-form"
            name="categoryId"
            rules={[
              {
                required: true,
                message: "merci d'entrer la catégorie ",
              },
            ]}
          >
            <Select
              // onChange={onChange}
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              {prestations.map((p, i) => (
                <Select.Option value={p.id}>{p.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="title"
            label="Titre"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "merci d'entrer le titre",
              },
            ]}
          >
            <Input className="bam--input" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "merci d'entrer la description",
              },
            ]}
          >
            <Editor
              fontFamilies={["Helvetica"]}
              editorState={description}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorDescriptionChange}
            />
          </Form.Item>
          <Form.Item
            name="text"
            label="Text"
            rules={[
              {
                required: true,
                message: "merci d'entrer le text",
              },
            ]}
          >
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </Form.Item>
          <Row justify="end">
            <Col span="24">
              <Button
                style={{
                  marginTop: "2vh",
                  float: "right",
                  marginRight: "",
                }}
                className="bam--button-carrousel-text"
                onClick={() => saveBlog(true)}
              >
                {" "}
                Valider
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default BlogAdmin;
