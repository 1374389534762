import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import ReactGA from "react-ga";
import { useParams } from "react-router-dom";
import moment from "moment";
import { errorNotification, openNotification } from "../utils/Utils";
import history from "../utils/History";
import CAxios from "../utils/CAxios";
import axios from "axios";

const Pwd = () => {
  const [form] = Form.useForm();
  const { key } = useParams();
  const [expired, setExpired] = useState(false);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scroll(0, 0);
    var currDate = moment(new Date());
    var keyDate = moment(key, "X");
    var duration = moment.duration(currDate.diff(keyDate));
    if (duration.asHours() > 24) {
      setExpired(true);
    }
  }, []);

  const save = () => {
    form.validateFields().then((res) => {
      console.log(res);
      if (res.password1 == res.password2) {
        updatePWD(key, res.password1).then((res) => {
          openNotification(
            "Succès!",
            "Modifications enregistrées avec succès !"
          );
          setTimeout(() => {
            history.push("/");
          }, 500);
        });
      } else {
        errorNotification("Les deux mot de passe ne sont pas identiques.");
      }
    });
  };

  const updatePWD = (key, pwd) => {
    return axios.post(process.env.REACT_APP_BASE_URL + "auth/changepwd", {
      key: key,
      pwd: pwd,
    });
  };
  return (
    <div style={{ minHeight: "50vh" }}>
      {expired ? (
        <div style={{ textAlign: "center", marginTop: "5vh" }}>
          <span
            className="bam--label-form"
            style={{ fontSize: "2rem", textAlign: "center" }}
          >
            Ce lien est expiré.
          </span>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "5vh" }}>
          <Form form={form} layout={"vertical"}>
            <span
              className="bam--label-form"
              style={{ fontSize: "2rem", textAlign: "center" }}
            >
              Changement du mot de passe
            </span>
            <Row style={{ marginTop: "2vh", textAlign: "center" }}>
              <Col xs={8} md={8} xl={8} offset="8">
                <Form.Item
                  name="password1"
                  label="Entrez votre nouveau mot de passe"
                  className="bam--label-form"
                  rules={[
                    {
                      required: true,
                      message: "merci d'entrer nouveau mot de passe",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Tapez le nouveu mot de passe"
                    className="bam--input"
                  />
                </Form.Item>
                <Form.Item
                  name="password2"
                  label="Confirmez votre mot de passe"
                  className="bam--label-form"
                  rules={[
                    {
                      required: true,
                      message: "merci d'entrer nouveau mot de passe",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Tapez le nouveu mot de passe"
                    className="bam--input"
                  />
                </Form.Item>
                <Button
                  style={{
                    marginTop: "2vh",
                    textAlign: "center",
                  }}
                  className="bam--button-carrousel-text"
                  htmlType="submit"
                  onClick={save}
                >
                  Enregistrer
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Pwd;
