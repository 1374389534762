import { Row, Col, Space } from "antd";
import "antd/dist/antd.css";
import React from "react";
import moment from "moment";
import localization from "moment/locale/fr";
moment.updateLocale("fr", localization);

const Avis = ({ list }) => {
  const toConvertDate = (date) => {
    return moment(date).format("MMMM YYYY");
  };

  const getAvis = () => {
    if (list.length == 0) {
      return 0;
    }
    var sum = list.reduce((a, b) => a + b.vote, 0);
    return Math.round(sum / list.length);
  };

  return (
    <>
      <div>
        <span className="bam--profile-name">Avis</span>
      </div>
      <div className="bam--margin-avis">
        <Row style={{ display: "block" }}>
          <span className="bam--profile-rate">{getAvis()}</span>
          <span style={{ marginLeft: "1vh" }} className="bam--profile-rate">
            {getAvis() > 0 && (
              <img src={require("../Styles/images/star.svg")} />
            )}
            {getAvis() > 1 && (
              <img
                style={{ marginLeft: "2px" }}
                src={require("../Styles/images/star.svg")}
              />
            )}
            {getAvis() > 2 && (
              <img
                style={{ marginLeft: "2px" }}
                src={require("../Styles/images/star.svg")}
              />
            )}
            {getAvis() > 3 && (
              <img
                style={{ marginLeft: "2px" }}
                src={require("../Styles/images/star.svg")}
              />
            )}
            {getAvis() > 4 && (
              <img
                style={{ marginLeft: "2px" }}
                src={require("../Styles/images/star.svg")}
              />
            )}
          </span>
          <span
            className="bam--profile-nb-rate"
            style={{ marginLeft: "5px", cursor: "pointer" }}
          >
            ({list.length + " avis"})
          </span>
        </Row>
      </div>

      <Row style={{ marginTop: "4vh" }}>
        {list.map((a) => (
          <Col xl={10} md={10} xs={24}>
            <Row>
              <Col span={4}>
                <img
                  className="bam--avis-img"
                  src={require("../Styles/images/imgProfile.png")}
                />
              </Col>
              <Col span="1" />
              <Col span={14}>
                <span className="bam--avis-name">{a.user}</span>
                <br />
                <span className="bam--avis-date">{toConvertDate(a.date)}</span>
              </Col>
            </Row>
            <p className="bam--avis-descrption">{a.text}</p>
          </Col>
        ))}

        {/* <Col xl={2} md={2} xs={0}></Col>

        <Col xl={10} md={10} xs={24}>
          <Row>
            <Col span={4}>
              <img
                className="bam--avis-img"
                src={require("../Styles/images/imgProfile.png")}
              />
            </Col>
            <Col span={14}>
              <span className="bam--avis-name">Cindy</span>
              <br />
              <span className="bam--avis-date">Décembre 2020</span>
            </Col>
          </Row>
          <p className="bam--avis-descrption">
            J’adore ma nouvelle couleur ! Accueil chaleureux, massage alliant
            douceur et fermeté, je recommande. Merci pour tout et a tres bientot
            :)
          </p>
        </Col>
        */}
      </Row>
    </>
  );
};
export default Avis;
