import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import Avis from "../../components/Avis";
import { getAvis } from "../ProfBeaute/APIs";
import ReactGA from "react-ga";

const MesAvis = () => {
  const [avis, setAvis] = useState([]);
  const [loading, setLoading] = useState(true);
  const ratingChanged = (e) => {};
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getAvis().then((res) => {
      setAvis(res.data.success.data);
      setLoading(false);
      avis.forEach((el) => {});
    });
  }, [loading]);

  return (
    // <Row gutter={[8, 8, 8, 8]}>
    //   {avis.map(el => {
    //     return (
    //       <Col xl={6}>
    //         <Card bordered={true} className="bam--card">
    //           <Col xl={12}>
    //             <span className="bam--avis-name">{el.user}</span>
    //           </Col>
    //           <Row>
    //             <Col xl={6}>
    //               <ReactStars
    //                 count={5}
    //                 onChange={e => ratingChanged(e)}
    //                 size={12}
    //                 value={el.vote}
    //                 activeColor="#ffd700"
    //               />
    //             </Col>
    //             <Col xl={12} style={{ textAlign: "left" }}>
    //               <span className="bam--avis-date"></span>
    //             </Col>
    //           </Row>
    //           <span className="bam--avis-descrption">{el.text}</span>
    //         </Card>
    //       </Col>
    //     );
    //   })}
    // </Row>
    <Row style={{ marginTop: "3vh" }}>
      <Col span={22}>
        <Avis list={avis} />
      </Col>
    </Row>
  );
};

export default MesAvis;
