import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getHistoryPaiementAdmin } from "../ProfBeaute/APIs";

const HistoriquePaiemenet = ({}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getHistoryCheckout();
  }, []);
  const getHistoryCheckout = () => {
    getHistoryPaiementAdmin().then(data => {
      setData(data);
    });
  };

  const columns = [
    {
      name: "Utilisateur",
      selector: "user",
      sortable: true
    },
    {
      name: "Type d'abonnement",
      selector: "type",
      sortable: true,
      format: p => <span>{p.type === "m" ? "Mensuelle" : "Annuelle"}</span>
    },
    {
      name: "Prix",
      selector: "prix",
      sortable: true
    }
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1.2rem"
      }
    },
    cells: {
      style: {
        fontSize: "1rem"
      }
    }
  };
  return (
    <>
      <DataTable
        striped
        customStyles={customStyles}
        title="Liste de paiement"
        columns={columns}
        data={data}
        fixedHeader
        noDataComponent={<span>Pas de paiement trouvés</span>}
      />
    </>
  );
};

export default HistoriquePaiemenet;
