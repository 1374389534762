import { Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { openNotification } from "../../utils/Utils";
import {
  toGetReservationsAll,
  toValidateReservations,
} from "../ProfBeaute/APIs";
import ReactGA from "react-ga";

const MesReservations = ({}) => {
  const columns = [
    {
      name: "Client",
      selector: "user",
      sortable: true,
    },
    {
      name: "Prestation",
      selector: "prestation",
      sortable: true,
    },
    {
      name: "Date de réservation",
      selector: "date",
      sortable: true,
    },

    {
      name: "Etat",
      selector: "status",
      sortable: true,
      format: (p) => (
        <span>
          {p.status === 1
            ? "Validé"
            : p.status === 0
            ? "pas encore validé"
            : "refusé"}
        </span>
      ),
    },
    {
      cell: (p) => renderButton(p),
    },
  ];
  const renderButton = (p) => {
    {
      return (
        p.status === 0 && (
          <>
            <Button
              onClick={() => {
                validerReservations(p.id, true);
              }}
              className="bam--button-carrousel-text"
            >
              Valider
            </Button>
            <Button
              danger
              onClick={() => {
                validerReservations(p.id, false);
              }}
              className="bam--button-carrousel-text"
              style={{ backgroundColor: "#ff4d4f", marginLeft: "1vh" }}
            >
              Refuser
            </Button>
          </>
        )
      );
    }
  };
  const validerReservations = (id, value) => {
    toValidateReservations(id, value).then((data) => {
      if (data) {
        openNotification("Succès!", "Modifications enregistrées avec succès !");
        getReservations();
      }
    });
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    cells: {
      style: {
        fontSize: "1rem",
      },
    },
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getReservations();
  }, []);

  const getReservations = () => {
    toGetReservationsAll().then((res) => {
      setData(res);
    });
  };
  return (
    <Row>
      <Col span="24">
        <DataTable
          pagination={true}
          paginationPerPage={10}
          striped
          customStyles={customStyles}
          title="Liste des réservations"
          columns={columns}
          data={data}
          fixedHeader
          noDataComponent={<span>Pas de réservations trouvées</span>}
        />
      </Col>
    </Row>
  );
};

export default MesReservations;
