import { LogoutOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import homeIcon from "../../Styles/images/homeIcon.svg";
import history from "../../utils/History";

const MenuItem = ({}) => {
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    window.addEventListener("resize", resize);
  }, []);

  const resize = () => {
    setCollapsed(true);
  };
  return (
    <>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        theme="dark"
        style={{
          background: "#4ba48e"
        }}
      >
        <Menu.Item
          onClick={() => history.push("/espacepro/")}
          style={{
            background: "none"
          }}
          key="1"
          icon={<img src={homeIcon} />}
        >
          Reservations
        </Menu.Item>

        <Menu.Item
          style={{
            background: "none"
          }}
          onClick={() => {
            cookie.remove("token", { path: "/" });
            history.push("/");
          }}
          key="9"
          icon={<LogoutOutlined size="large" />}
        >
          Déconnexion
        </Menu.Item>
      </Menu>
    </>
  );
};

export default MenuItem;
