import {
  LogoutOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import homeIcon from "../../Styles/images/homeIcon.svg";
import prestationsIcon from "../../Styles/images/prestationsIcon.svg";
import history from "../../utils/History";
import { useLocation } from "react-router-dom";

const MenuItem = ({}) => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const [key, setKey] = useState("1");
  useEffect(() => {
    window.addEventListener("resize", resize);
    switch (location.pathname) {
      case "/espaceadmin/":
        setKey("1");
        break;
      case "/espaceadmin/prestations":
        setKey("2");
        break;
      case "/espaceadmin/paiement":
        setKey("3");
        break;
      case "/espaceadmin/settings":
        setKey("4");
        break;
      case "/espaceadmin/blog":
        setKey("5");
        break;

      default:
        break;
    }
  }, []);

  const resize = () => {
    setCollapsed(true);
  };
  return (
    <Menu
      defaultSelectedKeys={[key]}
      defaultOpenKeys={["sub1"]}
      selectedKeys={[key]}
      theme="dark"
      inlineCollapsed={collapsed}
      style={{
        background: "#4ba48e",
      }}
    >
      <Menu.Item
        onClick={() => {
          setKey("1");
          history.push("/espaceadmin/");
        }}
        style={{
          background: "none",
        }}
        key="1"
        icon={<img src={homeIcon} />}
      >
        Professionnels
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setKey("2");
          history.push("/espaceadmin/prestations");
        }}
        key="2"
        style={{
          background: "none",
        }}
        icon={<img src={prestationsIcon} />}
      >
        Prestations
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setKey("3");
          history.push("/espaceadmin/paiement");
        }}
        key="3"
        style={{
          background: "none",
        }}
        icon={<MoneyCollectOutlined size="large" />}
      >
        Historique paiement
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setKey("4");
          history.push("/espaceadmin/settings");
        }}
        key="4"
        style={{
          background: "none",
        }}
        icon={<SettingOutlined size="large" />}
      >
        Paramètres
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setKey("5");
          history.push("/espaceadmin/blog");
        }}
        key="5"
        style={{
          background: "none",
        }}
        icon={<SettingOutlined size="large" />}
      >
        Blog
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          cookie.remove("token", { path: "/" });
          history.push("/");
        }}
        key="6"
        style={{
          background: "none",
        }}
        icon={<LogoutOutlined size="large" />}
      >
        Déconnexion
      </Menu.Item>
    </Menu>
  );
};

export default MenuItem;
