import { Button, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import { openNotification } from "../../utils/Utils";
import { getPriceAbonement, savePaiementAbonnement } from "../ProfBeaute/APIs";
const Parametres = ({}) => {
  const [form] = Form.useForm();
  const [type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getPrix();
  }, []);
  const getPrix = () => {
    getPriceAbonement().then((data) => {
      setType(data);
      form.setFieldsValue({
        type: data[0].type,
        prix: data[0].prix,
      });
    });
  };
  const onChange = (val) => {
    setSelectedType(type.filter((t) => t.type == val)[0]);

    form.setFieldsValue({ prix: type.filter((t) => t.type == val)[0].prix });
  };
  const savePaiement = (val) => {
    setLoading(true);
    savePaiementAbonnement(val).then((data) => {
      if (data.success) {
        openNotification("Succès!", "Modifications enregistrées avec succès !");
        setLoading(false);
      }
    });
  };
  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f5f8fa"
      spinnerColor="#f87d6b"
      textColor="#2D2926"
    >
      <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
        <Form.Item
          label="Choisir le type d'abonnement"
          className="bam--label-form"
          name="type"
        >
          <Select style={{ width: "20%" }} onChange={(val) => onChange(val)}>
            {type.map((t, i) => (
              <Select.Option value={t.type}>
                {t.type === "m" ? "Mensuelle" : "Annuelle"}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row style={{ marginTop: "4vh" }}>
          <Form.Item
            name="prix"
            label="Prix abonnement"
            className="bam--label-form"
          >
            <Input
              placeholder="Prix"
              value={selectedType.prix}
              className="bam--input"
            />
          </Form.Item>
        </Row>
        <Row>
          <Button
            style={{
              float: "right",
              marginRight: "2vh",
            }}
            className="bam--button-carrousel-text"
            htmlType="submit"
            onClick={() => savePaiement(form.getFieldsValue())}
          >
            Enregistrer
          </Button>
        </Row>
      </Form>
    </LoadingScreen>
  );
};

export default Parametres;
