import { message } from "antd";
import axios from "axios";
import cookie from "react-cookies";
import history from "./History";
// Custom axios
const CAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  validateStatus: (status) => status < 400,
});

CAxios.interceptors.request.use((config) => {
  let { url, headers } = config;
  if (cookie.load("token") != null) {
    headers.common["Authorization"] = "Bearer " + cookie.load("token");
  }
  return config;
});

CAxios.interceptors.response.use(null, function (error) {
  // if (error.response && error.response.status === 401) {
  //   AppStore.dispatch(logout());
  // }

  if (error.response && error.response.status === 401) {
    cookie.remove("token", { path: "/" });
    history.push("/");
  }
  if (error.response && error.response.status === 500) {
    cookie.remove("token", { path: "/" });
    history.push("/");
    message.error({
      content:
        "Quelque chose s'est mal passé! Veuillez contacer l'administrateur",
    });
  }
  return Promise.reject(error);
});

export default CAxios;
