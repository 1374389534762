import React, { useEffect, useState } from "react";
import { Row, Col, BackTop, Collapse } from "antd";
import up from "../Styles/images/up.svg";
import plus from "../Styles/images/plus.svg";
import close from "../Styles/images/close.svg";
import ReactGA from "react-ga";

const { Panel } = Collapse;
const style = {
  height: 44,
  width: 44,
  lineHeight: "40px",
  borderRadius: 6,
  backgroundColor: "#52C99F",
  textAlign: "center",
};
const FAQ = () => {
  const [activeKey, setActiveKey] = useState([]);
  const [activeKeyProfile, setActiveKeyProfile] = useState([]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Row style={{ marginTop: "2vh", padding: "24px 50px" }}>
        <Col span="24">
          <span className="bam--section-title">Frequently Asked Questions</span>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={0} md={2} xl={2} />
        <Col xs={23} md={6} xl={6} style={{ marginLeft: "1vh" }}>
          <span className="bam--section-title">
            Boite à questions professionnels
          </span>
        </Col>
        <Col
          xs={23}
          md={10}
          xl={10}
          style={{ marginTop: "2vh", marginLeft: "1vh" }}
        >
          <Collapse
            bordered={false}
            defaultActiveKey={activeKey}
            onChange={(key) => setActiveKey(key)}
          >
            <div className="bam--faq-hr" />
            <Panel
              key="1"
              showArrow={false}
              className="bam--faq-title"
              header="Comment puis-je m’inscrire sur BAM en tant que professionnel ?"
              extra={
                activeKey.includes("1") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Cliquez sur « Devenir Professionnel » à partir de la page
                d’accueil et renseignez les informations nécessaires à la
                création de votre compte « nom, prénom, mail…).
                <ul>
                  <li>
                    Une fois vos accès créés, configurez votre espace
                    professionnel en ajoutant vos horaires, vos prestations, vos
                    photos, etc.
                  </li>
                  <li>
                    Choisissez ensuite de payer l’abonnement mensuel ou annuel
                  </li>
                  <li>
                    Une fois votre compte configuré et le paiement effectué,
                    notre équipe procédera à la vérification de votre compte.
                  </li>
                  <li>
                    Une fois votre compte validé, vous recevrez un email de
                    validation.
                  </li>
                  <li>
                    {" "}
                    Vous êtes désormais prêt à recevoir des réservations !
                  </li>
                </ul>
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="2"
              className="bam--faq-title"
              header=" Puis-je définir mes propres conditions ? (Prestations, prix,
                zone de déplacement…)"
              extra={
                activeKey.includes("2") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Absolument ! Vous définissez vos prestations, vos tarifs, vos
                photos, vos horaires et toutes autres informations présentes sur
                votre profil.
              </p>
              <p className="bam--faq-paragraph">
                BAM est un outil qui sert à faciliter votre gestion et à vous
                mettre en relation avec vos clients. Vous restez décisionnaires
                de vos conditions.
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="3"
              className="bam--faq-title"
              header="Puis-je accueillir mes clients à mon domicile ?"
              extra={
                activeKey.includes("3") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Certains professionnels reçoivent également à leur domicile /
                institut. Vous pouvez le voir grâce à l’indication « reçoit à
                son domicile » dans les résultats de recherche
              </p>
            </Panel>

            <Panel
              showArrow={false}
              key="5"
              className="bam--faq-title"
              header="Quel est le prix ?"
              extra={
                activeKey.includes("5") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Vous bénéficiez d’un mois d’essai gratuit.
              </p>{" "}
              <p className="bam--faq-paragraph">
                Après cela, le forfait mensuel s’élève à 24,99€ HT / mois, ou
                199,99€ HT / an.
              </p>
            </Panel>

            <Panel
              showArrow={false}
              key="6"
              className="bam--faq-title"
              header="Les clients peuvent-ils payer sur BAM directement ?"
              extra={
                activeKey.includes("6") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Aucun paiement ne transite sur BAM, les clients réservent leur
                RDV et vous paient directement sur place.
              </p>{" "}
              <p className="bam--faq-paragraph">
                Pour recevoir un acompte, vous pouvez indiquer votre lien paypal
                sur votre profil.
              </p>
            </Panel>

            <Panel
              showArrow={false}
              key="7"
              className="bam--faq-title"
              header="Comment sont calculées mes disponibilités ?"
              extra={
                activeKey.includes("7") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Vous indiquez vos jours et horaires de disponibilité, ainsi que
                le temps nécessaire à effectuer chaque prestation, et notre
                algorithme calcule et met à jour en temps réel vos
                disponibilités en fonction des réservations de votre agenda.
              </p>{" "}
            </Panel>
          </Collapse>
        </Col>
        <Col span="6" />
      </Row>

      <Row style={{ marginTop: "4vh" }}>
        <Col xs={0} md={2} xl={2} />
        <Col xs={23} md={6} xl={6} style={{ marginLeft: "1vh" }}>
          <span className="bam--section-title">Boite à questions clients</span>
        </Col>
        <Col
          xs={23}
          md={10}
          xl={10}
          style={{ marginTop: "2vh", marginLeft: "1vh" }}
        >
          <Collapse
            bordered={false}
            defaultActiveKey={activeKeyProfile}
            onChange={(key) => setActiveKeyProfile(key)}
          >
            <div className="bam--faq-hr" />
            <Panel
              key="1"
              showArrow={false}
              className="bam--faq-title"
              header="Qui sont les professionnels qui apparaissent sur BAM ?"
              extra={
                activeKeyProfile.includes("1") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Les professionnels qui apparaissent sur BAM sont des
                professionnels indépendants de la beauté, qui exercent à
                domicile chez vous, ou chez eux.{" "}
              </p>
              <p className="bam--faq-paragraph">
                BAM est une plateforme de mise en relations entre vous et ces
                professionnels et n’emploie pas ces derniers.
              </p>
              <p className="bam--faq-paragraph">
                Chaque profil est vérifié et validé par nos équipes avant d’être
                mis en ligne.
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="2"
              className="bam--faq-title"
              header="Comment effectuer une réservation ?              "
              extra={
                activeKeyProfile.includes("2") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Pour réserver une prestation c’est simple :
                <ul>
                  <li>
                    A partir de la page d’accueil, renseignez la prestation que
                    vous recherchez, ainsi que la date et le lieu auquel vous
                    souhaitez recevoir le professionnel.
                  </li>
                  <li>
                    Dans la liste des résultats, consultez le profil de votre
                    choix selon les prestations, les prix et les avis de chacun.
                  </li>
                  <li>
                    Une fois votre professionnel sélectionné, cliquez sur
                    réserver et sélectionnez votre créneau.
                  </li>
                </ul>
                Et voilà, c’est réservé !
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="3"
              className="bam--faq-title"
              header="S’agit-il uniquement de prestations à domicile ?"
              extra={
                activeKeyProfile.includes("3") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Certains professionnels reçoivent également à leur domicile /
                institut. Vous pouvez le voir grâce à l’indication « reçoit à
                son domicile » dans les résultats de recherche
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="4"
              className="bam--faq-title"
              header="Comment modifier/annuler une réservation ?"
              extra={
                activeKeyProfile.includes("4") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Vous pouvez modifier ou annuler votre réservation jusqu’à 24h
                avant votre rendez-vous initialement prévu, en vous rendant
                directement sur votre espace « mes réservations »
              </p>
              <p className="bam--faq-paragraph">
                Pour toute autre demande, veuillez contacter directement votre
                professionnel en consultant les coordonnées renseignées sur son
                profil.
              </p>
            </Panel>
            <Panel
              showArrow={false}
              key="5"
              className="bam--faq-title"
              header="Comment contacter le professionnel ?"
              extra={
                activeKeyProfile.includes("5") ? (
                  <img src={close} />
                ) : (
                  <img src={plus} />
                )
              }
            >
              <p className="bam--faq-paragraph">
                Pour contacter votre professionnel, rendez-vous sur son profil
                afin de consulter les coordonnées renseignées par ce dernier.
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col span="6" />
      </Row>

      <BackTop>
        <div style={style}>
          <img src={up} />
        </div>
      </BackTop>
    </>
  );
};

export default FAQ;
