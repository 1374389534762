import { Menu } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import React, { Component }  from 'react';


const { SubMenu } = Menu;

const Bibliotheque = (props) => {
  return <>Biblio</>;
};

export default Bibliotheque;
