import { Button, Col, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { getAllMedias, toUploadPhotos } from "../ProfBeaute/APIs";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import ReactGA from "react-ga";
import { errorNotification } from "../../utils/Utils";

const Photo = () => {
  const [avatar, setAvatar] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getAllMedias().then((images) => {
      toDataUrl(
        "https://backbam.cody.tn/images/" + images[0].name,
        (myBase64) => {
          fetch(myBase64).then((res) => {
            res.arrayBuffer().then((buf) => {
              const file = new File([buf], images[0].name, {
                type: "image/jpeg",
              });
              setAvatar(file);
            });
          });
        }
      );
      for (let index = 1; index < images.length; index++) {
        const element = images[index];
        toDataUrl(
          "https://backbam.cody.tn/images/" + element.name,
          (myBase64) => {
            fetch(myBase64).then((res) => {
              res.arrayBuffer().then((buf) => {
                const file = new File([buf], element.name, {
                  type: "image/jpeg",
                });
                var l__files = [...files];
                l__files.push(file);
                setFiles(l__files);
              });
            });
          }
        );
      }
      setLoading(false);
    });
  }, []);

  const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
  };

  const handleSubmit = (files, allFiles) => {
    setLoading(true);
    var res = true;
    allFiles.forEach((element) => {
      if (element.file.size > 2097152) {
        res = false;
        return;
      }
    });
    if (!res) {
      errorNotification("Error!", "La taille de l'image est supériieur à 2Mb");
      return;
    }
    toUploadPhotos(allFiles, false);
  };
  const handleSubmitAvatar = (files, allFiles) => {
    toUploadPhotos(allFiles, true);
  };

  return (
    <>
      <div>
        <span className="bam--section-title">Photo de profil</span>
      </div>
      <Dropzone
        id="myDropzone"
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmitAvatar}
        maxFiles={1}
        maxSize={2097152}
        initialFiles={[avatar]}
        accept="image/*"
        inputContent="Ajouter votre photo de profil"
        submitButtonDisabled={(files) => files.length < 1}
        dictDefaultMessage="envoyer"
        submitButtonContent="Valider"
      />

      <div style={{ marginTop: "2vh" }}>
        <span className="bam--section-title">Albums</span>
        <p style={{ color: "red" }}>
          (*) La taille maximale admissible de l'image est de 2 Mo.
        </p>
        <Skeleton active loading={loading} />
        <Dropzone
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          maxFiles={10}
          maxSize={2048}
          accept="image/*"
          inputContent="Ajouter des photos"
          submitButtonContent="Valider"
          submitButtonDisabled={(files) => files.length < 1}
          initialFiles={files}
        />
        {/* <Row glutter={[8, 8, 8, 8]}>
          {pictures.length > 0 &&
            pictures.map((picture) => {
              return (
                <Col xl={6}>
                  <img style={{ width: "80%" }} src={picture} />
                </Col>
              );
            })}
        </Row> */}
      </div>
    </>
  );
};

export default Photo;
