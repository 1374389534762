import React from "react";
import { Row, Col, BackTop } from "antd";
import up from "../Styles/images/up.svg";

const style = {
  height: 44,
  width: 44,
  lineHeight: "40px",
  borderRadius: 6,
  backgroundColor: "#52C99F",
  textAlign: "center",
};
const CGUProf = () => {
  return (
    <>
      <Row className="bam--margin-cgu">
        <Col xs={24} md={24} xl={24} style={{ textAlign: "center" }}>
          <p className="bam--cgu-paragraph">
            <p class="x18">
              <span class="x13 x26">CONDITIONS GENERALES DE PARTENARIAT</span>
            </p>
            <p class="x25">
              <span class="x24 x13"></span>
            </p>
            <p class="x27">
              <span class="x22">
                Version Mise &agrave; Jour&nbsp;: Septembre 2021
              </span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x4">R&Eacute;SUM&Eacute;</span>

          <p className="bam--cgu-paragraph">
            <p class="x12">
              <span class="x4">
                Ceci est un r&eacute;sum&eacute; de nos Conditions
                G&eacute;n&eacute;rales de Partenariat. Il ne doit pas remplacer
                la lecture de la version compl&egrave;te ci-dessous. Les termes
                comportant une majuscule dans ce r&eacute;sum&eacute; sont
                d&eacute;finis dans les Conditions G&eacute;n&eacute;rales
                &nbsp;Professionnels.
              </span>
            </p>
            <ul class="c0 lst2-kix_list_6-0 start">
              <li class="x21 li2-bullet-0">
                <span class="x2">
                  BAM est une plateforme de mise en relations entre les
                  professionnels de la beaut&eacute; et les particuliers pour
                  des prestations &agrave; domicile, qui propose :
                </span>
              </li>
            </ul>
            <ul class="c0 lst2-kix_list_7-0 start">
              <li class="x11 li2-bullet-0">
                <span class="x2">Un&nbsp;</span>
                <span class="x4">syst&egrave;me de r&eacute;servation</span>
                <span class="x2">
                  &nbsp;par lequel vous pouvez afficher les Services que vous
                  proposez &agrave; un large public de consommateurs potentiels
                  ;
                </span>
              </li>
              <li class="x11 li2-bullet-0">
                <span class="x2">Un&nbsp;</span>
                <span class="x4">outil de gestion des rendez-vous</span>
                <span class="x2">
                  &nbsp;qui vous aidera &agrave; g&eacute;rer votre
                  entreprise&nbsp;;
                </span>
              </li>
              <li class="x11 li2-bullet-0">
                <span class="x2">Des&nbsp;</span>
                <span class="x4">
                  fonctionnalit&eacute;s et services compl&eacute;mentaires
                </span>
                <span class="x2">
                  &nbsp;tels qu&#39;un site personnalis&eacute; avec vos propres
                  prestations, photos et tarifs, un tableau de bord de votre
                  entreprise, la gestion de votre agenda, un fichier client,
                  l&rsquo;envoi de mails de confirmation pour vous aider
                  &agrave; g&eacute;rer vos paiements.
                </span>
              </li>
            </ul>
            <p class="x5">
              <span class="x1"></span>
            </p>
            <p class="x3">
              <span class="x1"></span>
            </p>
            <ul class="c0 lst2-kix_list_1-0 start">
              <li class="x10 li2-bullet-0">
                <span class="x2">
                  Vous &ecirc;tes responsable des Services que vous fournissez
                  aux Clients et le contrat li&eacute; aux Services est conclu
                  entre vous et le Client.&nbsp;
                </span>
                <span class="x4">
                  Nous ne sommes en aucun cas responsables vis-&agrave;-vis des
                  Clients pour les Services que vous leur fournissez.
                </span>
              </li>
              <li class="x21 li2-bullet-0">
                <span class="x2">
                  Vous avez un certain nombre d&#39;obligations envers BAM en
                  &eacute;change des Services BAM. Celles-ci sont
                  d&eacute;taill&eacute;es dans les Conditions
                  G&eacute;n&eacute;rales de Partenariat mais celles qui suivent
                  sont importantes :
                </span>
              </li>
            </ul>
            <ul class="c0 lst2-kix_list_2-0 start">
              <li class="x11 li2-bullet-0">
                <span class="x2">
                  Vous acceptez de payer toutes les Charges applicables (par
                  exemple : Frais d&#39;Inscription, Frais d&rsquo;abonnement
                  mensuel, etc.) et vous autorisez par les pr&eacute;sentes BAM
                  &agrave; effectuer le pr&eacute;l&egrave;vement de ces
                  Charges.&nbsp;
                </span>
                <span class="x4">
                  Veuillez noter que la TVA sera appliqu&eacute;e &agrave;
                  toutes les Charges.
                </span>
              </li>
              <li class="x6 li2-bullet-0">
                <span class="x2">
                  Vous acceptez de proc&eacute;der ou fournir les Services aux
                  plus hauts standards du m&eacute;tier.
                </span>
              </li>
              <li class="x6 li2-bullet-0">
                <span class="x2">
                  Vous devez vous assurer que vous disposez de toutes les
                  licences, autorisations, permissions, qualifications,
                  pr&eacute;rogatives et assurances requises pour
                  l&#39;ex&eacute;cution des Services.
                </span>
              </li>
              <li class="x6 li2-bullet-0">
                <span class="x2">
                  Vous devez vous assurer que vos d&eacute;nomination et adresse
                  commerciales puissent &ecirc;tre clairement vues par les
                  Clients concernant tous les Services que vous utilisez et qui
                  sont visibles pour les Clients.
                </span>
              </li>
              <li class="x6 li2-bullet-0">
                <span class="x2">
                  Vous devez vous assurer que le Contenu renseign&eacute; sur
                  votre Profil est exact, conforme &agrave; la loi et non
                  trompeur.
                </span>
              </li>
            </ul>
            <ul class="c0 lst2-kix_list_3-0 start">
              <li class="x10 li2-bullet-0">
                <span class="x2">
                  Les Clients sont autoris&eacute;s &agrave; demander le report,
                  la modification ou l&rsquo;annulation d&#39;une
                  R&eacute;servation jusqu&rsquo;&agrave; 24 heures avant
                  l&rsquo;heure du rendez-vous ainsi que l&rsquo;annulation
                  jusqu&#39;&agrave; 24 heures avant l&#39;heure du rendez-vous.
                </span>
              </li>
              <li class="x21 li2-bullet-0">
                <span class="x2">
                  Si nous avons besoin de vous contacter concernant les
                  R&eacute;servations ou pour toute autre raison relative aux
                  Services BAM, nous pouvons vous contacter par email,
                  t&eacute;l&eacute;phone ou SMS, en utilisant les
                  coordonn&eacute;es que vous avez fournies dans votre espace
                  professionnel.
                </span>
              </li>
              <li class="x21 li2-bullet-0">
                <span class="x2">
                  L&rsquo;ordre d&rsquo;apparition de la liste des
                  Professionnels lors des recherches des Clients sur le Site
                  Internet ou l&rsquo;Application d&eacute;pend d&rsquo;un
                  certain nombre de facteurs. (notamment votre
                  disponibilit&eacute; sur le cr&eacute;neau horaire et la zone
                  g&eacute;ographique s&eacute;lectionn&eacute;s par le Client)
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x2">
                  Si vous souhaitez faire une r&eacute;clamation concernant BAM,
                  ou pour toute question, veuillez contacter notre &eacute;quipe
                  :{" "}
                </span>
              </li>
            </ul>
            <p class="x12">
              <span class="x4">Adresse:</span>
              <span class="x1">
                &nbsp;10, rue du Colis&eacute;e &ndash; 75008 Paris, France
              </span>
            </p>
            <p class="x12">
              <span class="x4">Mail</span>
              <span class="x2">&nbsp;: </span>
              <span class="x24">
                <a class="x20" href="mailto:contact@bambeaute.com">
                  contact@bambeaute.com
                </a>
              </span>
            </p>
            <p class="x12">
              <span class="x4">T&eacute;l&eacute;phone</span>
              <span class="x2">
                &nbsp;: 06 19 16 91 61
                <br />
                <br />
              </span>
            </p>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="x4">VERSION COMPL&Egrave;TE CI-DESSOUS)</span>
          </p>
          <p className="bam--cgu-paragraph">
            <span class="x4 x13">
              Lisez attentivement les pr&eacute;sentes Conditions
              G&eacute;n&eacute;rales de Partenariat (ainsi que les Conditions
              G&eacute;n&eacute;rales de R&eacute;servation) avant
              d&rsquo;utiliser les Services BAM, car elles s&rsquo;appliqueront
              dans votre relation avec BAM. Nous vous recommandons
              d&#39;imprimer un exemplaire des pr&eacute;sentes Conditions
              G&eacute;n&eacute;rales de Partenariat pour les consulter
              ult&eacute;rieurement. Si vous n&#39;acceptez pas les
              pr&eacute;sentes Conditions G&eacute;n&eacute;rales de
              Partenariat, vous ne devez pas utiliser les Services BAM.
            </span>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="c0 lst2-kix_list_4-0 start" start="1">
            <span class="x10 li2-bullet-0">
              <span class="x4">1. DEFINITIONS</span>
            </span>
          </span>
          <p className="bam--cgu-paragraph">
            <ul class="c0 lst2-kix_list_4-1 start">
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Application &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe le logiciel d&#39;application mobile BAM
                  sur Android ou iOS ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Client &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe toute personne qui re&ccedil;oit les
                  Services r&eacute;serv&eacute;s sur bambeaute.com ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Contrat &raquo;</span>
                <span class="x2">&nbsp;ou&nbsp;</span>
                <span class="x4">
                  &laquo; Conditions G&eacute;n&eacute;rales de Partenariat
                  &raquo;
                </span>
                <span class="x2">
                  &nbsp;d&eacute;signe le pr&eacute;sent contrat qui
                  &eacute;tablit toutes les conditions qui vous sont
                  envoy&eacute;es par email ou qui vous sont
                  pr&eacute;sent&eacute;es sur la page d&#39;inscription en
                  ligne des Professionnels, les termes et conditions selon
                  lesquels BAM fournit les Services BAM aux Professionnels et
                  qui prend effet &agrave; la Date de Prise d&rsquo;Effet ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Essai gratuit &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe une p&eacute;riode limit&eacute;e pendant
                  laquelle un &nbsp;Professionnel peut recevoir une partie ou la
                  totalit&eacute; des Services BAM sans frais, tel que
                  d&eacute;termin&eacute; par BAM et &agrave; son enti&egrave;re
                  discr&eacute;tion. A l&#39;expiration d&#39;un Essai gratuit,
                  le Professionnel sera redevable des Charges pr&eacute;vues
                  dans le Contrat ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Services BAM &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe les avantages et services qu&#39;un
                  Professionnel peut recevoir de BAM, tels
                  qu&rsquo;indiqu&eacute;s sur la page d&rsquo;inscription en
                  ligne des &nbsp;Professionnels, dans le Contrat de
                  Coop&eacute;ration, tout avenant au pr&eacute;sent Contrat
                  et/ou dans les e-mails entre BAM et le &nbsp;Professionnel, en
                  &eacute;change du paiement des Charges &agrave; BAM
                  (except&eacute; dans le cas d&rsquo;un Essai gratuit) ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Espace Professionnel &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe l&rsquo;espace dans lequel le
                  professionnel renseigne ses informations et g&egrave;re son
                  activit&eacute; via bambeaute.com ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; Site Internet &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe le site internet www.bambeaute.com ;
                </span>
              </li>
              <li class="x10 li2-bullet-0">
                <span class="x4">&laquo; BAM &raquo;</span>
                <span class="x2">
                  &nbsp;d&eacute;signe BAM &ndash; Beaut&eacute; A La Maison,
                  une soci&eacute;t&eacute; immatricul&eacute;e en France sous
                  le num&eacute;ro 752 044 503 R.C.S. Paris, dont le
                  si&egrave;ge social est situ&eacute; 10, rue du
                  Colis&eacute;e, 75008 Paris, France et dont le num&eacute;ro
                  de TVA est le FR 43894530583 ;
                </span>
              </li>
            </ul>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="x12">
            <span class="x2">2- </span>
            <span class="x4 x13">SERVICES BAM</span>
          </p>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  En contrepartie du paiement d&rsquo;un Abonnement par le
                  Professionnel (except&eacute; dans le cas d&#39;un Essai
                  gratuit) et de l&rsquo;ex&eacute;cution par ce dernier de
                  toutes les autres obligations stipul&eacute;es au Contrat et
                  sous r&eacute;serve des termes et conditions du Contrat, BAM
                  fournit les Services BAM applicables au Professionnel
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM permet la prise de rendez-vous au client directement sur
                  le profil du Professionnel. La Responsabilit&eacute; de BAM ne
                  pourra pas &ecirc;tre engag&eacute;e au titre des Services
                  fournis aux Clients. BAM est uniquement un fournisseur de
                  technologie en ce qui concerne les R&eacute;servations et
                  n&#39;a pas de relation directe avec le Client.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM peut contacter les Professionnels concernant les
                  R&eacute;servations ou toute autre question relative aux
                  Services BAM. Nous pouvons vous contacter par email,
                  t&eacute;l&eacute;phone, SMS ou WhatsApp, en utilisant les
                  coordonn&eacute;es que vous avez fournies dans votre Espace
                  Professionnel.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x2">
                  Si un Professionnel a choisi de recevoir des Services BAM qui
                  sont soumis au paiement d&rsquo;un Abonnement, et s&rsquo;il
                  souhaite cesser de recevoir les Services BAM, le Professionnel
                  doit adresser &agrave; BAM un mail &agrave; l&rsquo;adresse
                  suivante{" "}
                </span>
                <span class="x7">
                  <a class="x20" href="mailto:contact@bambeaute.com">
                    contact@bambeaute.com
                  </a>
                </span>
                <span class="x1">
                  &nbsp;en respectant un pr&eacute;avis d&rsquo;au moins 30
                  jours et restera redevable des Frais applicables pendant
                  ladite dur&eacute;e de pr&eacute;avis.
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x2">
                  BAM peut, &agrave; son enti&egrave;re discr&eacute;tion,
                  changer le montant de l&rsquo;Abonnement apr&egrave;s
                  notification du &nbsp;Professionnel 30 jours avant ledit
                  changement (l&rsquo;&nbsp;
                </span>
                <span class="x4">
                  &laquo; Avis de Modification de Prix &raquo;
                </span>
                <span class="x1">
                  &nbsp;). L&#39;utilisation continue des Services BAM par le
                  Professionnel apr&egrave;s la r&eacute;ception de cette
                  Notification de Changement de Prix sera
                  consid&eacute;r&eacute;e comme une acceptation des nouveaux
                  Frais et/ou taux de Commission.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Lorsque BAM offre au Professionnel un Essai gratuit, le
                  Professionnel comprend et accepte que les Conditions
                  G&eacute;n&eacute;rales de Partenariat s&#39;appliquent
                  int&eacute;gralement. Le Professionnel s&#39;engage par les
                  pr&eacute;sentes &agrave; payer toutes les Charges applicables
                  apr&egrave;s la fin de l&#39;Essai gratuit et &agrave; fournir
                  ses coordonn&eacute;es bancaires &agrave; BAM avant la fin de
                  l&#39;Essai gratuit. Si le Professionnel omet de fournir ses
                  coordonn&eacute;es bancaires &agrave; BAM conform&eacute;ment
                  &agrave; la pr&eacute;sente clause, le &nbsp;Professionnel
                  comprend et accepte que BAM cessera la fourniture des Services
                  BAM avec effet imm&eacute;diat et sans autre avis. BAM peut,
                  &agrave; sa seule discr&eacute;tion, r&eacute;tablir une
                  partie ou la totalit&eacute; des Services BAM si le
                  Professionnel fournit les d&eacute;tails du paiement &agrave;
                  BAM. BAM ne sera en aucun cas responsable envers le
                  Professionnel de la r&eacute;siliation imm&eacute;diate des
                  Services BAM.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4">
              3. SERVICES ET ACC&Egrave;S AUX DONN&Eacute;ES
            </span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM est autoris&eacute;e &agrave; avoir acc&egrave;s &agrave;
                  toutes les donn&eacute;es fournies directement par les
                  Professionnels et/ou Clients ou g&eacute;n&eacute;r&eacute;es
                  par l&rsquo;utilisation par un quelconque Professionnel et/ou
                  Client des Services &nbsp;BAM, y compris, mais sans s&rsquo;y
                  limiter :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-3 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">Le Contenu du Professionnel;</span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Toutes les donn&eacute;es relatives aux comptes des
                  Professionnels et &agrave; l&rsquo;utilisation des Services
                  BAM ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Les informations de compte des Clients &ndash; le Contenu
                  G&eacute;n&eacute;r&eacute; par l&rsquo;Utilisateur et les
                  d&eacute;tails de l&rsquo;utilisation des Services par les
                  Clients ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Toutes les m&eacute;tadonn&eacute;es associ&eacute;es, par ex.
                  date/heure de connexion du Professionnel et Client ; et
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x1">
                  toutes les donn&eacute;es personnelles des Clients et
                  Professionnels
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM conserve l&rsquo;Acc&egrave;s de BAM apr&egrave;s la
                  r&eacute;siliation du pr&eacute;sent Contrat durant la
                  p&eacute;riode de conservation requise par la loi ou la
                  r&eacute;glementation et conform&eacute;ment aux politiques de
                  conservation de BAM.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Un Professionnel peut acc&eacute;der aux donn&eacute;es en
                  rapport avec son compte et aux donn&eacute;es du Client, en se
                  connectant &agrave; son Espace Professionnel, et ceci peut
                  inclure des rapports contenant des informations
                  compil&eacute;es concernant le Professionnel et les Clients du
                  Professionnel. &Agrave; la r&eacute;siliation de leur compte
                  BAM, les Professionnels peuvent exporter leur Base de
                  donn&eacute;es Clients avant la prise d&rsquo;effet de la
                  r&eacute;siliation. Afin d&rsquo;&eacute;viter toute
                  ambigu&iuml;t&eacute;, les Professionnels ne sont pas
                  autoris&eacute;s &agrave; exporter, ni n&rsquo;ont un
                  quelconque droit sur, un quelconque Contenu
                  G&eacute;n&eacute;r&eacute; par l&rsquo;Utilisateur.
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x1">
                  Certains tiers peuvent acc&eacute;der aux donn&eacute;es
                  &agrave; l&rsquo;occasion. Les donn&eacute;es du Client et les
                  donn&eacute;es du Professionnel fournies en vue de
                  l&rsquo;utilisation des Services BAM ou
                  g&eacute;n&eacute;r&eacute;es lors de leur fourniture seront
                  uniquement partag&eacute;es avec des tiers dans la mesure
                  n&eacute;cessaire &agrave; la bonne fourniture des Services
                  BAM.{" "}
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="7">
              <li class="c8 li2-bullet-0">
                <span class="x1">Le Professionnel ne doit pas :</span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  reproduire, dupliquer, copier, vendre, revendre et exploiter
                  tout ou partie de l&rsquo;Espace Professionnel ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  permettre &agrave; un tiers (y compris des
                  soci&eacute;t&eacute;s du groupe du &nbsp;Professionnel)
                  d&rsquo;utiliser son Espace Professionnel ou d&rsquo;y
                  acc&eacute;der sans l&rsquo;accord expr&egrave;s &eacute;crit
                  et pr&eacute;alable de BAM (que BAM pourra refuser ou accorder
                  selon des conditions qu&rsquo;elle d&eacute;terminera &agrave;
                  son enti&egrave;re discr&eacute;tion) ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  envoyer des courriels non sollicit&eacute;s, des SMS ou
                  d&#39;autres formes &eacute;lectroniques de marketing aux
                  Clients via son Espace Professionnel (ou autre) ; ou
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  divulguer, partager ou revendre les informations de connexion
                  de l&rsquo;Espace Professionnel BAM.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="8">
              <li class="c8 li2-bullet-0">
                <span class="x1">BAM ne garantit pas que :</span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  L&rsquo;Espace Professionnel r&eacute;pondra aux exigences
                  sp&eacute;cifiques du Professionnel ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  toutes les informations ou r&eacute;sultats obtenus
                  gr&acirc;ce &agrave; l&rsquo;utilisation de l&rsquo;Espace
                  Professionnel seront exacts ou fiables ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  la qualit&eacute; des produits, services, informations ou
                  autres mat&eacute;riels achet&eacute;s ou obtenus par le
                  Professionnel par l&rsquo;interm&eacute;diaire de
                  l&rsquo;Espace Professionnel r&eacute;pondront aux exigences
                  ou aux attentes du Professionnel; ou
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  les erreurs dans l&rsquo;Espace Professionnel seront
                  corrig&eacute;es.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="9">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le Professionnel comprend et convient express&eacute;ment que
                  concernant l&rsquo;utilisation de l&rsquo;Espace
                  Professionnel, que ce soit pendant un Essai gratuit ou non,
                  BAM ne sera pas responsable de tout manque &agrave; gagner, de
                  toute perte de client&egrave;le, d&rsquo;usage, de
                  donn&eacute;es ou autres pertes immat&eacute;rielles ni des
                  dommages indirects, (m&ecirc;me si BAM a &eacute;t&eacute;
                  inform&eacute;e par le Professionnel de la possibilit&eacute;
                  de ces dommages), r&eacute;sultant de :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;utilisation de l&rsquo;Espace Client ou de
                  l&rsquo;incapacit&eacute; &agrave; les utiliser ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  de l&rsquo;arr&ecirc;t de la fourniture par BAM des Services
                  BAM &agrave; l&#39;expiration de l&#39;Essai gratuit ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;acc&egrave;s non autoris&eacute; aux transmissions ou
                  donn&eacute;es du Professionnel ou la modification de
                  celles-ci ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  d&eacute;clarations ou agissements d&rsquo;un tiers sur
                  l&rsquo;Espace Professionnel&nbsp;; ou
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  toutes autres questions concernant l&rsquo;Espace
                  Professionnel.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="10">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM fournira au Professionnel un compte d&rsquo;utilisateur et
                  un mot de passe permettant au Professionnel
                  d&rsquo;acc&eacute;der &agrave; son Espace Professionnel. Le
                  Professionnel prot&eacute;gera et conservera les informations
                  de compte et le mot de passe confidentiels et stock&eacute;s
                  en toute s&eacute;curit&eacute; et ne les divulguera &agrave;
                  personne. Le &nbsp;Professionnel doit imm&eacute;diatement
                  informer BAM de toute violation pr&eacute;sum&eacute;e de la
                  s&eacute;curit&eacute; ou de toute utilisation
                  inappropri&eacute;e, y compris toute utilisation qui
                  contreviendrait au pr&eacute;sent Contrat, aux instructions
                  raisonnables de BAM donn&eacute;es au fil du temps et/ou aux
                  lois applicables.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4">4. TARIFS ET ABONNEMENT BAM</span>
          </span>
          <p className="bam--cgu-paragraph">
            <p class="x16">
              <span class="x23">
                L&rsquo;acc&egrave;s &agrave; l&rsquo;abonnement BAM est
                donn&eacute; aux Professionnels inscrits en contrepartie du
                paiement d&rsquo;un abonnement de 24,99&euro; HT / mois, ou
                199,99&euro; HT / an.
              </span>
            </p>
            <p class="x16">
              <span class="x1">
                Une TVA de 20% s&rsquo;ajoute &agrave; ce prix dans la
                facturation de BAM aupr&egrave;s des Professionnels.{" "}
              </span>
            </p>{" "}
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4">5. OBLIGATIONS DU &nbsp;PROFESSIONNEL</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="11">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  En contrepartie de la r&eacute;ception des Services BAM, le
                  Professionnel s&rsquo;engage &agrave; payer toutes les Charges
                  applicables et &agrave; accepter toutes les
                  R&eacute;servations BAM et &agrave; traiter et fournir les
                  Services selon les r&egrave;gles de l&rsquo;art du secteur et
                  conform&eacute;ment aux termes et conditions
                  sp&eacute;cifiques du pr&eacute;sent Contrat et
                  particuli&egrave;rement au pr&eacute;sent article. Un
                  manquement &agrave; cet article constituera une Violation
                  Substantielle de ce Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Report ou annulation des R&eacute;servations{" "}
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Si un Client souhaite changer la date et/ou l&rsquo;heure
                  d&rsquo;une r&eacute;servation dans le d&eacute;lai
                  autoris&eacute; de 24h avant l&rsquo;heure du rendez-vous
                  initial, alors le Professionnel doit proposer au Client une
                  heure et/ou date de R&eacute;servation alternative
                  adapt&eacute;e. Si le Professionnel n&rsquo;est pas en mesure
                  ou ne souhaite pas ex&eacute;cuter une r&eacute;servation
                  suite &agrave; une demande de changement d&rsquo;un Client, le
                  Client pourra choisir de proc&eacute;der &agrave;
                  l&rsquo;annulation du rendez-vous{" "}
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le Professionnel est tenu de s&rsquo;assurer que les
                  informations qu&rsquo;il renseigne dans son espace
                  professionnel ou qu&rsquo;il remet &agrave; BAM en vue de sa
                  publication sur le site bambeaute.com est vrai, exact et non
                  trompeur.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="13">
              <li class="c8 li2-bullet-0">
                <span class="x1">Le Professionnel s&rsquo;assure que :</span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Toute personne figurant sur les photos publi&eacute;es par le
                  Professionnel sur son profil BAM ont fourni le consentement
                  &eacute;crit ; et
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM se r&eacute;serve le droit de supprimer toute photo allant
                  &agrave; l&rsquo;encontre de ces engagements. Si le
                  &nbsp;Professionnel re&ccedil;oit l&#39;un des Services BAM
                  dans le cadre d&#39;un Essai gratuit, le Professionnel doit
                  fournir &agrave; BAM tous les d&eacute;tails du paiement avant
                  l&#39;expiration de l&#39;Essai gratuit. Ne pas le faire
                  constituera une Violation Substantielle du pr&eacute;sent
                  Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM n&rsquo;est pas responsable de la qualit&eacute; de la
                  prestation fournie par les Professionnels envers leurs
                  clients. BAM est uniquement une plateforme de mise en relation
                  entre les Professionnels et les Clients
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4">5. SERVICE CLIENT ET RECLAMATIONS</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="14">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel s&rsquo;efforcera au mieux de fournir
                  des Services de haute qualit&eacute; &agrave; tous les Clients
                  et de traiter sans d&eacute;lai toutes les demandes
                  commerciales, questions ou probl&egrave;mes concernant les
                  R&eacute;servations, y compris les r&eacute;clamations des
                  Clients. La r&eacute;ception de r&eacute;clamations Clients de
                  nature s&eacute;rieuse sera consid&eacute;r&eacute;e comme une
                  Violation Substantielle du Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le Professionnel sera directement responsable envers le Client
                  de tout manquement &agrave; remplir les attentes du Client ou
                  de tout autre responsabilit&eacute; l&eacute;gale en lien avec
                  les Services du &nbsp;Professionnel
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4">6. R&Eacute;CLAMATIONS DES PROFESSIONNELS</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="16">
              <li class="x15 li2-bullet-0">
                <span class="x2">
                  Si le &nbsp;Professionnel a une r&eacute;clamation concernant
                  tout service BAM, celui-ci doit contacter BAM par
                  courriel&nbsp;(
                </span>
                <span class="x7">
                  <a class="x20" href="mailto:contact@bambeuate.com">
                    contact@bambeuate.com
                  </a>
                </span>
                <span class="x1">
                  ) en fournissant autant d&rsquo;informations que possible
                  concernant la R&eacute;clamation.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM r&eacute;pondra au &nbsp;Professionnel en accusant
                  r&eacute;ception et enqu&ecirc;tera ensuite sur la question.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  D&egrave;s r&eacute;ception de la R&eacute;clamation, nous
                  enqu&ecirc;terons en interne sur la R&eacute;clamation, en
                  tenant compte de l&rsquo;importance et de la complexit&eacute;
                  de la question soulev&eacute;e.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM r&eacute;pondra au &nbsp;Professionnel avec ses
                  conclusions en r&eacute;ponse &agrave; la R&eacute;clamation,
                  et, le cas &eacute;ch&eacute;ant, avec une suggestion de
                  solution.
                  <br />
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4">7. DONN&Eacute;ES DES CLIENTS</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM et le Professionnel reconnaissent chacun que, pour les
                  besoins de la L&eacute;gislation sur la Protection des
                  Donn&eacute;es :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  en ce qui concerne les donn&eacute;es personnelles des
                  Clients, le Professionnel est le responsable de traitement et
                  BAM est plateforme de mise en relation ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  en ce qui concerne les donn&eacute;es personnelles des
                  Professionnels et de BAM, chaque partie est responsable de sa
                  propre conformit&eacute; &agrave; la L&eacute;gislation sur la
                  Protection des Donn&eacute;es, y compris le RGPD ;
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x1">
                  le Professionnel doit promptement (et en tout cas dans les 24
                  heures suivant le moment o&ugrave; le &nbsp;Professionnel en a
                  connaissance) informer BAM de tout dommage accidentel ou
                  intentionnel, alt&eacute;ration, destruction, divulgation non
                  autoris&eacute;e, perte, mauvaise utilisation ou vol des
                  donn&eacute;es personnelles de tout Client auquel le
                  &nbsp;Professionnel a acc&egrave;s. Le Professionnel apportera
                  une coop&eacute;ration compl&egrave;te et une assistance
                  rapide &agrave; BAM en ce qui concerne ses efforts pour (i)
                  enqu&ecirc;ter, corriger et att&eacute;nuer les effets de
                  l&#39;Incident de S&eacute;curit&eacute;, et (ii) se conformer
                  aux obligations de notification aux individus, clients ou
                  autorit&eacute;s r&eacute;glementaires ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le Professionnel ne doit pas faire, ou omettre de faire, et
                  doit s&#39;assurer que son personnel et ses autres
                  repr&eacute;sentants ne font ou omettent de faire quoi que ce
                  soit qui causerait (ou pourrait raisonnablement causer)
                  &agrave; BAM ou &agrave; ses Affili&eacute;s
                  d&rsquo;&ecirc;tre en violation de toute disposition de la
                  L&eacute;gislation sur la Protection des Donn&eacute;es et
                  prendre toutes les mesures raisonnables pour assurer la
                  fiabilit&eacute; de ses employ&eacute;s, prestataires et
                  agents qui peuvent avoir acc&egrave;s aux donn&eacute;es
                  personnelles et s&#39;assurer que ces personnels et agents
                  sont inform&eacute;s de la nature confidentielle des
                  donn&eacute;es personnelles et ont suivi une formation sur les
                  lois relatives aux donn&eacute;es personnelles ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel accepte de mettre en &oelig;uvre et de
                  maintenir des mesures techniques et organisationnelles
                  appropri&eacute;es pour le traitement des donn&eacute;es
                  personnelles pour se conformer &agrave; la L&eacute;gislation
                  sur la Protection des Donn&eacute;es et pour prot&eacute;ger
                  les donn&eacute;es personnelles contre tout traitement non
                  autoris&eacute; ou ill&eacute;gal, vol, alt&eacute;ration ou
                  divulgation ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le Professionnel accepte de traiter uniquement les
                  donn&eacute;es personnelles des Clients dans le but de fournir
                  les Services aux Clients et, dans le cas des Clients qui ont
                  express&eacute;ment accept&eacute; par un syst&egrave;me de
                  consentement &agrave; recevoir du marketing par e-mail,
                  &agrave; des fins de marketing par e-mail et dans tous les cas
                  uniquement lors de la r&eacute;ception des Services ; et
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Si le Professionnel, ses affili&eacute;s ou ses fournisseurs
                  doivent transf&eacute;rer les donn&eacute;es personnelles des
                  Clients BAM vers des emplacements situ&eacute;s en dehors de
                  l&#39;Espace &eacute;conomique Europ&eacute;en, le
                  &nbsp;Professionnel assume (et accepte) l&#39;enti&egrave;re
                  responsabilit&eacute; que ces donn&eacute;es personnelles
                  soient trait&eacute;es en conformit&eacute; avec la
                  L&eacute;gislation sur la Protection des Donn&eacute;es.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="2">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Lorsque le &nbsp;Professionnel est responsable de traitement
                  et que BAM est sous-traitant, &agrave; l&#39;&eacute;gard des
                  donn&eacute;es personnelles des Clients &nbsp;Professionnels
                  et des donn&eacute;es personnelles des Clients Widget (sauf
                  pour le marketing par e-mail des Clients Widget dont le
                  &nbsp;Professionnel et BAM sont des responsables de traitement
                  ind&eacute;pendants), ces donn&eacute;es seront
                  trait&eacute;es par BAM conform&eacute;ment aux obligations de
                  l&#39;article 28 du RGPD, sous r&eacute;serve des stipulations
                  de l&rsquo;article 10.9 ci-dessous et sous r&eacute;serve que
                  BAM puisse facturer au &nbsp;Professionnel une assistance non
                  express&eacute;ment sp&eacute;cifi&eacute;e comme une exigence
                  de service de BAM dans le cadre du Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel reconna&icirc;t que la
                  propri&eacute;t&eacute; de tous les Droits de
                  Propri&eacute;t&eacute; Intellectuelle dans le Contenu
                  G&eacute;n&eacute;r&eacute; par l&#39;Utilisateur sera
                  d&eacute;volue &agrave; BAM ou &agrave; ses Affili&eacute;es
                  et le &nbsp;Professionnel c&egrave;de de mani&egrave;re
                  irr&eacute;vocable et inconditionnelle tous les droits
                  &agrave; BAM.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel s&#39;engage et garantit qu&#39;il ne
                  traitera les donn&eacute;es personnelles des Clients
                  &nbsp;Professionnels qu&#39;en conformit&eacute; avec la
                  L&eacute;gislation sur la Protection des Donn&eacute;es et en
                  particulier que lui, ses employ&eacute;s, ses prestataires,
                  ses &nbsp;Professionnels et ses fournisseurs enverront
                  uniquement des communications marketing ou promotionnelles aux
                  Clients &nbsp;Professionnels ayant donn&eacute; leur
                  consentement &agrave; en recevoir. L&#39;utilisation par le
                  &nbsp;Professionnel des donn&eacute;es personnelles des
                  Clients &nbsp;Professionnels est effectu&eacute;e aux risques
                  et p&eacute;rils du &nbsp;Professionnel, le
                  &nbsp;Professionnel &eacute;tant responsable de s&#39;assurer
                  que l&#39;utilisation de ses employ&eacute;s, de ses
                  prestataires, de ses &nbsp;Professionnels et de ses
                  fournisseurs est enti&egrave;rement conforme &agrave; la
                  L&eacute;gislation sur la Protection des Donn&eacute;es en
                  vigueur.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Dans le cas o&ugrave; un Client notifie au Professionnel ou
                  &agrave; l&#39;un de ses employ&eacute;s ou prestataires
                  qu&#39;il ne souhaite pas recevoir d&#39;autres documents
                  marketing de BAM et/ou de ses Affili&eacute;s, le
                  &nbsp;Professionnel doit rapidement (et en tout cas au plus
                  tard 48 (quarante-huit) heures apr&egrave;s en avoir
                  &eacute;t&eacute; ainsi inform&eacute;) informer BAM avec tous
                  les d&eacute;tails par &eacute;crit afin que BAM puisse
                  honorer une telle demande.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Dans le cas o&ugrave; une personne concern&eacute;e demande
                  &agrave; l&#39;une ou l&#39;autre partie d&#39;exercer un ou
                  plusieurs droits accord&eacute;s aux personnes
                  concern&eacute;es par la L&eacute;gislation sur la Protection
                  des Donn&eacute;es, dans la mesure o&ugrave; l&#39;une ou
                  l&#39;autre partie a raisonnablement besoin de l&#39;aide ou
                  de l&#39;assistance de l&rsquo;autre partie pour donner effet
                  &agrave; l&#39;un quelconque des droits accord&eacute;s, cette
                  autre partie doit fournir toutes ses contributions ou
                  assistance dans un d&eacute;lai raisonnable, chaque partie
                  assumant ses propres frais pour ce faire.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Dans le cas o&ugrave; l&#39;une des parties re&ccedil;oit une
                  demande d&#39;une autorit&eacute; de protection des
                  donn&eacute;es pour des informations relatives au Contrat ou
                  la relation entre les parties, cette partie doit rapidement en
                  aviser l&#39;autre, sauf interdiction l&eacute;gale.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Toute violation du pr&eacute;sent article par le
                  Professionnel, ses employ&eacute;s, prestataires ou agents
                  constituera une Violation Substantielle du Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel indemnisera BAM et ses Affili&eacute;s
                  contre toutes les pertes, r&eacute;clamations, co&ucirc;ts,
                  dommages ou proc&eacute;dures subis ou encourus par BAM et/ou
                  ses Affili&eacute;s du fait de ou en relation avec la
                  violation par le &nbsp;Professionnel du pr&eacute;sent
                  article.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent article restera en vigueur dans son
                  int&eacute;gralit&eacute; apr&egrave;s la r&eacute;siliation
                  ou l&rsquo;expiration du pr&eacute;sent Contrat.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4 x13">
              8. GARANTIES ET INDEMNISATION DU PROFESSIONNEL
            </span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel remettra &agrave; BAM le Contenu du
                  Professionnel qu&rsquo;elle demande raisonnablement afin
                  d&rsquo;assurer la prestation des Services BAM.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel accorde &agrave; BAM un droit et une
                  licence non exclusive, gratuite, irr&eacute;vocable et
                  internationale (ou sous-licence selon le cas) d&#39;utiliser,
                  reproduire, distribuer, sous-licencier, communiquer et mettre
                  &agrave; disposition le Contenu du Professionnel sur le Site
                  Internet, l&rsquo;Application, le Lookbook BAM, les pages de
                  r&eacute;seaux sociaux de BAM et tout autre R&eacute;seau de
                  Distribution, et pour tout autre usage qui sont
                  n&eacute;cessaires ou requis par BAM pour que BAM exerce ses
                  droits et s&#39;acquitte de ses obligations en vertu du
                  pr&eacute;sent Contrat, et pour tout autre motif, y compris,
                  mais sans s&rsquo;y limiter, &agrave; des fins de
                  commercialisation, publicitaires, commerciales et
                  &eacute;ditoriales.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM peut accorder une sous-licence, mettre &agrave;
                  disposition, divulguer et/ou offrir le Contenu du
                  &nbsp;Professionnel &agrave; des Affili&eacute;s et &agrave;
                  des tiers (&laquo; Plateformes Tierces &raquo;), incluant mais
                  sans se limiter &agrave; Google via ses services tels que
                  R&eacute;server avec Google et Google My Business. En aucun
                  cas BAM ne sera responsable vis-&agrave;-vis du Professionnel
                  pour tout acte ou omission de la part de toute Plateforme
                  Tierce. Le seul recours disponible pour le Professionnel
                  &agrave; l&#39;&eacute;gard de ces Plateformes Tierces est de
                  (a) demander &agrave; BAM (qui a le droit et non
                  l&#39;obligation) de d&eacute;sactiver et de se
                  d&eacute;connecter avec cette Plateforme Tiers &agrave;
                  l&#39;&eacute;gard du Professionnel ; ou (b) r&eacute;silier
                  le pr&eacute;sent Contrat. Le Professionnel donne &agrave; BAM
                  le plein pouvoir pour organiser et g&eacute;rer le Contenu
                  Professionnel de son compte Professionnel Google My Business
                  comme &eacute;tant son repr&eacute;sentant
                  agr&eacute;&eacute;. Si le &nbsp;Professionnel souhaite
                  &agrave; tout moment reprendre le contr&ocirc;le exclusif de
                  son compte Google My Business, le &nbsp;Professionnel devra en
                  informer BAM et BAM fera le n&eacute;cessaire pour se
                  dissocier du compte et renoncer aux droits de g&eacute;rer et
                  modifier ledit compte.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel accorde par les pr&eacute;sentes
                  &agrave; BAM le droit :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  de retirer, modifier, couper ou autrement changer le Contenu
                  du Professionnel publi&eacute; sur le Site Internet,
                  l&rsquo;Application, les R&eacute;seaux sociaux de BAM, le
                  Site Professionnel et/ou le Lookbook BAM pour tout motif, y
                  compris, notamment, lorsque ce Contenu du Professionnel
                  n&rsquo;est, selon l&rsquo;opinion raisonnable de BAM, pas
                  conforme ou viole autrement les stipulations du pr&eacute;sent
                  Contrat ; et
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  d&rsquo;utiliser les services d&rsquo;optimisation de moteur
                  de recherche, et tous autres m&eacute;canismes qui incorporent
                  ou citent (en tout ou en partie) le nom commercial du
                  Professionnel ou les marques utilis&eacute;es en rapport avec
                  les Services.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="5">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le &nbsp;Professionnel d&eacute;clare, garantit et
                  s&#39;engage &agrave; ce que :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  tout le Contenu du &nbsp;Professionnel qu&#39;il fournit
                  &agrave; BAM dans le cadre du pr&eacute;sent Contrat et/ou
                  publie (ou fournit &agrave; BAM pour publication) sur le Site
                  Internet, l&rsquo;Application, les R&eacute;seaux sociaux de
                  BAM, le Site Professionnel et/ou le Lookbook BAM, le cas
                  &eacute;ch&eacute;ant, sera exact &agrave; tous les
                  &eacute;gards substantiels et ne violera pas les droits de
                  toute autre personne (y compris les Droits de
                  Propri&eacute;t&eacute; Intellectuelle) ou ne sera pas
                  diffamatoire, ill&eacute;gal, offensant, mena&ccedil;ant,
                  pornographique ou autrement en de&ccedil;&agrave; des normes
                  g&eacute;n&eacute;rales de go&ucirc;t et de d&eacute;cence ;
                  et
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  il doit se conformer &agrave; toutes les lois et
                  r&eacute;glementations applicables en mati&egrave;re de
                  marketing, vente et prestation des Services et doit obtenir
                  toutes les licences, consentements, autorit&eacute;s,
                  qualifications et assurances qu&#39;il est n&eacute;cessaire
                  ou raisonnablement prudent pour le &nbsp;Professionnel
                  d&#39;obtenir en respect de l&#39;ensemble de ses
                  activit&eacute;s et de son personnel (mais plus
                  particuli&egrave;rement dans le cadre de la fourniture de
                  Services). Ceci comprend le fait de s&#39;assurer que tous les
                  prix indiqu&eacute;s en tant que rabais constituent de
                  v&eacute;ritables prix au rabais et ont donc &eacute;t&eacute;
                  plus &eacute;lev&eacute;s auparavant et seulement au rabais
                  pour une courte p&eacute;riode, comme annonc&eacute;.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="6">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Toute violation des garanties du pr&eacute;sent article sera
                  consid&eacute;r&eacute;e comme une Violation Substantielle de
                  ce Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le Professionnel s&rsquo;engage par les pr&eacute;sentes
                  &agrave; indemniser et garantir &agrave; BAM et ses
                  dirigeants, administrateurs et employ&eacute;s de toutes les
                  r&eacute;clamations, mises en demeure, obligations, motifs
                  d&rsquo;action r&eacute;els ou all&eacute;gu&eacute;s et
                  proc&egrave;s et de tous dommages, responsabilit&eacute;s,
                  amendes, jugements, co&ucirc;ts (y compris les co&ucirc;ts
                  transactionnels) des frais qui y sont associ&eacute;s (dont le
                  paiement des honoraires et d&eacute;bours raisonnables
                  d&rsquo;avocat), des pertes (y compris notamment les pertes
                  directes ou indirectes, le manque &agrave; gagner, la perte de
                  r&eacute;putation et tous les int&eacute;r&ecirc;ts,
                  p&eacute;nalit&eacute;s et frais juridiques (calcul&eacute;s
                  sur une base d&#39;indemnisation compl&egrave;te)) et de tous
                  les autres frais et d&eacute;penses professionnels
                  raisonnables d&eacute;coulant de la violation d&rsquo;une
                  stipulation du pr&eacute;sent Contrat par le
                  &nbsp;Professionnel ou s&rsquo;y rapportant ou
                  d&eacute;coulant de toute action intent&eacute;e par un tiers
                  en rapport avec les Services fournis (ou non fournis) ou
                  d&rsquo;actes (ou d&rsquo;omission) du &nbsp;Professionnel ou
                  de toute personne (autre que BAM) agissant pour son compte, y
                  compris notamment toute action intent&eacute;e en rapport avec
                  la L&eacute;gislation sur la Protection des Donn&eacute;es, le
                  Contenu du Professionnel ou la visite d&rsquo;un Client chez
                  le Professionnel.
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x2">
                  Le Professionnel reconna&icirc;t que BAM conclut le Contrat
                  pour son propre b&eacute;n&eacute;fice mais &eacute;galement
                  en tant que mandataire de chacun de ses dirigeants,
                  administrateurs et employ&eacute;s (chacun &eacute;tant
                  un&nbsp;
                </span>
                <span class="x4">&laquo; Tiers Indemnis&eacute; &raquo;</span>
                <span class="x2">&nbsp;et, collectivement, les&nbsp;</span>
                <span class="x4">&laquo; Tiers Indemnis&eacute;s &raquo;</span>
                <span class="x1">
                  ) et que les droits en mati&egrave;re d&#39;indemnisation
                  &eacute;nonc&eacute;s seront des droits et avantages de chaque
                  Tiers Indemnis&eacute; (comme s&rsquo;il &eacute;tait une
                  Partie du pr&eacute;sent Contrat). BAM agira en tant que
                  mandataire pour chaque Tiers Indemnis&eacute; en vertu du
                  Contrat. Nonobstant ce qui pr&eacute;c&egrave;de, le
                  Professionnel et BAM peuvent convenir par &eacute;crit de
                  modifier toute disposition du pr&eacute;sent Contrat sans le
                  consentement de l&#39;un des Tiers Indemnis&eacute;s,
                  m&ecirc;me si cette modification affecte ou affectera les
                  droits conf&eacute;r&eacute;s &agrave; tout Tiers
                  Indemnis&eacute; en vertu des pr&eacute;sentes.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent article restera en vigueur apr&egrave;s la
                  r&eacute;siliation ou l&rsquo;expiration du pr&eacute;sent
                  Contrat.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4 x13">9. DUR&Eacute;E DU CONTRAT</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="10">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent Contrat entrera en vigueur &agrave; la Date
                  de Prise d&rsquo;Effet et se poursuivra sans limitation de
                  dur&eacute;e, sauf s&rsquo;il est r&eacute;sili&eacute;
                  conform&eacute;ment aux articles ci-dessous :<br />
                  <br />
                  R&eacute;siliation pour convenance par l&rsquo;une ou
                  l&rsquo;autre partie
                  <br />
                  <br />
                  Cet article s&rsquo;applique aux Services BAM
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent Contrat peut &ecirc;tre
                  r&eacute;sili&eacute; &agrave; tout moment par la remise
                  d&rsquo;une notification par mail de 30 jours minimum par
                  l&rsquo;une ou l&rsquo;autre partie &agrave; l&rsquo;autre en
                  vertu des conditions du pr&eacute;sent article ou tel
                  qu&rsquo;autrement autoris&eacute; par la loi.
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x2">
                  Si le &nbsp;Professionnel d&eacute;cide de r&eacute;silier le
                  pr&eacute;sent Contrat, celui-ci doit r&eacute;diger un mail
                  &agrave; l&rsquo;adresse suivante :{" "}
                </span>
                <span class="x7">
                  <a class="x20" href="mailto:contact@bambeaute.com">
                    contact@bambeaute.com
                  </a>
                </span>
                <span class="x1">
                  &nbsp;en indiquant qu&rsquo;il souhaite r&eacute;silier le
                  Contrat et cesser de recevoir les Services BAM{" "}
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Si BAM d&eacute;cide de r&eacute;silier le pr&eacute;sent
                  Contrat, nous vous contacterons au courriel ou num&eacute;ro
                  de t&eacute;l&eacute;phone que vous nous avez
                  communiqu&eacute; dans votre Espace Professionnel.
                  <br />
                  <br />
                  R&eacute;siliation pour un motif valable par BAM
                  <br />
                  <br />
                  Le pr&eacute;sent article s&rsquo;applique aux Services BAM
                </span>
              </li>
              <li class="x15 li2-bullet-0">
                <span class="x2">
                  BAM peut r&eacute;silier le pr&eacute;sent Contrat avec effet
                  imm&eacute;diat pour un motif imp&eacute;ratif, y compris (a)
                  si un Professionnel viole le pr&eacute;sent Contrat ou une loi
                  ou r&eacute;glementation (y compris la L&eacute;gislation sur
                  la Protection des Donn&eacute;es), ou fait quelque chose qui
                  viole les droits d&rsquo;un tiers y compris les questions
                  affectant la s&eacute;curit&eacute; d&rsquo;un service, une
                  fraude ou des violations des donn&eacute;es, (b) si un
                  &nbsp;Professionnel se comporte d&rsquo;une quelconque
                  mani&egrave;re ill&eacute;gale ou inappropri&eacute;e &agrave;
                  l&rsquo;&eacute;gard d&rsquo;un Client (y compris
                  lorsqu&rsquo;un Client l&rsquo;a signal&eacute; &agrave; la
                  police), les employ&eacute;s de BAM et/ou les propres
                  employ&eacute;s du &nbsp;Professionnel, ou (c) s&rsquo;il y a
                  eu plus d&rsquo;une r&eacute;clamation du Client concernant un
                  &nbsp;Professionnel&nbsp;
                </span>
                <span class="x4">(&laquo; Motif Imp&eacute;ratif &raquo;)</span>
                <span class="x1">
                  .<br />
                  <br />
                  R&eacute;siliation pour faute par l&rsquo;une ou l&rsquo;autre
                  partie
                  <br />
                  <br />
                  Le pr&eacute;sent article s&rsquo;applique aux Services BAM{" "}
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  L&rsquo;une ou l&rsquo;autre des parties pourra
                  r&eacute;silier un Service BAM avec effet imm&eacute;diat par
                  notification mail &agrave; l&rsquo;autre partie si :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;autre partie a commis une Violation Substantielle de
                  l&rsquo;une des stipulations du pr&eacute;sent Contrat ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;autre partie commet une violation persistante ou
                  r&eacute;p&eacute;t&eacute;e (qu&rsquo;il s&rsquo;agisse
                  d&rsquo;une Violation Substantielle ou non, et qu&rsquo;il
                  soit possible d&rsquo;y rem&eacute;dier ou non) de l&rsquo;une
                  des stipulations du pr&eacute;sent Contrat ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  un cr&eacute;ancier prend possession des biens ou actifs de
                  cette autre partie ou un liquidateur est d&eacute;sign&eacute;
                  pour ces biens et actifs ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;autre partie conclut une reconnaissance de dettes avec
                  ses cr&eacute;anciers ou fait l&rsquo;objet d&rsquo;une
                  proc&eacute;dure d&rsquo;administration des biens ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;autre partie entre en liquidation (sauf en vue
                  d&rsquo;une restructuration et de sorte que la
                  soci&eacute;t&eacute; en r&eacute;sultant s&rsquo;engage
                  effectivement &agrave; &ecirc;tre li&eacute;e par ou &agrave;
                  assumer les obligations impos&eacute;es &agrave; cette autre
                  partie au titre du pr&eacute;sent Contrat) ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  quoi que ce soit d&#39;analogue &agrave; ce qui
                  pr&eacute;c&egrave;de en vertu de la loi d&#39;une juridiction
                  se produit &agrave; l&#39;&eacute;gard de cette autre partie ;
                  ou
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  l&rsquo;autre partie cesse ou menace de cesser d&rsquo;exercer
                  son activit&eacute;.
                  <br />
                  <br />
                  Gel des Services BAM
                  <br />
                  <br />
                  Le pr&eacute;sent article s&rsquo;applique aux Services BAM
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="16">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM peut temporairement geler le compte d&rsquo;un quelconque
                  &nbsp;Professionnel, afin que celui-ci ne puisse plus recevoir
                  les R&eacute;servation par l&rsquo;interm&eacute;diaire de BAM
                  et ne soit plus visible sur le Site Internet,
                  l&rsquo;Application ou tout R&eacute;seau de Distribution y
                  compris dans les r&eacute;sultats des recherches, si le
                  &nbsp;Professionnel commet, ou est suspect&eacute; de
                  commettre, une Violation Substantielle d&rsquo;une quelconque
                  des dispositions du pr&eacute;sent Contrat{" "}
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Si, apr&egrave;s cette &eacute;tude, BAM consid&egrave;re
                  raisonnablement qu&rsquo;une Violation Substantielle a eu
                  lieu, ou qu&rsquo;il existe un Motif Imp&eacute;ratif de
                  r&eacute;siliation, BAM pourra r&eacute;silier le
                  pr&eacute;sent Contrat en vertu de l&rsquo;article ci-dessus.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  La r&eacute;siliation du pr&eacute;sent Contrat, quelle
                  qu&#39;en soit la forme, n&#39;affectera aucun des droits et
                  recours des parties qui sont acquis au moment de la
                  r&eacute;siliation.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  &Agrave; la r&eacute;siliation du pr&eacute;sent Contrat, le
                  Professionnel n&rsquo;aura plus acc&egrave;s aux Services BAM.
                  Le &nbsp;Professionnel doit t&eacute;l&eacute;charger toute
                  donn&eacute;e ou information stock&eacute;e dans son Espace
                  Professionnel avant la r&eacute;siliation. Si le Professionnel
                  ne peut le faire et aimerait acc&eacute;der &agrave; une
                  quelconque de ses donn&eacute;es se trouvant dans son Espace
                  Professionnel, il doit contacter BAM, qui s&rsquo;assurera que
                  ces donn&eacute;es ou informations soient envoy&eacute;es au
                  Professionnel par courriel, dans la mesure l&eacute;galement
                  et pratiquement possible.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4 x13">10. CONFIDENTIALIT&Eacute;</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="20">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Chaque partie s&rsquo;engage &agrave; maintenir et &agrave;
                  faire maintenir la confidentialit&eacute; des Informations
                  Confidentielles &agrave; tout moment et &agrave; assurer ou
                  faire assurer la s&eacute;curit&eacute; de toutes les
                  Informations Confidentielles et leur protection contre le vol,
                  tout dommage, toute perte ou tout acc&egrave;s non
                  autoris&eacute;. Chaque Partie s&rsquo;interdit &agrave; tout
                  moment, que ce soit pendant la dur&eacute;e du pr&eacute;sent
                  Contrat ou apr&egrave;s son expiration, sans l&rsquo;accord
                  &eacute;crit pr&eacute;alable du propri&eacute;taire des
                  Informations Confidentielles, directement ou indirectement,
                  d&rsquo;utiliser, de divulguer, d&rsquo;exploiter, de copier
                  ou de modifier toute Information Confidentielle ou
                  d&rsquo;autoriser ou de permettre &agrave; un tiers d&rsquo;y
                  proc&eacute;der, autrement que dans le cadre du seul exercice
                  de ses droits et ex&eacute;cution de ses obligations au titre
                  des pr&eacute;sentes.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Les stipulations du pr&eacute;sent article et les obligations
                  impos&eacute;es par celui-ci ne s&rsquo;appliquent pas
                  &agrave; une Information Confidentielle qui :
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-2 start" start="1">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  fait partie du domaine public, au moment de sa
                  r&eacute;ception par le destinataire ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  tombe ult&eacute;rieurement dans le domaine public sans faute
                  du destinataire, de ses dirigeants, employ&eacute;s ou agents
                  ;
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  est l&eacute;gitimement re&ccedil;ue par le destinataire
                  d&rsquo;un tiers sans restriction ; ou
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  est d&eacute;j&agrave; connue du destinataire avant sa
                  r&eacute;ception au titre des pr&eacute;sentes.
                </span>
              </li>
            </ol>
            <ol class="c0 lst2-kix_list_5-1" start="22">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le destinataire pourra divulguer les Informations
                  Confidentielles de mani&egrave;re confidentielle &agrave; ses
                  conseillers ou s&rsquo;il y est tenu par une loi, un
                  r&egrave;glement ou une ordonnance &eacute;manant d&rsquo;une
                  autorit&eacute; comp&eacute;tente.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent article restera en vigueur apr&egrave;s la
                  r&eacute;siliation ou l&rsquo;expiration du pr&eacute;sent
                  Contrat.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4 x13">11. RESPONSABILIT&Eacute;</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="24">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  La responsabilit&eacute; totale maximale de BAM au titre du
                  pr&eacute;sent Contrat ou en rapport avec celui-ci ou de tout
                  contrat li&eacute;, que ce soit en mati&egrave;re
                  contractuelle ou d&eacute;lictuelle (en ce compris en cas de
                  n&eacute;gligence) ou autrement ne saurait en aucun cas
                  d&eacute;passer les Charges payables &agrave; BAM au titre des
                  pr&eacute;sentes &agrave; la date de
                  l&rsquo;&eacute;v&egrave;nement donnant lieu &agrave; cette
                  responsabilit&eacute;. En outre, BAM ne sera pas responsable
                  de toute perte de revenu ou de b&eacute;n&eacute;fices, perte
                  de contrats, perte d&rsquo;activit&eacute; ou de toute perte
                  immat&eacute;rielle ou dommage ou perte indirectes survenant
                  de quelque fa&ccedil;on que ce soit m&ecirc;me en,
                  mati&egrave;re d&eacute;lictuelle (en ce compris en cas de
                  n&eacute;gligence), contractuelle ou autrement (m&ecirc;me si
                  BAM a &eacute;t&eacute; avis&eacute; par le Professionnel de
                  la possible survenance du dommage ou de la perte).
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent article restera en vigueur dans son
                  int&eacute;gralit&eacute; apr&egrave;s la r&eacute;siliation
                  ou l&rsquo;expiration du pr&eacute;sent Contrat.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4 x13">12. NOTATION / RESULTATS DE RECHERCHE</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="26">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  L&rsquo;ordre d&rsquo;apparition de la liste d&rsquo;un
                  &nbsp;Professionnel dans les r&eacute;sultats de recherche,
                  lorsqu&rsquo;un Client recherche les Services sur
                  l&rsquo;Application et/ou le Site Internet est
                  al&eacute;atoire, selon la localisation indiqu&eacute;e par le
                  Client et la disponibilit&eacute; du Professionnel sur le
                  cr&eacute;neau selectionn&eacute; par le Client
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Les Professionnels peuvent acc&eacute;der aux notes et avis
                  des Clients concernant leurs Services en visitant leur Espace
                  Professionnel, mais les Professionnels n&rsquo;ont aucun droit
                  quant &agrave; ces avis, et ne peuvent effectuer des copies de
                  ces avis &agrave; la fermeture du compte et/ou &agrave; la
                  r&eacute;siliation des Services BAM.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  BAM ne re&ccedil;oit aucune r&eacute;mun&eacute;ration directe
                  ou indirecte ou &eacute;quivalent d&rsquo;un quelconque
                  &nbsp;Professionnel afin d&rsquo;am&eacute;liorer sa notation
                  dans les r&eacute;sultats de recherche.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <span class="x10 li2-bullet-0">
            <span class="x4 x13">13. STIPULATIONS DIVERSES</span>
          </span>
          <p className="bam--cgu-paragraph">
            <ol class="c0 lst2-kix_list_5-1" start="29">
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Tous les droits sur le Site Internet, l&rsquo;Application, le
                  Site Professionnel et leur contenu incluant, sans limitation,
                  les avis des Clients, except&eacute; le Contenu du
                  &nbsp;Professionnel, et tous les autres Droits de
                  Propri&eacute;t&eacute; Intellectuelle appartenant &agrave;
                  BAM ou qui lui sont conc&eacute;d&eacute;s sous licence
                  restent la propri&eacute;t&eacute; de BAM. Aucune stipulation
                  du pr&eacute;sent Contrat ne conf&egrave;re au Professionnel
                  des droits quant &agrave; ces Droits de
                  Propri&eacute;t&eacute; Intellectuelle ou au fonds de commerce
                  qui y est associ&eacute;. Afin de rationaliser le Site
                  internet et son contenu (y compris le Contenu du
                  &nbsp;Professionnel), BAM pourra, &agrave; sa
                  discr&eacute;tion absolue et au fil du temps, modifier le
                  format, le contenu et le style des descriptions de page, des
                  photos et des menus.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Toute notification, facture ou autre communication que
                  l&rsquo;une ou l&rsquo;autre partie est tenue de signifier
                  &agrave; l&rsquo;autre sera consid&eacute;r&eacute;e comme
                  suffisamment signifi&eacute;e si elle est envoy&eacute;e
                  &agrave; l&rsquo;autre partie &agrave; l&rsquo;adresse mail
                  pr&eacute;cis&eacute;e au pr&eacute;sent Contrat (ou &agrave;
                  une autre adresse notifi&eacute;e &agrave; l&rsquo;autre
                  partie par &eacute;crit ou par courriel). Les notifications
                  envoy&eacute;es par courriel seront r&eacute;put&eacute;es
                  signifi&eacute;es trois Jours Ouvr&eacute;s apr&egrave;s le
                  jour d&rsquo;envoi par mail. Dans tous les autres cas, les
                  notifications sont r&eacute;put&eacute;es avoir
                  &eacute;t&eacute; signifi&eacute;es le jour o&ugrave; elles
                  sont effectivement re&ccedil;ues.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Nous avons le droit de r&eacute;viser et de modifier ces
                  termes et conditions au fil du temps pour refl&eacute;ter les
                  changements dans les conditions du march&eacute; affectant
                  notre activit&eacute;, les changements technologiques, les
                  changements dans les m&eacute;thodes de paiement, les
                  changements dans les lois et les exigences
                  r&eacute;glementaires et les changements dans les
                  capacit&eacute;s de notre syst&egrave;me. Veuillez consulter
                  nos conditions r&eacute;guli&egrave;rement. Celles-ci sont,
                  &agrave; tout moment, disponibles sur notre Site Internet.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  La relation des parties est celle de contractants
                  ind&eacute;pendants traitant dans les conditions normales du
                  commerce. Sauf stipulation contraire du pr&eacute;sent
                  Contrat, aucune stipulation des pr&eacute;sentes ne fait des
                  parties des associ&eacute;s, co-entrepreneurs ou
                  copropri&eacute;taires.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Aucune partie ne peut c&eacute;der, transf&eacute;rer, grever,
                  sous-traiter ou effectuer toute autre op&eacute;ration avec
                  tout ou partie du pr&eacute;sent Contrat sans l&rsquo;accord
                  &eacute;crit pr&eacute;alable de l&rsquo;autre partie (lequel
                  ne sera pas refus&eacute;, soumis &agrave; des conditions ou
                  retard&eacute; sans motif l&eacute;gitime).
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Une personne qui n&#39;est pas une partie au pr&eacute;sent
                  Contrat n&#39;a aucun droit d&#39;appliquer toute stipulation
                  du Contrat.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le manquement d&rsquo;une partie &agrave; ex&eacute;cuter ou
                  exercer &agrave; tout moment et pendant toute dur&eacute;e,
                  une stipulation du pr&eacute;sent Contrat ou un droit en vertu
                  de celui-ci, ne pourra &ecirc;tre interpr&eacute;t&eacute;
                  comme une renonciation &agrave; cette stipulation ou &agrave;
                  ce droit et ne saurait en aucun cas affecter le droit de cette
                  partie de l&rsquo;ex&eacute;cuter ou de l&rsquo;exercer
                  ult&eacute;rieurement.{" "}
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Si une stipulation des pr&eacute;sentes Conditions
                  G&eacute;n&eacute;rales de Partenariat est
                  d&eacute;clar&eacute;e nulle, ill&eacute;gale ou inapplicable
                  &agrave; quelque &eacute;gard que ce soit par une
                  autorit&eacute; comp&eacute;tente, cette stipulation sera
                  s&eacute;par&eacute;e des autres &agrave; cet &eacute;gard qui
                  resteront valables dans toute la mesure autoris&eacute;e par
                  la loi.
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent Contrat contient la totalit&eacute; des
                  conditions convenues entre les parties quant &agrave; son
                  objet et remplace tout contrat, accord ou convention
                  ant&eacute;rieur(e) entre les parties, oral(e) ou
                  &eacute;crit(e).
                </span>
              </li>
              <li class="c8 li2-bullet-0">
                <span class="x1">
                  Le pr&eacute;sent Contrat est r&eacute;gi par et
                  interpr&eacute;t&eacute; selon le droit fran&ccedil;ais. Les
                  parties se soumettent &agrave; la comp&eacute;tence exclusive
                  des juridictions fran&ccedil;aises pour r&eacute;gler tout
                  litige d&eacute;coulant du pr&eacute;sent Contrat ou s&rsquo;y
                  rapportant.
                </span>
              </li>
            </ol>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default CGUProf;
