import { Button, Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../Styles/blog.scss";
import blog from "../Styles/images/blog.png";

import { getBlog } from "./ProfBeaute/APIs";
import LoadingScreen from "react-loading-screen";
import { getDayNameAndDay } from "../utils/Utils";
import history from "../utils/History";
import { useLocation } from "react-router-dom";

const Blog = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const [listAll, setListAll] = useState([]);
  const [categories, setCategories] = useState([]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: list.length > 2 ? 3 : list.length,
    slidesToScroll: list.length > 2 ? 3 : list.length,
    initialSlide: 0,
    vertical: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    console.log(location);
    if (null != location.state && location.state.refresh) {
      // window.location.reload();
    }
    // getBlog().then((res) => {
    //   var list = res.data.filter((item) => item.actif === true);
    //   setList(list);
    //   setListAll(list);
    //   var cat = [...new Set(list.map((item) => item.categoryName))];
    //   setCategories(cat);
    //   setLoading(false);
    // });
  }, []);

  const changeCategory = (e) => {
    if (e == "Tout") {
      setList(listAll);
      return;
    }
    setList(listAll.filter((item) => item.categoryName == e));
  };
  {
    /*return (
    <LoadingScreen
      loading={loading}
      bgColor="#f5f8fa"
      spinnerColor="#f87d6b"
      textColor="#2D2926"
    >
      <div className="bam--blog--header">
        <Row
          style={{
            textAlign: "center",
            marginTop: "2vh",
          }}
        >
          <Col span="24" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <img src={blog} style={{ width: "100%" }} />{" "}
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col xl={2} md={2} xs={2}></Col>
          <Col xl={20} md={20} xs={20} style={{ marginBottom: "1vh" }}>
            <div>
              <span className="bam--blog--welcome--title">
                Retrouvez les dernières tendances <br /> beauté et bien-être
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "4vh",
        }}
      >
        <span className="bam--title--blog">Choisir une catégorie</span>
      </div>
      <Row className="bam--div--category" style={{ marginTop: "5vh" }}>
        <Col xl={5} md={5} xs={2}></Col>
        <Col span="14" style={{ textAlign: "center" }}>
          <Button
            className="bam--category--button"
            onClick={() => changeCategory("Tout")}
          >
            Tout
          </Button>
          {categories.map((e) => (
            <Button
              className="bam--category--button"
              onClick={() => changeCategory(e)}
            >
              {e}
            </Button>
          ))}
        </Col>
        <Col xl={5} md={5} xs={2}></Col>
      </Row>
      <Row style={{ marginTop: "5vh" }}>
        <Col xl={2} md={2} xs={0} />
        <Col xl={20} md={20} xs={22} style={{ textAlign: "center" }}>
          <span className="bam--latest--blog">Derniers Articles </span>
        </Col>
        <Col xl={2} md={2} xs={0} />
      </Row>
      <Row style={{ marginTop: "3vh" }}>
        <Col span={23}>
          {list.length > 3 ? (
            <Slider {...settings}>
              {list.map((e, i) => (
                <div key={i}>
                  <Row style={{ marginTop: "2vh" }}>
                    <Col xl={4} md={2} xs={0} />

                    <Col xl={4} md={9} xs={6}>
                      <div className="bam--container-blog">
                        <img
                          className="bam--blog--image"
                          src={
                            process.env.REACT_APP_BASE_URL + "images/" + e.media
                          }
                        />
                      </div>
                    </Col>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      style={{
                        marginLeft: "2vh",
                        marginTop: "2vh",
                      }}
                    >
                      <div>
                        <span
                          className="bam--title--article"
                          onClick={() =>
                            history.push({
                              pathname: "/article",
                              state: {
                                id: e.id,
                              },
                            })
                          }
                        >
                          {e.title}
                        </span>
                      </div>

                      <p className="bam--content--article">
                        <div
                          dangerouslySetInnerHTML={{ __html: e.description }}
                        />
                      </p>

                      <p className="bam--date--article">
                        {getDayNameAndDay(e.date)}
                      </p>
                    </Col>
                    <Col xl={4} md={2} xs={4} />
                  </Row>
                </div>
              ))}
            </Slider>
          ) : (
            <>
              {list.map((e) => (
                <div>
                  <Row style={{ marginTop: "2vh" }}>
                    <Col xl={4} md={2} xs={0} />

                    <Col xl={4} md={9} xs={6}>
                      <img
                        className="bam--blog--image"
                        src={
                          process.env.REACT_APP_BASE_URL + "images/" + e.media
                        }
                      />
                    </Col>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      style={{
                        marginLeft: "2vh",
                        marginTop: "2vh",
                      }}
                    >
                      <div>
                        <span
                          className="bam--title--article"
                          onClick={() =>
                            history.push({
                              pathname: "/article",
                              state: {
                                id: e.id,
                              },
                            })
                          }
                        >
                          {e.title}
                        </span>
                      </div>

                      <p className="bam--content--article">
                        <div
                          dangerouslySetInnerHTML={{ __html: e.description }}
                        />
                      </p>

                      <p className="bam--date--article">
                        {getDayNameAndDay(e.date)}
                      </p>
                    </Col>
                    <Col xl={4} md={2} xs={4} />
                  </Row>
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    </LoadingScreen>
  );*/
  }
  return <div id="dib-posts"></div>;
};

export default Blog;
