import { Card, Layout, Row } from "antd";
import React, { useLayoutEffect, useState } from "react";
import "../../Styles/left-layout.scss";
import history from "../../utils/History";
import MenuItem from "./MenuItem";
const { Sider, Header, Content } = Layout;

const LeftNavUser = ({ children }) => {
  const [menuCollapsed, setMenuState] = useState(true);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 900) {
        setMenuState(false);
      } else {
        setMenuState(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="bam--left--layout"
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
      >
        <MenuItem mode="inline"></MenuItem>
      </Sider>

      <Layout>
        <Card style={{ backgroundColor: "#fff" }}>
          <Row>
            <img
              style={{ cursor: "pointer", marginLeft: "2vh" }}
              src="/images/bam-icon.svg"
              onClick={() => history.push("/")}
            />
          </Row>
        </Card>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div style={{ padding: 24 }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LeftNavUser;
