import React from "react";
import { Row, Col, BackTop } from "antd";
import up from "../Styles/images/up.svg";

const style = {
  height: 44,
  width: 44,
  lineHeight: "40px",
  borderRadius: 6,
  backgroundColor: "#52C99F",
  textAlign: "center",
};
const CGUClient = () => {
  return (
    <>
      <Row className="bam--margin-cgu">
        <Col xs={24} md={24} xl={24} style={{ textAlign: "center" }}>
          <p className="bam--cgu-paragraph">
            <p class="c4">
              <span class="c9">
                Mentions l&eacute;gales et conditions g&eacute;n&eacute;rales
                d&#39;utilisation
              </span>
            </p>
            <p class="c4">
              <span class="c9">
                (CGU) des services propos&eacute;s sur le Site de BAM
              </span>
            </p>
            <p class="c4">
              <span class="c3">Version du 01/09/2021</span>
            </p>
            <p class="c4">
              <span class="c3">
                Le site{" "}
                <a href="https://www.bambeauté.com">www.bambeauté.com</a> est
                édité par BAM et hébergé par Ionos
              </span>
            </p>
            <p class="c4">
              <span class="c3">
                Société par Actions Simplifiée à associé Unique au capital de 3
                000 Euros, gérée par Gurbet INAN
              </span>
            </p>
            <p class="c4">
              <span class="c3">
                Numéro d’immatriculation 894 530 583 R.C.S. Paris
              </span>
            </p>

            <p class="c4">
              <span class="c3">
                Dont le si&egrave;ge social est 10 rue du Colis&eacute;e, 75008
                Paris, France
              </span>
            </p>
            <p class="c4">
              <span>Email : </span>
              <span class="c18">contact@bambeaute.com</span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p className="bam--cgu-paragraph">
            <p class="c1">
              <span class="c3">
                Veuillez lire les pr&eacute;sentes Conditions
                d&rsquo;Utilisation du Site Internet avec attention avant de
                commencer &agrave; utiliser notre site internet
                puisqu&rsquo;elles s&rsquo;appliquent &agrave; votre utilisation
                de notre site internet. Nous vous recommandons d&rsquo;imprimer
                un exemplaire des pr&eacute;sentes Conditions pour les consulter
                ult&eacute;rieurement. En utilisant notre site internet, vous
                confirmez que vous acceptez les pr&eacute;sentes Conditions
                d&rsquo;Utilisation du Site Internet et que vous vous engagez
                &agrave; vous y conformer. Si vous n&rsquo;acceptez pas les
                Conditions d&rsquo;Utilisation du Site Internet, vous ne devez
                pas utiliser notre site internet.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Veuillez noter que les pr&eacute;sentes Conditions
                d&rsquo;Utilisation du Site Internet couvrent uniquement votre
                utilisation de notre Site Internet et qu&rsquo;elles NE
                s&rsquo;appliquent PAS aux produits et services de tiers offerts
                &agrave; la r&eacute;servation sur notre Site Internet.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;acc&egrave;s, l&rsquo;utilisation et toute commande
                effectu&eacute;e sur le site internet de BAM accessible &agrave;
                l&rsquo;URL www.bambeaute.com emportent reconnaissance et
                acceptation, sans r&eacute;serve ni condition, des
                pr&eacute;sentes conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation applicables &agrave; l&rsquo;ensemble des
                services du Site (ci-apr&egrave;s &laquo; CGU &raquo;).{" "}
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Les pr&eacute;sentes CGU ont essentiellement pour objet de
                d&eacute;finir les conditions et modalit&eacute;s de la mise
                &agrave; la disposition par BAM de services de recherche et de
                prise de rendez-vous en ligne de Prestations de beaut&eacute;
                &agrave; domicile, chez le client, ou en salons de coiffure,
                instituts de beaut&eacute;, spas et ce, aupr&egrave;s des
                Professionnels membres du site internet ayant des
                activit&eacute;s dans le secteur de la Beaut&eacute; et du
                Bien-&Ecirc;tre (ci-apr&egrave;s d&eacute;nomm&eacute;s les
                &laquo; Professionnels &raquo;).
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM se r&eacute;serve le droit de modifier les CGU &agrave; tout
                moment. En cas de modification, la nouvelle version des CGU se
                substituera &agrave; la version pr&eacute;c&eacute;dente et sera
                applicable automatiquement aux Utilisateurs.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Le Site est r&eacute;serv&eacute; aux personnes physiques
                capables de souscrire des contrats, au sens du droit
                fran&ccedil;ais. Est consid&eacute;r&eacute;e comme Utilisateur
                du Site toute personne qui acc&egrave;de au Site, le visite
                et/ou l&rsquo;utilise dans l&rsquo;une de ses
                fonctionnalit&eacute;s (ci-apr&egrave;s &laquo; Utilisateur
                &raquo;).
              </span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">ARTICLE 1 - DESCRIPTION DES SERVICES</span>
          </p>
          <div>
            <p className="bam--cgu-description">
              BAM offre gratuitement aux Utilisateurs l&rsquo;acc&egrave;s et
              l&#39;utilisation du Site afin de profiter des services suivants
              (ci-apr&egrave;s d&eacute;nomm&eacute;s &laquo; les Services
              &raquo;) :
            </p>
          </div>
          <p className="bam--cgu-paragraph">
            <p class="c6">
              <span class="c0">
                1.1. Acheter des soins de beaut&eacute; et de bien-&ecirc;tre
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Le Site permet &agrave; l&#39;Utilisateur de rechercher et
                d&rsquo;acheter des prestations aupr&egrave;s des Professionnels
                de la Beaut&eacute; et du Bien-&ecirc;tre, partenaires de BAM
                (ci-apr&egrave;s les &laquo; Prestations &raquo;).
                L&rsquo;Utilisateur a la possibilit&eacute;
                de&nbsp;R&eacute;server une Prestation pour en profiter
                personnellement.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Lorsque l&rsquo;Utilisateur proc&egrave;de &agrave; la
                r&eacute;servation d&rsquo;une Prestation sur le Site, la prise
                de rendez-vous est faite sur le Site lui-m&ecirc;me et la
                disponibilit&eacute; de la Prestation est pr&eacute;cis&eacute;e
                au moment de la prise de rendez-vous effectu&eacute;e par
                l&rsquo;Utilisateur. Cette information est donn&eacute;e
                &agrave; titre indicatif. BAM ne garantit en aucune
                mani&egrave;re celle-ci, la disponibilit&eacute; de la
                Prestation pouvant ne pas avoir &eacute;t&eacute; mise &agrave;
                jour en temps r&eacute;el par le Professionnel concern&eacute;
                dans son agenda. Dans ce cas, le Professionnel appellera
                l&rsquo;Utilisateur pour lui proposer un autre cr&eacute;neau. A
                cet effet, l&rsquo;Utilisateur accepte d&rsquo;&ecirc;tre
                contact&eacute; par le Professionnel concern&eacute; afin de
                fixer un nouveau rendez-vous ; l&rsquo;Utilisateur est
                inform&eacute; et accepte que ses donn&eacute;es &agrave;
                caract&egrave;re personnel soient communiqu&eacute;es au
                Professionnel de la Beaut&eacute; et du Bien-&ecirc;tre
                concern&eacute;, conform&eacute;ment &agrave; l&rsquo;article
                8.2 des pr&eacute;sentes. BAM n&rsquo;agissant qu&rsquo;en
                qualit&eacute; de simple interm&eacute;diaire entre
                l&rsquo;Utilisateur et les Professionnels, tout diff&eacute;rend
                pouvant s&rsquo;&eacute;lever entre l&rsquo;Utilisateur et le
                Professionnel doit &ecirc;tre trait&eacute; directement entre
                eux.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                1.2. B&eacute;n&eacute;ficier de promotions et offres
                sp&eacute;ciales propos&eacute;es par les Professionnels
                r&eacute;f&eacute;renc&eacute;s sur le Site
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur qui r&eacute;serve une Prestation
                aupr&egrave;s d&rsquo;un Professionnel par le biais du Site peut
                b&eacute;n&eacute;ficier de promotions ou offres
                sp&eacute;ciales propos&eacute;es par les Professionnels,
                &eacute;tant pr&eacute;cis&eacute; que tous les Professionnels
                r&eacute;f&eacute;renc&eacute;s sur le Site ne proposent pas de
                promotion ou d&#39;offre sp&eacute;ciale.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Les conditions de validit&eacute; de ces promotions ou offres
                sp&eacute;ciales sont, le cas &eacute;ch&eacute;ant,
                d&eacute;taill&eacute;es sur le Site.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                1.3. Possibilit&eacute; de noter et commenter des Prestations de
                beaut&eacute; et de bien-&ecirc;tre en ligne
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur, qui r&eacute;serve une Prestation
                aupr&egrave;s d&rsquo;un Professionnel par le biais du Site, a
                la possibilit&eacute; de commenter et noter sa Prestation,
                seulement apr&egrave;s en avoir effectivement
                b&eacute;n&eacute;fici&eacute;.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                1.4. Services personnalis&eacute;s associ&eacute;s
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Le Compte client cr&eacute;&eacute; par l&rsquo;Utilisateur lors
                de sa commande, dans les conditions de l&rsquo;article 3.1 des
                pr&eacute;sentes, permet notamment &agrave; ce dernier :
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                &bull; de r&eacute;server des Prestations sans avoir &agrave;
                ressaisir ses coordonn&eacute;es,
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                &bull; de suivre l&rsquo;historique de ses r&eacute;servations
                et de ses avis,
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                &bull; d&rsquo;annuler et de modifier la date de ses rendez-vous
                si le d&eacute;lai d&rsquo;annulation du Professionnel choisi le
                lui permet.
              </span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">
              ARTICLE 2 - FONCTIONNEMENT DU SERVICE D&rsquo;INTERMEDIATION DE
              BAM
            </span>
          </p>
          <p className="bam--cgu-paragraph">
            <p class="c6">
              <span class="c0">
                2.1. R&eacute;f&eacute;rencement des Professionnels
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Les Professionnels autoris&eacute;s &agrave; proposer leurs
                services &agrave; la r&eacute;servation sur le Site sont des
                professionnels ind&eacute;pendants de beaut&eacute; &amp;
                bien-&ecirc;tre avec lesquels BAM entretient une relation
                contractuelle.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Pour &ecirc;tre r&eacute;servable sur BAM, un Professionnel doit
                :
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                &bull; &nbsp;Avoir certifi&eacute; sur l&rsquo;honneur
                &ecirc;tre un professionnel disposant d&rsquo;un certificat
                d&rsquo;aptitude professionnelle et accepter de justifier
                &agrave; BAM l&rsquo;ensemble des documents pouvant &ecirc;tre
                requis.{" "}
              </span>
            </p>
            <p class="c1 c16">
              <span class="c3"></span>
            </p>
            <p class="c1">
              <span class="c3">
                &bull; Avoir lu et accept&eacute; les Conditions
                G&eacute;n&eacute;rales de Partenariat applicables &agrave; sa
                relation contractuelle avec BAM.{" "}
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Le non-respect par le Professionnel de ses obligations
                vis-&agrave;-vis de BAM peut entra&icirc;ner son
                d&eacute;r&eacute;f&eacute;rencement.
              </span>
            </p>
            <p class="c6">
              <span class="c0">2.2. Exhaustivit&eacute;</span>
            </p>
            <p class="c1">
              <span class="c3">
                Les Professionnels r&eacute;f&eacute;renc&eacute;s ne proposent
                pas n&eacute;cessairement l&rsquo;int&eacute;gralit&eacute; de
                leurs prestations sur le Site.
              </span>
            </p>
            <p class="c6">
              <span class="c0">2.3. Mise &agrave; jour</span>
            </p>
            <p class="c1">
              <span class="c3">
                Les informations sur les Prestations communiqu&eacute;es sur le
                Site se fondent sur les informations donn&eacute;es par les
                Professionnels, lesquels les actualisent (prix,
                disponibilit&eacute;s, etc), &agrave; leur enti&egrave;re
                discr&eacute;tion et &agrave; leur rythme. BAM s&rsquo;efforce
                de mettre &agrave; jour le Site en temps r&eacute;el.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                2.4. Classement des Prestations sur le site
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Sur la page de r&eacute;sultats du Site, les Prestations dans la
                r&eacute;gion ou la ville concern&eacute;e sont class&eacute;es
                par d&eacute;faut.
              </span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">ARTICLE 3 - TRAITEMENT DE LA COMMANDE</span>
          </p>
          <p className="bam--cgu-paragraph">
            <p class="c6">
              <span class="c0">
                3.1. Cr&eacute;ation d&rsquo;un Compte client par
                l&rsquo;Utilisateur
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Tout Utilisateur peut naviguer sur le Site sans qu&rsquo;aucune
                obligation n&rsquo;en d&eacute;coule. En revanche,
                lorsqu&rsquo;un Utilisateur souhaite r&eacute;server une
                Prestation, celui-ci doit cr&eacute;er un Compte client. Lors de
                cette cr&eacute;ation, l&rsquo;Utilisateur doit fournir les
                informations suivantes, pr&eacute;cises et exactes, qui sont
                n&eacute;cessaires au parfait traitement de sa commande :
              </span>
            </p>
            <p class="c11 c2">
              <span class="c3">- Adresse postale</span>
            </p>
            <p class="c11 c2">
              <span class="c3">- Adresse email</span>
            </p>
            <p class="c2 c11">
              <span class="c3">- Mot de passe</span>
            </p>
            <p class="c11 c2">
              <span class="c3">- Nom</span>
            </p>
            <p class="c11 c2">
              <span class="c3">- Pr&eacute;nom</span>
            </p>
            <p class="c11 c2">
              <span class="c3">- Num&eacute;ro de t&eacute;l&eacute;phone</span>
            </p>
            <p class="c1">
              <span class="c3">
                Tous les champs doivent &ecirc;tre obligatoirement
                renseign&eacute;s pour la prise en compte de la cr&eacute;ation
                du Compte client.
              </span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">ARTICLE 4 - OBLIGATIONS DE L&#39;UTILISATEUR</span>
            <div>
              <p className="bam--cgu-description">
                Il appartient &agrave; l&#39;Utilisateur de faire toutes
                v&eacute;rifications qui semblent n&eacute;cessaires ou
                opportunes avant de proc&eacute;der &agrave; une commande de
                Prestation aupr&egrave;s des Professionnels pr&eacute;sents sur
                le Site.
              </p>
            </div>
          </p>
          <p className="bam--cgu-paragraph">
            <p class="c1"></p>
            <p class="c6">
              <span class="c0">
                4.1. Communication d&rsquo;informations exactes,
                compl&egrave;tes et mises &agrave; jour
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur s&rsquo;engage &agrave; fournir des
                informations exactes et compl&egrave;tes et de les maintenir
                &agrave; jour, tout particuli&egrave;rement les donn&eacute;es
                n&eacute;cessaires &agrave; son identification, en vue de
                l&#39;ouverture d&#39;un Compte client ainsi que, le cas
                &eacute;ch&eacute;ant, les informations relatives au mode de
                paiement qu&rsquo;il choisira.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                4.2. Respect des droits de propri&eacute;t&eacute;
                intellectuelle attach&eacute;s au Site
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Le Site et chacun des &eacute;l&eacute;ments qui le composent,
                notamment mais non limitativement, les textes, images,
                photographies, illustrations, documents, etc., sont, sauf
                mentions particuli&egrave;res, la propri&eacute;t&eacute;
                exclusive de BAM. En cons&eacute;quence, en application des
                dispositions du Code de la propri&eacute;t&eacute;
                intellectuelle fran&ccedil;ais, des dispositions
                l&eacute;gislatives et r&eacute;glementaires de tous pays et des
                conventions internationales, toute reproduction ou
                repr&eacute;sentation, int&eacute;grale ou partielle, du Site ou
                de l&#39;un quelconque des &eacute;l&eacute;ments qui le
                composent est interdite de m&ecirc;me que leur
                alt&eacute;ration. A ce titre, l&rsquo;Utilisateur
                s&rsquo;interdit de porter atteinte, de quelque mani&egrave;re
                que ce soit, aux &eacute;l&eacute;ments de
                propri&eacute;t&eacute; intellectuelle attach&eacute;s au Site
                et notamment de :
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                - reproduire et/ou repr&eacute;senter tout &eacute;l&eacute;ment
                composant le site et notamment les textes, images,
                photographies, illustrations et documents sur quelque support
                que ce soit ;
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                - d&rsquo;adapter, arranger, modifier, corriger, associer,
                traduire en toutes langues ou tous langages, mettre sur le
                march&eacute; &agrave; titre gratuit ou on&eacute;reux,
                commercialiser, tout ou partie du Site ou de l&rsquo;un
                quelconque des &eacute;l&eacute;ments qui le composent, quels
                qu&rsquo;en soient le moyen et le support.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                4.3. Obligations relatives aux contenus publi&eacute;s
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Dans le cadre des Services, l&rsquo;Utilisateur peut &ecirc;tre
                amen&eacute; &agrave; noter et/ou commenter les Prestations
                r&eacute;serv&eacute;es sur le Site et dont il a
                b&eacute;n&eacute;fici&eacute;.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Dans ce cadre, l&#39;Utilisateur s&#39;engage &agrave; ne pas :
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                &bull; Utiliser les Services propos&eacute;s pour tout objet
                contrevenant aux lois fran&ccedil;aises, aux dispositions
                l&eacute;gislatives et r&eacute;glementaires de tous pays et aux
                conventions internationales, &agrave; l&#39;ordre public et aux
                bonnes m&oelig;urs ;
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                &bull; Usurper l&#39;identit&eacute; d&#39;une autre personne,
                Utilisateur ou non ;
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                &bull; Mettre en ligne, enregistrer ou transmettre des
                &eacute;l&eacute;ments pouvant porter atteinte aux droits des
                tiers, et notamment :
              </span>
            </p>
            <ul class="c10 lst-kix_list_3-0 start">
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments prot&eacute;g&eacute;s par des
                  droits d&#39;auteur, sauf s&#39;il garantit avoir obtenu la
                  permission du titulaire des droits et qu&#39;il peut en
                  apporter la preuve ;
                </span>
              </li>
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments pr&eacute;sentant le
                  caract&egrave;re de la concurrence d&eacute;loyale ;
                </span>
              </li>
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments obsc&egrave;nes, violents,
                  diffamants, injurieux, mena&ccedil;ants, malveillants, abusifs
                  &agrave; l&#39;&eacute;gard de toute personne physique ou
                  morale, Utilisateur du service ou non ;
                </span>
              </li>
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments incitant &agrave; la
                  discrimination, &agrave; la haine d&#39;une personne ou
                  d&#39;un groupe de personnes &agrave; raison notamment de
                  leurs opinions politiques, de leur origine ou de leur
                  appartenance ou de leur non-appartenance vraie ou
                  suppos&eacute;e &agrave; une ethnie, une nation, une race ou
                  une religion d&eacute;termin&eacute;e ou de leur orientation
                  sexuelle ;
                </span>
              </li>
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments pouvant porter atteinte &agrave; la
                  pr&eacute;somption d&#39;innocence des personnes ou au respect
                  et &agrave; l&#39;autorit&eacute; dus &agrave; la Justice ;
                </span>
              </li>
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments &agrave; caract&egrave;re
                  pornographique et/ou ayant trait &agrave; la
                  p&eacute;dopornographie ou nuisibles &agrave; la protection
                  des mineurs ;
                </span>
              </li>
              <li class="c12 li-bullet-0">
                <span class="c3">
                  Des &eacute;l&eacute;ments pouvant porter atteinte &agrave; la
                  s&eacute;curit&eacute; ou &agrave;
                  l&#39;int&eacute;grit&eacute; d&#39;un Etat ou d&#39;un
                  territoire, pouvant inciter &agrave; commettre un
                  d&eacute;lit, un crime ou un acte de terrorisme ou faisant
                  l&#39;apologie des crimes de guerre ou des crimes contre
                  l&#39;humanit&eacute; ou incitant au suicide ;
                </span>
              </li>
              <li class="c1 c13 li-bullet-0">
                <span class="c3">
                  Des publicit&eacute;s ou des sollicitations commerciales et/ou
                  des petites annonces de toute nature.
                </span>
              </li>
            </ul>
            <p class="c1 c2">
              <span class="c3">
                &bull; Publier du contenu susceptible de perturber ou
                d&rsquo;endommager de quelque mani&egrave;re que ce soit le
                syst&egrave;me informatique de BAM.
              </span>
            </p>
            <p class="c1 c2">
              <span class="c3">
                L&#39;Utilisateur s&#39;engage en outre &agrave; r&eacute;diger
                ses messages en langue fran&ccedil;aise.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                4.4. Utilisation des contenus publi&eacute;s
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur accepte que les contenus ainsi publi&eacute;s
                deviennent des informations publiques. L&#39;Utilisateur accepte
                que les contenus soient publi&eacute;s, reproduits,
                modifi&eacute;s, traduits, distribu&eacute;s,
                pr&eacute;sent&eacute;s et/ou affich&eacute;s, sous quelque
                forme, support ou technologie que ce soit, actuellement connus
                ou inconnus.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur conc&egrave;de aux autres Utilisateurs, le
                droit d&#39;acc&eacute;der, afficher, enregistrer et reproduire
                les communications pour leur usage personnel, sans que la
                responsabilit&eacute; de BAM ne puisse &ecirc;tre engag&eacute;e
                &agrave; cet &eacute;gard.
              </span>
            </p>
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">ARTICLE 5 - DUREE &ndash; RESILIATION</span>
          </p>
          <p className="bam--cgu-paragraph">
            <p class="c6">
              <span class="c0">5.1. Dur&eacute;e</span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur est autoris&eacute; &agrave; acc&eacute;der
                au Site et utiliser ses Services pour une dur&eacute;e
                ind&eacute;termin&eacute;e.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                5.2. R&eacute;siliation &agrave; l&rsquo;initiative de BAM
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                En cas de manquement par l&rsquo;Utilisateur &agrave;
                l&rsquo;une des dispositions des pr&eacute;sentes CGU, BAM peut
                r&eacute;silier les CGU, de plein droit, &agrave; tout moment,
                sans pr&eacute;avis ni indemnit&eacute;, par courrier
                &eacute;lectronique &agrave; l&rsquo;adresse renseign&eacute;e
                par l&rsquo;Utilisateur sur le Site, sans qu&#39;il soit
                n&eacute;cessaire d&#39;effectuer aucune autre formalit&eacute;
                judiciaire ou extrajudiciaire.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                5.3. R&eacute;siliation &agrave; l&rsquo;initiative de
                l&rsquo;Utilisateur
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                La cl&ocirc;ture du Compte client &agrave; l&rsquo;initiative de
                l&rsquo;Utilisateur vaut r&eacute;siliation. Les
                pr&eacute;sentes CGU demeureront n&eacute;anmoins applicables
                jusqu&rsquo;&agrave; la parfaite cl&ocirc;ture des relations
                contractuelles engag&eacute;es entre les Parties, notamment
                lorsque des commandes de Prestations ou prises de rendez-vous
                ont &eacute;t&eacute; amorc&eacute;es pr&eacute;alablement
                &agrave; la cl&ocirc;ture du Compte client.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                5.4. Cons&eacute;quences de la r&eacute;siliation
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                La r&eacute;siliation des CGU pour quelque cause que ce soit
                peut entra&icirc;ner l&rsquo;arr&ecirc;t automatique et
                d&eacute;finitif de l&rsquo;acc&egrave;s au Site et aux
                Services.{" "}
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                En cas de manquement par l&rsquo;Utilisateur aux
                pr&eacute;sentes CGU, BAM se r&eacute;serve le droit de
                suspendre sans pr&eacute;avis ni indemnit&eacute;
                l&rsquo;acc&egrave;s aux Services du Site par tout
                proc&eacute;d&eacute; technique qui pourrait
                s&rsquo;av&eacute;rer n&eacute;cessaire, et notamment par la
                suppression, temporaire ou d&eacute;finitive, de son Compte
                client, la modification, la limitation ou la suppression de
                l&#39;acc&egrave;s aux Services, sans que l&rsquo;Utilisateur ne
                puisse r&eacute;clamer aucune indemnit&eacute; quelconque.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Le cas &eacute;ch&eacute;ant, BAM sera en droit de
                r&eacute;clamer des indemnit&eacute;s destin&eacute;es &agrave;
                compenser le pr&eacute;judice subi.
              </span>
            </p>{" "}
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">
              ARTICLE 6 - LIMITATIONS DE RESPONSABILIT&Eacute;
            </span>{" "}
          </p>
          <p className="bam--cgu-paragraph">
            <p class="c6">
              <span class="c0">
                6.1. Responsabilit&eacute; de l&rsquo;Utilisateur
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                &bull; Confidentialit&eacute; des identifiants
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Les identifiants permettant &agrave; l&rsquo;Utilisateur
                d&rsquo;acc&eacute;der &agrave; son Compte client et choisis par
                lui sont personnels et confidentiels. Ils ne peuvent &ecirc;tre
                chang&eacute;s que par l&#39;Utilisateur dans son Compte client
                ou &agrave; l&#39;initiative de BAM.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur s&#39;oblige donc &agrave; conserver secrets
                ses identifiants et &agrave; ne pas les divulguer, transmettre
                ou communiquer, sous quelque forme que ce soit, &agrave; un
                tiers. La sauvegarde de la confidentialit&eacute; du mot de
                passe choisi par l&#39;Utilisateur rel&egrave;ve de
                l&#39;enti&egrave;re responsabilit&eacute; de
                l&rsquo;Utilisateur et ce dernier d&eacute;charge donc BAM de
                toute responsabilit&eacute; &agrave; cet &eacute;gard.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur est seul responsable de l&#39;utilisation qui
                est faite de ses identifiants. Tout acc&egrave;s ou utilisation
                des Services effectu&eacute; &agrave; partir du Compte client
                d&#39;un Utilisateur sera r&eacute;put&eacute; avoir
                &eacute;t&eacute; effectu&eacute; par ce dernier.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur est donc tenu de se montrer prudent dans
                l&rsquo;utilisation de ses identifiants et il doit notamment
                s&#39;assurer de s&rsquo;&ecirc;tre d&eacute;connect&eacute; du
                Site &agrave; l&#39;issue de chaque session, en particulier
                lorsqu&#39;il acc&egrave;de au Site &agrave; partir d&#39;un
                ordinateur public. En cas de perte ou de
                r&eacute;v&eacute;lation au public de ces identifiants et/ou
                mots de passe personnels, l&rsquo;Utilisateur le signalera
                imm&eacute;diatement &agrave; BAM pour que lui soit
                envoy&eacute; un mail de r&eacute;initialisation de son mot de
                passe et/ou que son compte soit rendu inaccessible.
              </span>
            </p>
            <p class="c1">
              <span>
                En cas de perte ou de vol d&#39;un ou plusieurs de ses
                identifiants, l&#39;Utilisateur demeure responsable de toute
                cons&eacute;quence dommageable li&eacute;e &agrave; cette perte
                ou ce vol et doit mettre en &oelig;uvre, dans les plus brefs
                d&eacute;lais, la proc&eacute;dure lui permettant de les
                modifier, disponible &agrave; l&#39;adresse{" "}
              </span>
              <a href="https://www.bambeaute.com/profil">
                <span class="c24">www.bambeaute.com/profil</span>
              </a>
            </p>
            <p class="c1">
              <span class="c3">&bull; Contenus publi&eacute;s</span>
            </p>
            <p class="c1">
              <span class="c3">
                Tous les Contenus, qu&#39;il s&#39;agisse des contenus
                publi&eacute;s ou communiqu&eacute;s &agrave; titre public ou
                priv&eacute;, sont plac&eacute;s sous la seule
                responsabilit&eacute; de l&rsquo;Utilisateur qui les publie et
                les utilise &agrave; ses entiers risques et p&eacute;rils.
              </span>
            </p>
            <p class="c6">
              <span class="c0">6.2. Responsabilit&eacute; de BAM</span>
            </p>
            <p class="c1">
              <span class="c3">
                &bull; Produits, services et/ou pratiques commerciales BAM ne
                garantit aucunement et de quelque fa&ccedil;on que ce soit les
                produits, services et/ou pratiques commerciales des
                Professionnels pr&eacute;sents sur son Site. BAM ne garantit pas
                &agrave; l&#39;Utilisateur la pleine et enti&egrave;re
                satisfaction relative aux produits, services et/ou pratiques
                commerciales qu&#39;il a r&eacute;serv&eacute;s &agrave;
                l&rsquo;un des Professionnels par le biais du Site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                &bull; Des massages sont propos&eacute;s sur le Site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur reconna&icirc;t et accepte que le terme
                &laquo; massage &raquo; ne corresponde aucunement &agrave; la
                d&eacute;finition donn&eacute;e par les dispositions
                r&egrave;glementaires de l&rsquo;article R. 4321-3 du Code de la
                Sant&eacute; Publique fran&ccedil;ais (D&eacute;cret n&deg;
                2004-802 du 29 juillet 2004 - JO du 8 ao&ucirc;t 2004). Il est
                rappel&eacute; que les massages m&eacute;dicaux, sportifs ou
                th&eacute;rapeutiques ne peuvent &ecirc;tre
                r&eacute;alis&eacute;s que par des m&eacute;decins ou masseurs
                kin&eacute;sith&eacute;rapeutes. Le terme &laquo; massage
                &raquo; d&eacute;signe sur le Site de mani&egrave;re
                g&eacute;n&eacute;rique et globale toute man&oelig;uvre, tout
                soin, traitement ou modelage uniquement relaxant et/ou
                esth&eacute;tique, excluant tout caract&egrave;re ou toute vertu
                d&rsquo;ordre m&eacute;dical ou th&eacute;rapeutique ou
                sportive. Sauf pr&eacute;cision expresse du Professionnel
                v&eacute;rifi&eacute;e par l&rsquo;Utilisateur lui-m&ecirc;me,
                aucun des massages n&rsquo;est r&eacute;alis&eacute; par un
                m&eacute;decin ou un masseur kin&eacute;sith&eacute;rapeute.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                En cas de grossesse et quel que soit le soin, il est
                recommand&eacute; &agrave; l&rsquo;Utilisateur de demander
                l&rsquo;avis pr&eacute;alable de son m&eacute;decin, de
                m&ecirc;me que celui du Professionnel o&ugrave; il souhaite
                aller.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Il en est de m&ecirc;me pour les personnes fragiles, comme par
                exemple sans que cette liste ne soit consid&eacute;r&eacute;e
                comme limitative, les personnes &acirc;g&eacute;es, les
                personnes ayant des probl&egrave;mes cardiaques ou de tension,
                les personnes sujettes &agrave; des allergies, etc.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Pour les personnes mineures, leur responsable l&eacute;gal est
                tenu de v&eacute;rifier si l&rsquo;acc&egrave;s au Professionnel
                leur est autoris&eacute;.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                &bull; Fonctionnement du r&eacute;seau Internet
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Compte tenu des sp&eacute;cificit&eacute;s du r&eacute;seau
                Internet sur lequel BAM n&rsquo;a aucun contr&ocirc;le ni
                ma&icirc;trise, BAM fera ses meilleurs efforts pour assurer la
                continuit&eacute; du service. La responsabilit&eacute; de BAM ne
                peut pas &ecirc;tre engag&eacute;e en cas de dommages
                li&eacute;s &agrave; l&#39;impossibilit&eacute; temporaire
                d&#39;acc&eacute;der &agrave; l&#39;un des Services du Site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Compte tenu des caract&eacute;ristiques du r&eacute;seau
                Internet, l&#39;Utilisateur reconna&icirc;t notamment qu&#39;il
                est impossible de garantir que les donn&eacute;es que
                l&#39;Utilisateur aura transmises seront enti&egrave;rement
                s&eacute;curis&eacute;es. Ainsi, la responsabilit&eacute; de BAM
                ne pourra &ecirc;tre engag&eacute;e en cas d&rsquo;incident
                portant atteinte &agrave; la s&eacute;curit&eacute; et
                protection des donn&eacute;es. BAM assure n&eacute;anmoins user
                de tous les moyens mis &agrave; sa disposition pour garantir un
                maximum de s&eacute;curit&eacute; et met en place un
                syst&egrave;me de cryptage de donn&eacute;es
                s&eacute;curis&eacute; lors du Paiement des Prestations sur le
                site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&#39;Utilisateur reconna&icirc;t que, d&#39;une mani&egrave;re
                g&eacute;n&eacute;rale et en l&#39;&eacute;tat de la technique
                actuelle, chaque fois qu&#39;il fournit des informations
                personnelles en ligne, ces informations peuvent &ecirc;tre
                collect&eacute;es et utilis&eacute;es par des tiers. Par
                cons&eacute;quent, l&#39;Utilisateur d&eacute;charge BAM de
                toute responsabilit&eacute; ou cons&eacute;quence dommageable de
                l&#39;utilisation par des tiers des informations
                &eacute;chang&eacute;es par le biais des outils de communication
                (notamment les avis) propos&eacute;s par le Site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">&bull; Modification du site</span>
            </p>
            <p class="c1">
              <span class="c3">
                Toutes les informations contenues sur le Site sont susceptibles
                d&#39;&ecirc;tre modifi&eacute;es &agrave; tout moment, compte
                tenu de l&#39;interactivit&eacute; du Site, sans que cela puisse
                engager la responsabilit&eacute; de BAM, ce que
                l&rsquo;Utilisateur reconna&icirc;t et accepte.
              </span>
            </p>
            <p class="c1">
              <span class="c3">&bull; Utilisation du site</span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM fait ses meilleurs efforts pour que les informations
                pr&eacute;sent&eacute;es sur le Site relativement aux
                Prestations et aux Professionnels soient
                d&eacute;taill&eacute;es, compl&egrave;tes,
                v&eacute;rifi&eacute;es ou exactes. Ainsi, les documents,
                informations, fiches descriptives, et, en g&eacute;n&eacute;ral,
                tout contenu pr&eacute;sent sur le Site sont fournis en
                &quot;l&#39;&eacute;tat&quot;, sans aucune garantie
                d&rsquo;exactitude.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur reconna&icirc;t express&eacute;ment que les
                photos pr&eacute;sentes sur le Site ne sont pas contractuelles.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                La disponibilit&eacute; d&rsquo;une prise de rendez-vous est
                v&eacute;rifi&eacute;e en temps r&eacute;el informatiquement et
                la cabine, l&rsquo;offre ou la Prestation sont r&eacute;ellement
                bloqu&eacute;es informatiquement. Toutefois, BAM ne pouvant pas
                mat&eacute;riellement v&eacute;rifier l&#39;exactitude des
                renseignements recueillis en temps r&eacute;el aupr&egrave;s des
                Professionnels, sa responsabilit&eacute; ne peut en aucun cas
                &ecirc;tre engag&eacute;e si l&#39;Utilisateur ne parvient pas
                effectivement &agrave; b&eacute;n&eacute;ficier du rendez-vous
                en question, &agrave; l&rsquo;heure et la date pr&eacute;vues.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Il appartient &agrave; l&rsquo;Utilisateur de v&eacute;rifier
                les conditions de validit&eacute; d&#39;une promotion avant de
                prendre rendez-vous et/ou d&rsquo;acheter une Prestation sur le
                Site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur ne pourra en aucun cas r&eacute;clamer
                &agrave; BAM et/ou aux Professionnels, une promotion en dehors
                des conditions de validit&eacute; telles
                qu&#39;explicit&eacute;es sur le Site, et en dehors de la
                proc&eacute;dure de prise de rendez-vous. Dans ce cas de figure,
                aucun &eacute;change ni remboursement ne pourra &ecirc;tre
                allou&eacute;.
              </span>
            </p>
            <p class="c1">
              <span class="c3">&bull; Liens hypertextes</span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM n&rsquo;est aucunement responsable des liens hypertextes
                vers des sites internet de tiers sur lesquels le Site renvoie
                &agrave; titre informatif, facultatif et de mani&egrave;re non
                contraignante, en ce qui concerne notamment
                l&rsquo;acc&egrave;s, la collecte ou le traitement de leur
                contenu, des donn&eacute;es &agrave; caract&egrave;re personnel
                et des modalit&eacute;s d&rsquo;utilisation (qu&rsquo;elle soit
                gratuite ou payante).
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM n&rsquo;est pas non plus responsable des liens hypertextes
                figurant sur des sites tiers et dirigeant vers le Site.
              </span>
            </p>
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={24} xl={24} style={{ padding: "10px" }}>
          <p class="c11">
            <span class="c7">ARTICLE 7 - DISPOSITIONS DIVERSES</span>
          </p>
          <p className="bam--cgu-paragraph">
            <p class="c6">
              <span class="c0">7.1. Convention de preuve</span>
            </p>
            <p class="c1">
              <span class="c3">
                Compte tenu de la nature de leurs &eacute;changes et en
                particulier de la d&eacute;mat&eacute;rialisation de leur
                relation, BAM et l&rsquo;Utilisateur acceptent que
                l&#39;ensemble des enregistrements informatiques dans le
                syst&egrave;me d&#39;information de BAM aura, en cas de litige,
                valeur de preuve.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                7.2. Protection des donn&eacute;es &agrave; caract&egrave;re
                personnel
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM s&rsquo;engage &agrave; respecter la l&eacute;gislation en
                mati&egrave;re de protection des donn&eacute;es &agrave;
                caract&egrave;re personnel.
              </span>
            </p>
            <p class="c1">
              <span class="c3">&bull; CNIL</span>
            </p>
            <p class="c1">
              <span class="c3">
                Conform&eacute;ment &agrave; la Loi n&deg; 78-87 du 6 janvier
                1978 modifi&eacute;e, dite Informatique et Libert&eacute;s, les
                donn&eacute;es &agrave; caract&egrave;re personnel des
                Utilisateurs du Site feront l&rsquo;objet d&rsquo;une collecte
                et d&rsquo;un traitement automatis&eacute;s dont seuls le
                personnel habilit&eacute; de BAM et les Professionnels
                concern&eacute;s habilit&eacute;s seront destinataires, afin de
                permettre l&rsquo;acc&egrave;s et l&rsquo;utilisation du Site,
                ainsi que la fourniture et la gestion des Services
                sollicit&eacute;s par l&rsquo;Utilisateur, en ce compris dans le
                but de faire profiter l&#39;Utilisateur, avec son consentement
                pr&eacute;alable, d&#39;informations et services
                personnalis&eacute;s.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                En application des dispositions des articles 38 et suivants de
                cette Loi, les Utilisateurs disposent d&rsquo;un droit
                d&rsquo;information, de communication, de rectification en cas
                d&rsquo;inexactitude sur les donn&eacute;es &agrave;
                caract&egrave;re personnel les concernant. Les Utilisateurs
                invoquant des motifs l&eacute;gitimes disposent &eacute;galement
                d&rsquo;un droit d&rsquo;opposition &agrave; la collecte et au
                traitement de leurs donn&eacute;es &agrave; caract&egrave;re
                personnel.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Pour l&rsquo;exercice de ces droits, l&rsquo;Utilisateur devra
                adresser sa demande par e-mail &agrave; l&rsquo;adresse suivante
                : contact@bambeaute.com
              </span>
            </p>
            <p class="c1">
              <span class="c3">&bull; Cookies</span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur est inform&eacute; que des &laquo; cookies
                &raquo; sont implant&eacute;s dans son ordinateur, son mobile ou
                sa tablette, lors de la consultation du Site.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Un cookie ne permet pas d&rsquo;identifier un Utilisateur. En
                revanche, il enregistre des informations relatives &agrave; la
                navigation de l&rsquo;ordinateur sur le Site (les pages
                consult&eacute;es, la date et l&#39;heure de la consultation,
                etc.) qui pourront &ecirc;tre lues lors de visites
                ult&eacute;rieures afin de faciliter l&#39;ergonomie de la
                visite et am&eacute;liorer la fourniture des Services. La
                dur&eacute;e maximale de conservation de ces informations dans
                l&rsquo;ordinateur est de 1(un) an.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur peut choisir, &agrave; tout moment, de
                d&eacute;sactiver ces cookies ou param&eacute;trer son
                navigateur afin que lui soient signal&eacute;s les cookies qui
                sont d&eacute;pos&eacute;s et lui soit demand&eacute;
                d&rsquo;accepter ou non de tels cookies. L&rsquo;Utilisateur
                peut &eacute;galement d&eacute;cider d&rsquo;accepter ou de
                refuser les cookies au cas par cas ou bien les refuser
                syst&eacute;matiquement.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM rappelle cependant aux Utilisateurs que le
                param&eacute;trage des cookies est susceptible de modifier leurs
                conditions d&#39;acc&egrave;s au Site et Services
                n&eacute;cessitant l&#39;utilisation de cookies.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                L&rsquo;Utilisateur peut restreindre l&rsquo;utilisation des
                cookies en modifiant les param&egrave;tres de son navigateur de
                la mani&egrave;re suivante :
              </span>
            </p>
            <p class="c1">
              <span class="c3">Sous Internet Explorer :</span>
            </p>
            <ul class="c10 lst-kix_list_2-0 start">
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Cliquez sur Outils dans la partie sup&eacute;rieure de la
                  fen&ecirc;tre du navigateur et s&eacute;lectionnez Options
                  Internet.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Cliquez sur l&#39;onglet Confidentialit&eacute;.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour activer les cookies, placez le curseur sur Moyenne ou sur
                  un param&egrave;tre inf&eacute;rieur.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour d&eacute;sactiver les cookies, placez le curseur tout en
                  haut afin de bloquer tous les cookies.
                </span>
              </li>
            </ul>
            <ul class="c10 lst-kix_list_1-0 start">
              <li class="c5 li-bullet-0">
                <span class="c3">Sous Firefox :</span>
              </li>
            </ul>
            <ul class="c10 lst-kix_list_2-0">
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Cliquez sur Outils dans le menu du navigateur et
                  s&eacute;lectionnez Options.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  S&eacute;lectionnez le volet Vie priv&eacute;e.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour activer les cookies, cochez la case Accepter les cookies
                  du Site.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour d&eacute;sactiver les cookies,
                  d&eacute;s&eacute;lectionnez la case Accepter les cookies du
                  Site.
                </span>
              </li>
            </ul>
            <ul class="c10 lst-kix_list_1-0">
              <li class="c5 li-bullet-0">
                <span class="c3">Sous Chrome :</span>
              </li>
            </ul>
            <ul class="c10 lst-kix_list_2-0">
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Cliquez sur l&#39;ic&ocirc;ne en haut &agrave; droite de la
                  barre de recherche de votre navigateur.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  S&eacute;lectionnez l&#39;option Param&egrave;tres.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Cliquez sur Afficher les Param&egrave;tres avanc&eacute;s.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Dans la section Confidentialit&eacute;, cliquez sur le bouton
                  Param&egrave;tres de contenu.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour activer les cookies, s&eacute;lectionnez Autoriser le
                  stockage des donn&eacute;es locales dans la section Cookies.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour d&eacute;sactiver les cookies, s&eacute;lectionnez
                  Interdire &agrave; tous les sites de stocker des
                  donn&eacute;es dans la section Cookies.
                </span>
              </li>
            </ul>
            <ul class="c10 lst-kix_list_1-0">
              <li class="c5 li-bullet-0">
                <span class="c3">Sous Safari :</span>
              </li>
            </ul>
            <ul class="c10 lst-kix_list_2-0">
              <li class="c8 li-bullet-0">
                <span class="c3">
                  S&eacute;lectionnez l&rsquo;onglet Pr&eacute;f&eacute;rences,
                  puis cliquez sur Confidentialit&eacute;.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Dans la section Bloquer les cookies, indiquez si Safari doit
                  accepter les cookies du Site et dans quelles circonstances.
                </span>
              </li>
              <li class="c8 li-bullet-0">
                <span class="c3">
                  Pour obtenir une explication de chaque option, cliquez sur
                  D&eacute;tails.
                </span>
              </li>
              <li class="c1 c15 li-bullet-0">
                <span class="c3">
                  Pour savoir quels sites Web stockent des cookies sur votre
                  ordinateur, cliquez sur D&eacute;tails.
                </span>
              </li>
            </ul>
            <p class="c1">
              <span class="c3">
                Afin de restreindre les cookies des autres navigateurs,
                l&rsquo;Utilisateur doit se rendre sur la page Web officielle du
                navigateur ou du fabricant du p&eacute;riph&eacute;rique ou
                consulter la documentation fournie, et en suivre les
                instructions.
              </span>
            </p>
            <p class="c1">
              <span class="c3">&bull; Sollicitation</span>
            </p>
            <p class="c1">
              <span class="c3">
                Les Utilisateurs acceptent de recevoir les emails, notifications
                et SMS de service (dit &quot;transactionnels&quot;) qui assurent
                le bon d&eacute;roulement du service de r&eacute;servation BAM.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Les utilisateurs peuvent &eacute;galement choisir de recevoir
                les newsletters BAM en donnant leur consentement, au moment de
                l&#39;inscription.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                BAM met &agrave; disposition des utilisateurs les moyens de
                retirer leur consentement, dans ce m&ecirc;me espace personnel
                ou en utilisant les liens de d&eacute;sabonnement
                pr&eacute;sents dans les emails envoy&eacute;s.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Conform&eacute;ment &agrave; l&rsquo;article L.34-5 du Code des
                Postes et des communications &eacute;lectroniques, BAM peut
                &eacute;galement envoyer des offres analogues aux achats que les
                clients ont d&eacute;j&agrave; effectu&eacute;s sur le site ou
                sur l&#39;application BAM.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Les clients peuvent toutefois s&#39;y opposer en utilisant le
                lien de d&eacute;sabonnement qui se trouve en bas des emails
                re&ccedil;us.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Pour g&eacute;rer le consentement li&eacute; aux
                &quot;notifications&quot;, que peuvent recevoir les porteurs de
                l&#39;application, BAM invite les utilisateurs &agrave;
                directement r&eacute;gler leurs param&egrave;tres, dans leur
                t&eacute;l&eacute;phone.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                7.3. Loi applicable et comp&eacute;tence juridictionnelle
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Tout diff&eacute;rend auquel pourrait donner lieu les
                pr&eacute;sentes conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation, concernant par exemple leur
                ex&eacute;cution, leur interpr&eacute;tation ou leur
                validit&eacute; sera r&eacute;gi au fond par le droit
                fran&ccedil;ais (pour les r&egrave;gles de formes, comme les
                r&egrave;gles de fond). Les Parties tenteront de r&eacute;gler
                amiablement toutes difficult&eacute;s
                d&rsquo;interpr&eacute;tation, d&rsquo;ex&eacute;cution et tous
                diff&eacute;rends relatifs aux pr&eacute;sentes CGU et plus
                g&eacute;n&eacute;ralement &agrave; l&rsquo;acc&egrave;s au Site
                et &agrave; l&rsquo;utilisation des Services.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Dans l&rsquo;hypoth&egrave;se o&ugrave; aucun accord amiable
                n&rsquo;a pu &ecirc;tre trouv&eacute;, les diff&eacute;rends
                rel&egrave;veront de la comp&eacute;tence des tribunaux de
                Paris, et ce y compris pour les proc&eacute;dures
                conservatoires, les proc&eacute;dures d&rsquo;urgence, en cas de
                r&eacute;f&eacute;r&eacute;, d&rsquo;appel en garantie, de
                requ&ecirc;te ou de pluralit&eacute; de d&eacute;fendeurs.
              </span>
            </p>
            <p class="c6">
              <span class="c0">
                7.4. Traduction - Nullit&eacute; partielle - Dissociation
                &ndash; Titres
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                En cas de r&eacute;daction des pr&eacute;sentes CGU en plusieurs
                langues ou de traduction, seule la version fran&ccedil;aise fera
                foi.
              </span>
            </p>
            <p class="c1">
              <span class="c3">
                Dans l&#39;hypoth&egrave;se o&ugrave; une disposition des
                pr&eacute;sentes CGU serait d&eacute;clar&eacute;e nulle,
                ill&eacute;gale, inopposable ou inapplicable d&#39;une
                mani&egrave;re quelconque, la validit&eacute;, la
                l&eacute;galit&eacute; ou l&#39;application des autres
                dispositions des pr&eacute;sentes CGU n&#39;en seraient
                aucunement affect&eacute;es ou alt&eacute;r&eacute;es, les
                autres stipulations des CGU demeurant en vigueur et conservant
                leur plein et entier effet.
              </span>
            </p>
            <p class="c6">
              <span class="c0">7.5. Absence de renonciation</span>
            </p>
            <p class="c1">
              <span class="c3">
                Sauf stipulation contraire pr&eacute;vue &eacute;ventuellement
                dans les pr&eacute;sentes CGU, aucune tol&eacute;rance,
                inaction, abstention ou omission, aucun retard de BAM pour se
                pr&eacute;valoir de l&#39;un quelconque de ses droits
                conform&eacute;ment aux termes des pr&eacute;sentes, ne portera
                atteinte audit droit, ni ne saurait impliquer une renonciation
                pour l&#39;avenir &agrave; se pr&eacute;valoir d&#39;un tel
                droit. Au contraire, ledit droit demeurera pleinement en
                vigueur.
              </span>
            </p>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default CGUClient;
