import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/fr_FR";
import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import BlogAdmin from "./BlogAdmin";
import HistoriquePaiemenet from "./HistoriquePaiement";
import Home from "./Home";
import Parametres from "./Parametres";
import Prestations from "./Prestations";

const NavAdmin = ({ location, ...props }) => {
  return (
    <Switch>
      <ConfigProvider locale={locale}>
        <PrivateRoute exact path="/espaceadmin/">
          <Home />
        </PrivateRoute>
        <PrivateRoute exact path="/espaceadmin/prestations">
          <Prestations />
        </PrivateRoute>
        <PrivateRoute exact path="/espaceadmin/paiement">
          <HistoriquePaiemenet />
        </PrivateRoute>
        <PrivateRoute exact path="/espaceadmin/settings">
          <Parametres />
        </PrivateRoute>
        <PrivateRoute exact path="/espaceadmin/blog">
          <BlogAdmin />
        </PrivateRoute>
      </ConfigProvider>
    </Switch>
  );
};
export default NavAdmin;
