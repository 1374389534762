import { Menu, Button } from "antd";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import agendaIcon from "../../Styles/images/agendaIcon.svg";
import avisIcon from "../../Styles/images/avisIcon.svg";
import clientsIcon from "../../Styles/images/clientsIcon.svg";
import entrepriseIcon from "../../Styles/images/entrepriseIcon.svg";
import homeIcon from "../../Styles/images/homeIcon.svg";
import photoIcon from "../../Styles/images/photoIcon.svg";
import prestationsIcon from "../../Styles/images/prestationsIcon.svg";
import reservationsIcon from "../../Styles/images/reservationsIcon.svg";
import history from "../../utils/History";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Paiement from "./Paiement";
import { paiementAbonnement } from "../ProfBeaute/APIs";
import { openNotification } from "../../utils/Utils";
const MenuItem = ({}) => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const [key, setKey] = useState("1");
  const [showPaiement, setShowPaiement] = useState(false);

  useEffect(() => {
    if (null != cookie.load("token")) {
      var decodedHeader = jwt_decode(
        cookie.load("token").replace("Bearer ", "")
      );
      if (decodedHeader.statusPaiement < 1) {
        setShowPaiement(true);
      }
    }

    window.addEventListener("resize", resize);
    switch (location.pathname) {
      case "/espacepro/":
        setKey("1");
        break;
      case "/espacepro/entreprise":
        setKey("2");
        break;
      case "/espacepro/mes-prestations":
        setKey("3");
        break;
      case "/espacepro/avis":
        setKey("4");
        break;

      case "/espacepro/photo":
        setKey("5");
        break;

      case "/espacepro/agenda":
        setKey("6");
        break;
      case "/espacepro/reservations":
        setKey("7");
        break;
      case "/espacepro/clients":
        setKey("8");
        break;

      default:
        break;
    }
  }, []);

  const resize = () => {
    setCollapsed(true);
  };
  const checkout = (data, typePaiement) => {
    paiementAbonnement({
      ...data,
      type: typePaiement,
    }).then((data) => {
      if (null != data) {
        cookie.save("token", data, { path: "/" });
      }
      setShowPaiement(false);
      openNotification("", "Merci pour votre paiement !");
    });
  };
  return (
    <>
      <Paiement
        isModalVisible={showPaiement}
        cancel={() => {}}
        checkout={(value, typePaiement) => checkout(value, typePaiement)}
      />

      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        selectedKeys={[key]}
        theme="dark"
        style={{
          background: "#4ba48e",
        }}
      >
        <Menu.Item
          onClick={() => {
            setKey("1");
            history.push("/espacepro/");
          }}
          style={{
            background: "none",
          }}
          key="1"
          icon={<img src={homeIcon} />}
        >
          Accueil
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setKey("2");
            history.push("/espacepro/entreprise");
          }}
          style={{
            background: "none",
          }}
          key="2"
          icon={<img src={entrepriseIcon} />}
        >
          Entreprise
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setKey("3");
            history.push("/espacepro/mes-prestations");
          }}
          key="3"
          style={{
            background: "none",
          }}
          icon={<img src={prestationsIcon} />}
        >
          Prestations
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            setKey("4");
            history.push("/espacepro/avis");
          }}
          style={{
            background: "none",
          }}
          icon={<img src={avisIcon} />}
        >
          Avis
        </Menu.Item>
        <Menu.Item
          style={{
            background: "none",
          }}
          key="5"
          onClick={() => {
            setKey("5");
            history.push("/espacepro/photo");
          }}
          icon={<img src={photoIcon} />}
        >
          Photos
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setKey("6");
            history.push("/espacepro/agenda");
          }}
          key="6"
          style={{
            background: "none",
          }}
          icon={<img src={agendaIcon} />}
        >
          Agenda
        </Menu.Item>
        <Menu.Item
          key="7"
          style={{
            background: "none",
          }}
          onClick={() => {
            setKey("7");
            history.push("/espacepro/reservations");
          }}
          icon={<img src={reservationsIcon} />}
        >
          Réservations
        </Menu.Item>
        <Menu.Item
          style={{
            background: "none",
          }}
          onClick={() => {
            setKey("8");
            history.push("/espacepro/clients");
          }}
          key="8"
          icon={<img src={clientsIcon} />}
        >
          Clients
        </Menu.Item>
        <Menu.Item
          style={{
            background: "none",
          }}
          onClick={() => {
            cookie.remove("token", { path: "/" });
            history.push("/");
          }}
          key="9"
          icon={<LogoutOutlined size="large" />}
        >
          Déconnexion
        </Menu.Item>
      </Menu>
    </>
  );
};

export default MenuItem;
