import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import ReactGA from "react-ga";

const About = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="bam--slider-about">
        <Row style={{ paddingTop: "6vh" }}>
          <Col xl={1} md={1} xs={1} />

          <Col xs={20} md={12} xl={14}>
            <div>
              <span className="bam--slider-title">
                BAM réinvente le monde de la beauté
              </span>
            </div>
            <div style={{ marginTop: "2vh" }}>
              <span className="bam--slider-about-title">
                {/* We make booking easy for individuals and events */}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      {/* <Row style={{ marginTop: "2vh", padding: "24px 50px" }}>
        <Col span="20">
          <span className="bam--section-title">Nos valeurs</span>
        </Col>
      </Row> */}
      <Row style={{ marginTop: "2vh" }}>
        <Col xl={1} md={1} xs={1} />

        <Col xs={23} md={23} xl={23}>
          <span className="bam--title-valeur">Qui sommes-nous ?</span>
          <p className="bam--paragraph-valeur">
            Pour des milliers de personnes, il est difficile de trouver le temps
            de prévoir, réserver et se rendre physiquement aux rendez-vous
            beauté pour entretenir sa routine.
          </p>
          <p className="bam--paragraph-valeur">
            BAM est une plateforme de mise en relation entre les professionnels
            indépendants de la beauté et les clients pour la réservation de
            prestations à domicile, qui permet la prise de RDV avec le
            professionnel de votre choix 24h/24 et 7j/7 et à des prix qui vous
            conviennent.
          </p>
          <p className="bam--paragraph-valeur">
            Ici, chez BAM, nous avons à cœur de fournir aux professionnels de la
            beauté et aux clients une plateforme qui répond à l’ensemble de
            leurs besoins.
          </p>
          <p className="bam--paragraph-valeur">
            Pour les professionnels, BAM est un outil de gestion quotidienne de
            leur société. Notre plateforme permet aux indépendants de bénéficier
            d’un site personnalisé avec leurs propres prestations, leurs tarifs,
            leurs photos et leurs avis clients, et de pouvoir gérer leurs
            agendas en toute simplicité.
          </p>
          <p className="bam--paragraph-valeur">
            Pour les clients, BAM permet d’avoir accès à un large choix de
            professionnels qui se déplacent chez vous et de pouvoir sélectionner
            le professionnel de votre choix. Confirmez votre rendez-vous en 2
            clics sans bouger de chez vous, tous les jours, à toute heure.
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: "1vh" }}>
        <Col xl={1} md={1} xs={1} />

        <Col xs={23} md={23} xl={23}>
          <span className="bam--title-valeur">
            Vous souhaitez nous contacter ?
          </span>
          <p className="bam--paragraph-valeur">
            Par mail : contact@bambeaute.com
          </p>
          <p className="bam--paragraph-valeur">
            Par courrier : BAM, 10 rue du Colisée, 75008 Paris, France
          </p>
          <p className="bam--paragraph-valeur">
            Pour toute question, n’hésitez pas à consulter notre
            <a type="link" href="/faq" style={{ marginLeft: "2px" }}>
              FAQ.
            </a>
          </p>
        </Col>
      </Row>

      {/* <div style={{ marginTop: "4vh" }}>
        <div className="bam--mission">
          <Row>
            <Col xl={1} md={1} xs={3}></Col>
            <Col xl={4} md={3} xs={6}>
              <img src={imgMission} />
            </Col>
            <Col xl={4} md={8} xs={2}></Col>
            <Col xl={14} md={12} xs={22}>
              <div style={{ marginTop: "4vh", marginLeft: "1vh" }}>
                <span className="bam--title-mission">Notre mission</span>
                <p className="bam--paragraph-mission">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui,
                  mauris pharetra lectus in velit, risus risus senectus.
                  Adipiscing nisl, egestas amet, pretium cras integer vulputate
                  tristique orci. Blandit eget ligula nunc odio molestie metus.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}
    </>
  );
};

export default About;
