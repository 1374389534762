import { Col, Row } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { FaFacebook } from "react-icons/fa";
import UserStore from "../Datastores/UserStore";

const SocialAuth = ({}) => {
  const responseFacebook = (response) => {
    UserStore.fbAuth({
      facebookId: response.userID,
      email: response.email,
      firstName: response.name.split(" ")[0],
      lastName: response.name.split(" ")[1],
    }).then((data) => {
      if (data.data.success) {
        UserStore.setLoginModalVisible();
      }
    });
  };
  const responseGoogle = (response) => {
    console.log(response);
    UserStore.googleAuth({
      googleId: response.googleId,
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
    }).then((data) => {
      if (data.data.success) {
        UserStore.setLoginModalVisible();
      }
    });
  };
  return (
    <div style={{ marginTop: "3vh" }}>
      <hr className="bam--hr-login" />
      <div style={{ marginTop: "3vh" }}>
        <span className="bam--connect--avec">ou me connecter avec</span>
      </div>
      <Row
        style={{
          textAlign: "center",
          marginTop: "3vh",
        }}
      >
        <Col xl={10} xs={10} md={10}>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID}
            fields="name,email,picture"
            onClick={() => console.log("clicked")}
            callback={(res) => responseFacebook(res)}
            cssClass="bam--facebook--button"
            textButton="FACEBOOK"
            icon={<FaFacebook />}
          />
        </Col>

        <Col xl={10} xs={10} md={10}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_ID}
            buttonText="Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            className="bam--google--auth--btn"
          ></GoogleLogin>
        </Col>
      </Row>
    </div>
  );
};
export default inject("UserStore")(observer(SocialAuth));
