import { Button, Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../Styles/blog.scss";
import blog from "../Styles/images/blog.png";

import { getArticle } from "./ProfBeaute/APIs";
import htmlToDraft from "html-to-draftjs";
import { getDayNameAndDay } from "../utils/Utils";
import history from "../utils/History";
import { useLocation } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import { convertFromHTML, ContentState } from "draft-js";
const Article = () => {
  const location = useLocation();
  const [article, setArticle] = useState(null);

  const [list, setList] = useState([]);

  useEffect(() => {
    getArticle(location.state.id).then((res) => {
      setArticle(res.data);
      console.log(res);
    });
  }, []);
  const getText = (content) => {
    var blocksFromHTML = convertFromHTML(content);
    var x = ContentState.createFromBlockArray(blocksFromHTML);
    return EditorState.createWithContent(content);
  };
  return (
    <>
      <div className="bam--blog--header">
        <Row>
          <Col xl={2} md={2} xs={2}></Col>
          <Col xl={20} md={20} xs={20} style={{ textAlign: "center" }}>
            <div>
              <span className="bam--article-title">
                {null != article && article.title}
              </span>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            textAlign: "center",
            marginTop: "2vh",
            paddingBottom: "2vh",
          }}
        >
          <Col span="24">
            <img
              src={
                null != article &&
                process.env.REACT_APP_BASE_URL + "images/" + article.media
              }
              style={{ width: "100%", maxHeight: "55vh" }}
            />{" "}
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "2vh" }}>
        {null != article && (
          <Row style={{ marginTop: "2vh" }}>
            <Col xl={4} md={4} xs={0} />

            <Col
              xl={16}
              md={18}
              xs={18}
              style={{
                marginLeft: "2vh",
                marginTop: "2vh",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: article.text }} />

              <p className="bam--date-article" style={{ marginTop: "2vh" }}>
                {getDayNameAndDay(article.date)}
              </p>
            </Col>
            <Col xl={4} md={4} xs={4} />
          </Row>
        )}
      </div>
    </>
  );
};

export default Article;
