import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Col,
  Switch,
} from "antd";
import "antd/dist/antd.css";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import UserStore from "../Datastores/UserStore";

const Register = ({ isModalVisible }) => {
  const [form] = Form.useForm();
  const [displayError, setDisplayError] = useState(false);

  const register = () => {
    UserStore.register(form.getFieldsValue()).then((data) => {
      openNotification();
      closeModal();
    });
  };
  const key = "updatable";
  const openNotification = () => {
    notification.success({
      key,
      message: "Inscription Réussie !",
      description: "Bienvenue sur BAM.",
    });
  };
  const closeModal = () => {
    UserStore.setRegisterModalVisible();
  };

  const verifCGU = (rule, value, callback) => {
    if (value != true) {
      callback("");
      setDisplayError(true);
      return;
    }
    setDisplayError(false);
    callback();
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => closeModal()}
        footer={null}
      >
        <div>
          <span className="bam--home-title">Welcome !</span>
        </div>

        <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
          <Form.Item
            name="firstName"
            label="Nom"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "merci d'entrer votre nom",
              },
            ]}
          >
            <Input className="bam--input" placeholder="Tapez votre nom" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Prénom"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "merci d'entrer votre prénom",
              },
            ]}
          >
            <Input className="bam--input" placeholder="Tapez votre prénom" />
          </Form.Item>
          <Form.Item
            name="tel"
            label="Téléphone"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "merci d'entrer votre téléphone",
              },
            ]}
          >
            <Input className="bam--input" placeholder="Tapez votre telephone" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "merci d'entrer votre adresse email",
              },
            ]}
          >
            <Input
              className="bam--input"
              placeholder="Tapez votre adresse email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            className="bam--label-form"
            rules={[
              {
                required: true,
                message: "merci d'entrer votre mot de passe",
              },
            ]}
          >
            <Input.Password
              className="bam--input"
              visibilityToggle={false}
              placeholder="Tapez votre mot de passe"
            />
          </Form.Item>
          <Row>
            <Col span="19">
              <span className="bam--label-form">
                En cochant la case suivante, j’accepte les{" "}
                <a href="/cgu" target="_blank">
                  {" "}
                  Conditions Générales de BAM.
                </a>
              </span>
              {displayError && (
                <div
                  class="ant-form-item-explain ant-form-item-explain-error"
                  style={{ float: "right" }}
                >
                  <div role="alert">Veuillez cocher la case ci-dessus</div>
                </div>
              )}
            </Col>
            <Col span="5">
              <Form.Item
                style={{ float: "right" }}
                rules={[
                  {
                    required: true,
                    message: "",
                    validator: verifCGU,
                  },
                ]}
                name="cgu"
                className="bam--label-form"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Form.Item>
              <Button
                className="bam--login--button"
                htmlType="submit"
                onClick={() => {
                  register();
                }}
              >
                <span className="bam--login--text">Valider</span>
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default inject("UserStore")(observer(Register));
