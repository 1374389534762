import moment from "moment";
import localization from "moment/locale/fr";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import DataTable from "react-data-table-component";
import history from "../../utils/History";
import {
  createAvis,
  getReservationsUser,
  toValidateReservations,
} from "../ProfBeaute/APIs";
import ReactGA from "react-ga";
import { Button, Input, Rate, Modal, Form } from "antd";
import { errorNotification, openNotification } from "../../utils/Utils";

const count = 3;
const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat&noinfo`;
moment.updateLocale("fr", localization);
const Reservations = ({}) => {
  const [data, setData] = useState([]);
  const [avis, setAvis] = useState(-1);
  const [visible, setVisible] = useState(false);
  const [note, setNote] = useState("");
  const [id, setSelectedId] = useState(-1);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (cookie.load("token") == null) {
      history.push("/");
      return;
    }
    getPrestations();
  }, []);
  const getPrestations = () => {
    getReservationsUser().then((res) => {
      setData(res);
    });
  };
  const columns = [
    {
      name: "Prestations",
      selector: "prestation",
      sortable: true,
    },
    {
      name: "Durée",
      selector: "duration",
      sortable: true,
      format: (p) => (
        <span>
          {p.hour > 0 && p.hour + " Heure(s) "}{" "}
          {p.minute > 0 && p.minute + " Minute(s)"}{" "}
        </span>
      ),
    },
    {
      name: "Etat",
      selector: "status",
      sortable: true,
      format: (p) => (
        <span>
          {p.status == 0
            ? "En cours de validation"
            : p.status == 1
            ? "Validé"
            : "Refusé"}
        </span>
      ),
    },

    {
      name: "Date de reservation",
      selector: "date",
      sortable: true,
      format: (row) => moment(row.date).local().format("D MMMM YYYY"),
    },
    {
      cell: (p) => renderButton(p),
    },
  ];

  const saveAvis = () => {
    if (note == "" || avis == -1) {
      errorNotification("Erreur", "Merci de renseigner les deux champs!");
      return;
    }
    createAvis(note, avis, id).then(() => {
      setVisible(false);
    });
  };
  const validerReservations = (id, value) => {
    toValidateReservations(id, value).then((data) => {
      if (data) {
        openNotification("Succès!", "Modifications enregistrées avec succès !");
        getPrestations();
      }
    });
  };

  const renderButton = (p) => {
    {
      return (
        <>
          {p.status != 0 && (
            <>
              <Button
                className="bam--button-carrousel-text"
                onClick={() => {
                  setSelectedId(p.id);
                  setVisible(true);
                }}
              >
                Laisser un avis
              </Button>
            </>
          )}

          {p.status == 0 && (
            <>
              <Button
                id="annuler"
                className="bam--button-carrousel-text"
                onClick={() => {
                  validerReservations(p.id, false);
                }}
                style={{ backgroundColor: "#ff4d4f", marginLeft: "1vh" }}
              >
                Annuler
              </Button>
            </>
          )}
        </>
      );
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    cells: {
      style: {
        fontSize: "1rem",
      },
    },
  };
  const closeModal = () => {
    setSelectedId(-1);
    setNote("");
    setAvis(-1);
    setVisible(false);
  };
  return (
    <>
      <Modal
        title="Laisser un avis"
        visible={visible}
        onOk={() => saveAvis()}
        onCancel={() => closeModal()}
      >
        <Form>
          <Form.Item label="Avis">
            <Rate onChange={(e) => setAvis(e)} />
          </Form.Item>
          <Form.Item label="Note">
            <Input.TextArea onChange={(e) => setNote(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
      <DataTable
        pagination={true}
        paginationPerPage={10}
        striped
        customStyles={customStyles}
        title="Réservations à venir"
        columns={columns}
        data={data.filter((reservation) => reservation.status === 0)}
        fixedHeader
        noDataComponent={<span>pas de réservations trouvées</span>}
      />

      <DataTable
        pagination={true}
        paginationPerPage={10}
        striped
        customStyles={customStyles}
        title="Réservations"
        columns={columns}
        data={data.filter((reservation) => reservation.status != 0)}
        fixedHeader
        noDataComponent={<span>pas de réservations trouvées</span>}
      />
    </>
  );
};

export default Reservations;
