import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Switch,
  Tabs,
} from "antd";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Geocode from "react-geocode";
import LoadingScreen from "react-loading-screen";
import HourMinute from "../../components/HourMinute";
import "../../Styles/entreprise.scss";
import { openNotification } from "../../utils/Utils";
import {
  paiementAbonnement,
  toGetInfosBeautyPro,
  toSaveInfoBeautyPro,
} from "../ProfBeaute/APIs";
import Paiement from "./Paiement";
import Autocomplete from "react-google-autocomplete";
import ReactGA from "react-ga";

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;
function callback(key) {}

const Entreprise = ({}) => {
  const [department, setDepartment] = useState("");
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(true);
  const [displayMap, setDisplayMap] = useState(false);

  const [indisponibilities, setIndisponibilities] = useState([]);
  const [showPaiementButton, setShowPaiementButton] = useState(true);
  const [latLong, setLatLong] = useState({
    lat: -1,
    lng: -1,
  });
  const [mobile, setMobile] = useState(false);

  const [tabs, setTabs] = useState([
    {
      name: "L",
      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
    {
      name: "M",
      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
    {
      name: "Me",
      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
    {
      name: "J",

      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
    {
      name: "V",

      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
    {
      name: "S",

      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
    {
      name: "D",

      start: { m: 0, h: 10 },
      startBreak: { m: 0, h: 12 },
      endBreak: { m: 0, h: 14 },
      end: { m: 0, h: 20 },
      open: true,
    },
  ]);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 900) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getInformations();
  }, []);

  const getInformations = () => {
    toGetInfosBeautyPro().then((res) => {
      if (res.length > 0) {
        form.setFieldsValue(res[0]);
        setDepartment(res[0].adress);

        getLatlng(res[0].adress);
        if (res[0].disponibilite.length > 0) {
          setTabs(JSON.parse(res[0].disponibilite));
        }
      }

      setLoading(false);
    });
  };

  const save = () => {
    var json = form.getFieldsValue();
    json.adress = department;
    if (null == json.displayAdress) {
      json.displayAdress = false;
    }
    form.setFieldsValue(json);
    form.validateFields().then((json) => {
      toSaveInfoBeautyPro({
        ...json,
        confirmationBooking: value,
        disponibilite: JSON.stringify(tabs),
        indisponibilite: JSON.stringify(indisponibilities),
        latlng: JSON.stringify(latLong),
      }).then((res) => {
        openNotification("Succès!", "Modifications enregistrées avec succès !");
      });
    });
  };
  const updateHourMinute = (name, period, h, m) => {
    const l__tabs = [...tabs];
    var index = l__tabs.findIndex((e) => e.name == name);
    l__tabs[index][period].h = h;
    l__tabs[index][period].m = m;
    setTabs(l__tabs);
  };

  const changeChecked = (i) => {
    const l__tabs = [...tabs];
    l__tabs[i].open = !l__tabs[i].open;
    setTabs(l__tabs);
  };

  const addIndisponibility = () => {
    var l__indisponibilities = [...indisponibilities];
    l__indisponibilities.push({ start: new Date(), end: new Date() });
    setIndisponibilities(l__indisponibilities);
  };
  const removeIndisponibility = (x) => {
    var l__indisponibilities = [...indisponibilities];
    l__indisponibilities.splice(x, 1);
    setIndisponibilities(l__indisponibilities);
  };
  const changeIndiponibility = (date, x) => {
    var l__indisponibilities = [...indisponibilities];
    l__indisponibilities[x].start = date[0];
    l__indisponibilities[x].end = date[1];
    setIndisponibilities(l__indisponibilities);
  };

  const getLatlng = (ev) => {
    Geocode.setApiKey("AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0");

    Geocode.setLanguage("fr");

    Geocode.setRegion("fr");

    Geocode.setLocationType("ROOFTOP");
    Geocode.fromAddress(ev).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatLong({
          lat: lat,
          lng: lng,
        });
        setDisplayMap(false);
        setDisplayMap(true);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: {
        lat: latLong.lat,
        lng: latLong.lng,
      },
      map,
      title: "Hello World!",
    });
  };

  return (
    <>
      <Form
        form={form}
        layout={"vertical"}
        style={{ marginLeft: mobile ? "0vh" : "4vh" }}
      >
        <LoadingScreen
          loading={loading}
          bgColor="#f5f8fa"
          spinnerColor="#f87d6b"
          textColor="#2D2926"
        >
          <Row>
            <Col xl={4} md={4} xs={0} />
            <Col xl={16} md={16} xs={24}>
              <Card className="bam--card-entrprise">
                <Row style={{ marginTop: "4vh" }}>
                  <span className="bam--section-title">
                    Informations de l'entreprise
                  </span>
                </Row>

                <Row style={{ marginTop: "4vh" }}>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      name="companyName"
                      label="Nom de l'entreprise"
                      className="bam--label-form"
                    >
                      <Input
                        placeholder="Tapez le nom de votre entreprise"
                        className="bam--input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "merci d'entrer votre email",
                        },
                      ]}
                      name="email"
                      label="Email"
                      className="bam--label-form"
                    >
                      <Input
                        className="bam--input"
                        placeholder="Tapez votre adresse email"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "merci d'entrer votre téléphone",
                        },
                      ]}
                      name="tel"
                      label="Téléphone"
                      className="bam--label-form"
                    >
                      <Input
                        className="bam--input"
                        type="tel"
                        placeholder="Tapez votre téléphone"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      label="Adresse"
                      name="adress"
                      className="bam--label-form"
                      rules={[
                        {
                          required: true,
                          message: "merci d'entrer votre adresse",
                        },
                      ]}
                    >
                      <Autocomplete
                        apiKey={"AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0"}
                        className="bam--input"
                        options={{ types: ["establishment", "geocode"] }}
                        style={{ width: "100%" }}
                        value={department}
                        onPlaceSelected={(place) => {
                          getLatlng(place.formatted_address);
                          setDepartment(place.formatted_address);
                        }}
                      />
                      <Form.Item
                        style={{ marginTop: "2vh" }}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        name="displayAdress"
                        label="Afficher l'adresse dans mon profil"
                        className="bam--label-form"
                      >
                        {!loading && (
                          <Switch
                            defaultChecked={form.getFieldValue("displayAdress")}
                            checkedChildren="oui"
                            unCheckedChildren="non"
                          />
                        )}
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col xl={1} md={1} xs={0} />
                  {/* <Col xl={9} md={9} xs={24}>
                    <Form.Item
                      label="Map"
                      name="map"
                      className="bam--label-form"
                    >
                      <div
                        style={{
                          height: "30vh",
                          borderRadius: "28px",
                        }}
                      >
                        {displayMap && (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0",
                            }}
                            bounds={latLong}
                            style={{
                              borderRadius: "28px",
                            }}
                            borderRadius="28px"
                            defaultCenter={{
                              lat: latLong.lat,
                              lng: latLong.lng,
                            }}
                            style={{ borderRadius: "30px" }}
                            defaultZoom={15}
                            onGoogleApiLoaded={({ map, maps }) =>
                              renderMarkers(map, maps)
                            }
                          ></GoogleMapReact>
                        )}
                      </div>
                    </Form.Item>
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      name="etablissement"
                      label="Type d'activité"
                      className="bam--label-form"
                      rules={[
                        {
                          required: true,
                          message: "merci d'entrer le type d’établissment",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Choisissez un type d’établissment"
                        className="bam--input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "merci de renseigner le siret",
                        },
                      ]}
                      name="siret"
                      label="Siret"
                      className="bam--label-form"
                    >
                      <Input
                        placeholder="XXX-XXXX-XXXXXX"
                        className="bam--input"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message:
                            "merci d'entrer la description de l'entreprise",
                        },
                      ]}
                      name="description"
                      label="Description de l'entreprise"
                      className="bam--label-form"
                    >
                      <Input.TextArea
                        placeholder="Tapez la description de votre entreprise"
                        className="bam--input"
                        rows="4"
                      />
                    </Form.Item>
                  </Col>
                </Row>*/}
                <Row>
                  <Col span="24">
                    <Form.Item
                      name="mobility"
                      rules={[
                        {
                          required: true,
                          message: "merci d'entrer votre mobilité",
                        },
                      ]}
                      label={
                        <div style={{ display: mobile && "block" }}>
                          Mobilité
                          <span
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            (distance maximale à laquelle vous acceptez de vous
                            déplacer pour effectuer vos prestations)
                          </span>
                        </div>
                      }
                      className="bam--label-form"
                    >
                      <Input
                        style={{ width: "15vh" }}
                        type="number"
                        placeholder="0"
                        className="bam--input"
                        suffix="KM"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* 
                <Row>
                  <Col span="9">
                    <Form.Item
                      name="fb"
                      label="Facebook"
                      className="bam--label-form"
                    >
                      <Input
                        placeholder="Coller votre lien Facebook"
                        className="bam--input"
                      />
                    </Form.Item>
                  </Col>
                  <Col span="2" />

                  <Col span="9">
                    <Form.Item
                      name="insta"
                      label="Instagram"
                      className="bam--label-form"
                    >
                      <Input
                        placeholder="Coller votre lien Instagram  "
                        className="bam--input"
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col xl={20} md={20} xs={24}>
                    <Form.Item
                      name="confirmationBooking"
                      label=""
                      className="bam--label-form"
                    >
                      <span className="bam--label-form">
                        Confirmation automatique des réservations
                      </span>
                      <Radio.Group
                        onChange={onChange}
                        value={value}
                        style={{ marginLeft: "2vh" }}
                      >
                        <Radio value={1}>Oui</Radio>
                        <Radio value={2}>Non</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row>
                  <span className="bam--section-title">Horaires</span>
                </Row>
                <Tabs
                  style={{ marginTop: "2vh" }}
                  onChange={callback}
                  type="card"
                  centered
                  className="bam--becamepro-tabs"
                  tabBarGutter={60}
                >
                  {tabs.map((element, i) => (
                    <TabPane tab={element.name} key={i}>
                      <Row>
                        <Col span={20} offset={2}>
                          <hr className="bam--hr-became" />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "5vh" }}>
                        <Col span={20} offset={2}>
                          <div style={{ textAlign: "center" }}>
                            <Switch
                              size={"large"}
                              checkedChildren="Ouvert"
                              unCheckedChildren="Fermé"
                              checked={element.open}
                              onChange={(c) => changeChecked(i)}
                            />
                          </div>
                          <Row
                            style={{
                              marginTop: "3vh",
                            }}
                          >
                            <Col xl={16} md={12} xs={10}>
                              <span className="bam--label-form">Début</span>
                            </Col>
                            <Col
                              xl={8}
                              md={12}
                              xs={14}
                              style={{ float: "right" }}
                            >
                              <HourMinute
                                updateHourMinute={(h, m) =>
                                  updateHourMinute(element.name, "start", h, m)
                                }
                                open={element.open}
                                m={element.start.m}
                                h={element.start.h}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* <Row style={{ marginTop: "5vh" }}>
                        <Col span={20} offset={2}>
                          <Row>
                            <Col xl={16} md={12} xs={10}>
                              <span className="bam--label-form">
                                Début pause déjeuner
                              </span>
                            </Col>
                            <Col
                              xl={8}
                              md={12}
                              xs={14}
                              style={{ float: "right" }}
                            >
                              <HourMinute
                                open={element.open}
                                updateHourMinute={(h, m) =>
                                  updateHourMinute(
                                    element.name,
                                    "startBreak",
                                    h,
                                    m
                                  )
                                }
                                m={element.startBreak.m}
                                h={element.startBreak.h}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "5vh" }}>
                        <Col span={20} offset={2}>
                          <Row>
                            <Col xl={16} md={12} xs={10}>
                              <span className="bam--label-form">
                                Fin pause déjeuner
                              </span>
                            </Col>
                            <Col
                              xl={8}
                              md={12}
                              xs={14}
                              style={{ float: "right" }}
                            >
                              <HourMinute
                                open={element.open}
                                updateHourMinute={(h, m) =>
                                  updateHourMinute(
                                    element.name,
                                    "endBreak",
                                    h,
                                    m
                                  )
                                }
                                m={element.endBreak.m}
                                h={element.endBreak.h}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                     */}{" "}
                      <Row style={{ marginTop: "5vh" }}>
                        <Col span={20} offset={2}>
                          <Row>
                            <Col xl={16} md={12} xs={10}>
                              <span className="bam--label-form">Fin</span>
                            </Col>
                            <Col
                              xl={8}
                              md={12}
                              xs={14}
                              style={{ float: "right" }}
                            >
                              <HourMinute
                                open={element.open}
                                updateHourMinute={(h, m) =>
                                  updateHourMinute(element.name, "end", h, m)
                                }
                                m={element.end.m}
                                h={element.end.h}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
                </Tabs>

                <Row style={{ marginTop: "2vh" }}>
                  <span
                    className="bam--section-title"
                    style={{ marginTop: "5px" }}
                  >
                    Indisponibilité
                  </span>
                  <img
                    style={{ marginLeft: "4vh" }}
                    onClick={() => addIndisponibility()}
                    src={require("../../Styles/images/plus-small.svg")}
                    className="bam--cursor"
                  />
                </Row>
                <Row style={{ marginTop: "5vh" }}>
                  <Col span={18} offset={2}>
                    {indisponibilities.map((e, x) => (
                      <Row style={{ marginTop: "2vh" }} key={x}>
                        <Col span={20}>
                          <RangePicker
                            onChange={(ev, date) => {
                              changeIndiponibility(date, x);
                            }}
                            value={[moment(e.start), moment(e.end)]}
                          />
                        </Col>
                        <Col span={4}>
                          {" "}
                          <img
                            style={{ marginLeft: "4vh" }}
                            onClick={() => removeIndisponibility(x)}
                            src={require("../../Styles/images/minus.svg")}
                            className="bam--cursor"
                          />{" "}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>

                <Button
                  style={{
                    marginTop: "2vh",
                    float: "right",
                  }}
                  className="bam--button-carrousel-text"
                  htmlType="submit"
                  onClick={save}
                >
                  Enregistrer
                </Button>
                {/* {showPaiementButton && (
                  <Button
                    style={{
                      marginTop: "2vh",
                      float: "right",
                      marginRight: "2vh",
                    }}
                    className="bam--button-carrousel-text"
                    htmlType="submit"
                    onClick={() => setShowPaiement(true)}
                  >
                    Régler mon abonnement
                  </Button>
                )} */}
              </Card>
            </Col>
            <Col xl={4} md={4} xs={0} />
          </Row>
        </LoadingScreen>
      </Form>
    </>
  );
};
export default Entreprise;
