import jwt_decode from "jwt-decode";
import React from "react";
import cookie from "react-cookies";
import { Redirect, Route } from "react-router-dom";
function PrivateRoute({ role, admin, children, user, layout, ...rest }) {
  const getRoles = () => {
    if (cookie.load("token")) {
      var decodedHeader = jwt_decode(
        cookie.load("token").replace("Bearer ", "")
      );

      return decodedHeader.role;
    }
  };
  return (
    <Route
      {...rest}
      render={({ location }) => {
        {
        }
        return cookie.load("token") &&
          getRoles() != null &&
          ((getRoles().includes("ROLE_BEAUTY_PRO") &&
            location.pathname.includes("/espacepro/")) ||
            (getRoles().includes("ROLE_ADMIN") &&
              location.pathname.includes("/espaceadmin/")) ||
            (getRoles().includes("ROLE_USER") &&
              location.pathname.includes("/espaceuser"))) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoute;
