import { Card, Layout, Row, Button } from "antd";
import React, { useState, useLayoutEffect } from "react";
import "../../Styles/left-layout.scss";
import history from "../../utils/History";
import MenuItem from "./MenuItem";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
const { Sider, Header, Content } = Layout;

const LeftNavLayout = ({ children, location }) => {
  const [menuCollapsed, setMenuState] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 900) {
        setMenuState(false);
      } else {
        setMenuState(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getName = (children) => {
    if (children.props.location.pathname == "/espacepro/entreprise") {
      return "Entreprise";
    }
    if (children.props.location.pathname == "/espacepro/entreprise") {
      return "Entreprise";
    }
    if (children.props.location.pathname == "/espacepro/mes-prestations") {
      return "Prestations";
    }
    if (children.props.location.pathname == "/espacepro/avis") {
      return "Avis";
    }
    if (children.props.location.pathname == "/espacepro/photo") {
      return "Photos";
    }
    if (children.props.location.pathname == "/espacepro/agenda") {
      return "Agenda";
    }
    if (children.props.location.pathname == "/espacepro/reservations") {
      return "Réservations";
    }
    if (children.props.location.pathname == "/espacepro/clients") {
      return "Clients";
    }
    return "Accueil";
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="bam--left--layout"
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
      >
        <div className="logo" style={{ textAlign: "center" }}>
          <img
            style={{
              cursor: "pointer",
              width: "8vh",
              marginTop: "2vh",
              marginBottom: "2vh",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src={require("../../Styles/images/bam-blanc.png")}
            onClick={() => history.push("/")}
          />
        </div>

        <MenuItem mode="inline"></MenuItem>
      </Sider>

      <Layout>
        <Card style={{ backgroundColor: "#fff" }}>
          <Row>
            <span className="bam--espace-pro-title">{getName(children)}</span>
          </Row>
        </Card>

        <Content
          style={{
            padding: 4,
            margin: 0,
            minHeight: 280,
            backgroundColor: "#F7F7F8",
          }}
        >
          <div
            style={{
              marginTop: "2vh",
              padding: menuCollapsed ? "0px" : "24px",
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LeftNavLayout;
