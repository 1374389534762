import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getHistoryPaiement } from "../ProfBeaute/APIs";
import ReactGA from "react-ga";

const columns = [
  {
    name: "Prix",
    selector: "prix",
    sortable: true,
  },
  {
    name: "Type d'abonnement",
    selector: "type",
    sortable: true,
    format: (p) => <span>{p.type == "m" ? "Mensuelle" : "Annuelle"}</span>,
  },
];
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "1.2rem",
    },
  },
  cells: {
    style: {
      fontSize: "1rem",
    },
  },
};

const HistoryPaiement = ({}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getPaiement();
  }, []);
  const getPaiement = () => {
    getHistoryPaiement().then((data) => {
      setData(data);
    });
  };
  return (
    <DataTable
      striped
      customStyles={customStyles}
      title="Liste de paiement"
      columns={columns}
      data={data}
      fixedHeader
      noDataComponent={<span>Pas encore de paiement</span>}
    />
  );
};
export default HistoryPaiement;
