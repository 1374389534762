import React, { useEffect, useLayoutEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Row, Col, Input, TreeSelect, Card, DatePicker } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Geocode from "react-geocode";
import { getAllMedias, getActifPrestations, search } from "./ProfBeaute/APIs";
import history from "../utils/History";
import { getDistanceFromLatLonInKm } from "../utils/Utils";
import LoadingScreen from "react-loading-screen";
import Autocomplete from "react-google-autocomplete";
import searchImg from "../Styles/images/search.svg";
import ReactGA from "react-ga";

const { TreeNode } = TreeSelect;

const Results = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [prestations, setPrestations] = useState([]);
  const [date, setDate] = useState(-1);
  const [department, setDepartment] = useState(-1);
  const [selectedId, setSelectedId] = useState(-1);
  const [category, setCategory] = useState(false);
  const [value, setValue] = useState("");
  const [openMap, setMap] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [list, setList] = useState([]);
  const [latLong, setLatLong] = useState({
    lat: -1,
    lng: -1,
  });
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 900) {
        setMap(true);
        setMobile(false);
      } else {
        setMap(false);
        setMobile(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getActifPrestations().then((res) => {
      setPrestations(res);
      setValue(
        location.state.category ? "parent" + selectedId : "child" + selectedId
      );
    });

    if (null != location.state) {
      setSelectedId(location.state.selectedId);
      setDepartment(location.state.department);
      setDate(location.state.date);
      setCategory(location.state.category);

      getLatlng(
        location.state.department,
        location.state.selectedId,
        location.state.category
      );
    } else {
      history.push("/");
    }
  }, []);
  const toGetResultsFromPage = () => {
    setLoading(true);

    search({
      prestationId: selectedId,
      department: department,
      category: category,
    }).then((res) => {
      var results = [...res];

      for (let index = 0; index < results.length; index++) {
        if (
          null != results[index].infos[0].latlng &&
          results[index].infos[0].latlng.length != 0
        ) {
          var lat1 = JSON.parse(results[index].infos[0].latlng).lat;
          var lng1 = JSON.parse(results[index].infos[0].latlng).lng;
          results[index].distance = getDistanceFromLatLonInKm(
            latLong.lat,
            latLong.lng,
            lat1,
            lng1
          );
        }
      }

      results.sort((a, b) => (a.distance > b.distance ? 1 : -1));
      results = results.filter((a) => a.distance < a.infos[0].mobility);
      var ids = [];
      results.forEach((e) => {
        ids.push(e.id);
      });
      ids = Array.from(new Set(ids));
      var l__results = [];
      ids.forEach((x) => {
        var p = [];
        results.forEach((element) => {
          if (element.id == x) {
            p.push(element.prestationInfo);
          }
        });

        var medias = results.filter((el) => el.id == x)[0].medias;
        var infos = results.filter((el) => el.id == x)[0].infos;
        var avis = results.filter((el) => el.id == x)[0].avis;
        var distance = results.filter((el) => el.id == x)[0].distance;
        l__results.push({
          prestations: p,
          medias: medias,
          infos: infos,
          avis: avis,
          distance: distance,
        });
      });
      setList(l__results);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  const toGetResults = (
    lat,
    lng,
    i__selectedId,
    i__category,
    i__department
  ) => {
    setLoading(true);
    setLatLong({
      lat: lat,
      lng: lng,
    });
    search({
      prestationId: i__selectedId,
      department: i__department,
      category: i__category,
    }).then((res) => {
      var results = [...res];

      for (let index = 0; index < results.length; index++) {
        if (
          null != results[index].infos[0].latlng &&
          results[index].infos[0].latlng.length != 0
        ) {
          var lat1 = JSON.parse(results[index].infos[0].latlng).lat;
          var lng1 = JSON.parse(results[index].infos[0].latlng).lng;
          results[index].distance = getDistanceFromLatLonInKm(
            lat,
            lng,
            lat1,
            lng1
          );
        }
      }

      results.sort((a, b) => (a.distance > b.distance ? 1 : -1));
      results = results.filter((a) => a.distance < a.infos[0].mobility);
      var ids = [];
      results.forEach((e) => {
        ids.push(e.id);
      });
      ids = Array.from(new Set(ids));
      var l__results = [];
      ids.forEach((x) => {
        var p = [];
        results.forEach((element) => {
          if (element.id == x) {
            p.push(element.prestationInfo);
          }
        });

        var medias = results.filter((el) => el.id == x)[0].medias;
        var infos = results.filter((el) => el.id == x)[0].infos;
        var avis = results.filter((el) => el.id == x)[0].avis;
        var distance = results.filter((el) => el.id == x)[0].distance;
        l__results.push({
          prestations: p,
          medias: medias,
          infos: infos,
          avis: avis,
          distance: distance,
        });
      });
      setList(l__results);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };
  const onChange = (e) => {
    var index = 0;
    if (e.includes("child")) {
      var i = e.indexOf("$") + 1;
      index = e.substring(i);
      setCategory(false);
    } else {
      var i = e.indexOf("$") + 1;
      index = e.substring(i);
      setCategory(true);
    }
    setSelectedId(index);
  };
  const getAvatar = (medias) => {
    try {
      var name = medias.filter((e) => e.avatar == 1)[0].name;
      return name;
    } catch (error) {
      return "profile.jpeg";
    }
  };
  const getLatlng = async (ev, selectedId, category) => {
    setLoading(true);
    Geocode.setApiKey("AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0");

    Geocode.setLanguage("fr");

    Geocode.setRegion("fr");

    Geocode.setLocationType("ROOFTOP");
    await Geocode.fromAddress(ev).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        toGetResults(lat, lng, selectedId, category, ev);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const getAvis = (avis) => {
    if (avis.length == 0) {
      return 0;
    }
    var sum = avis.reduce((a, b) => a + b.vote, 0);
    return Math.round(sum / avis.length);
  };
  const renderMarkers = (map, maps) => {
    const image = {
      url: require("../Styles/images/marker.svg"),

      origin: new maps.Point(0, 0),

      anchor: new maps.Point(0, 32),
    };

    list.forEach((element) => {
      let marker = new maps.Marker({
        position: {
          lat: JSON.parse(element.infos[0].latlng).lat,
          lng: JSON.parse(element.infos[0].latlng).lng,
        },
        map,
        icon: image,
        animation: maps.Animation.DROP,

        title: element.infos[0].companyName,
      });
    });
  };

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f5f8fa"
      spinnerColor="#f87d6b"
      textColor="#2D2926"
    >
      <Row>
        <Col xl={1} md={1} xs={0} />
        <Col xl={13} md={13} xs={24}>
          <Row>
            <Col xs={24} xl="12">
              <Card className="bam--card-home">
                <Row>
                  <Col xl={7} md={7} xs={24} className="bam--border-right">
                    <span className="bam--reservation-title">Prestations</span>

                    <p>
                      <span className="bam--reservation-description">
                        <TreeSelect
                          showSearch
                          style={{ width: "95%" }}
                          // value={value}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder="Que cherchez vous?"
                          allowClear
                          onChange={onChange}
                        >
                          {prestations.map((p, i) => (
                            <TreeNode
                              value={p.name + "parent$" + p.id}
                              title={p.name}
                            >
                              {p.list.map((el) => (
                                <TreeNode
                                  value={el.name + "child$" + el.id}
                                  title={el.name}
                                />
                              ))}
                            </TreeNode>
                          ))}
                        </TreeSelect>
                      </span>
                    </p>
                  </Col>
                  <Col xl={7} md={7} xs={24} className="bam--border-right">
                    <span className="bam--home-left-margin">
                      <span className="bam--reservation-title">Lieu</span>
                    </span>
                    <p className="bam--home-left-margin">
                      <Autocomplete
                        apiKey={"AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0"}
                        placeholder="A quelle adresse ?"
                        className="ant-input"
                        options={{ types: ["establishment", "geocode"] }}
                        style={{ width: "95%" }}
                        onPlaceSelected={(place) => {
                          setDepartment(place.formatted_address);
                        }}
                      />
                    </p>
                  </Col>

                  <Col xl={7} md={7} xs={24}>
                    <span className="bam--home-left-margin">
                      <span className="bam--reservation-title">Date</span>
                    </span>
                    <p className="bam--home-left-margin">
                      <span className="bam--reservation-description">
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          suffixIcon={null}
                          onChange={(e, date) => setDate(date)}
                          style={{ width: "95%" }}
                          placeholder="Choisissez votre date"
                        />
                      </span>
                    </p>
                  </Col>
                  <Col xl={3} md={3} xs={24} style={{ textAlign: "right" }}>
                    <p style={{ width: "95%", paddingTop: "-10px" }}>
                      <img
                        src={searchImg}
                        className="bam-img-search"
                        onClick={() => toGetResultsFromPage()}
                      />
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row justify="end" style={{ marginLeft: "2vh", marginTop: "2vh" }}>
            {/* <Col span="18">
              <Button className="bam--button-choice">Nails salons</Button>
              <Button className="bam--button-choice">Acrylic</Button>
              <Button className="bam--button-choice">Pedicure</Button>
              <Button className="bam--button-choice">Massage</Button>
            </Col> */}
            <Col span="6">
              <Row justify="end" onClick={() => setMap(!openMap)}>
                <a className="bam--hide-text">
                  {openMap ? "Masquer la map" : "Afficher la map"}
                </a>
                <img src={require("../Styles/images/hidemapicon.png")} />
              </Row>
            </Col>
          </Row>
          {mobile && (
            <Col xl={7} md={7} xs={24}>
              {openMap && (
                <div
                  style={{
                    height: "80vh",
                    borderRadius: "28px",
                  }}
                >
                  {!loading && (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0",
                      }}
                      defaultZoom={12}
                      defaultCenter={{
                        lat: latLong.lat,
                        lng: latLong.lng,
                      }}
                      onGoogleApiLoaded={({ map, maps }) =>
                        renderMarkers(map, maps)
                      }
                    ></GoogleMapReact>
                  )}
                </div>
              )}
            </Col>
          )}

          <div style={{ marginTop: "2vh" }}>
            {/* <span className="bam--profile-name"> Nail Salons in Paris</span> */}
            <span className="bam--nb-results">
              {" "}
              {list.length} résultats de recherche
            </span>
          </div>
          {list.map((e, i) => (
            <div className="bam--item-result-parent">
              <Row
                style={{
                  marginTop: "2vh",
                  cursor: "pointer",
                  borderBottom: "1px solid #E5E5E5",
                }}
                onClick={() =>
                  history.push({
                    pathname:
                      "/profil/" + e.infos[0].id + "/" + e.infos[0].companyName,
                    state: { id: e.infos[0].id, date: location.state.date },
                  })
                }
              >
                <Col span={openMap ? "7" : "7"} style={{ marginLeft: "2vh" }}>
                  <img
                    className="bam--img-results"
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "images/" +
                      getAvatar(e.medias)
                    }
                  />
                </Col>
                <Col span="1" />
                <Col xl={7} md={7} xs={14}>
                  <p
                    className="bam--results-title"
                    style={{ marginTop: "2vh" }}
                  >
                    {e.infos[0].companyName}
                  </p>
                  <p style={{ marginTop: "10px" }}>
                    <span className="bam--profile-nb-rate">
                      {"à " + Math.round(parseFloat(e.distance) || 0)}km de chez
                      vous
                    </span>
                  </p>
                  {getAvis(e.avis) > 0 && (
                    <p style={{ marginTop: "1vh" }}>
                      <span className="bam--profile-rate">
                        {getAvis(e.avis)}
                      </span>
                      <span
                        className="bam--profile-rate"
                        style={{ marginLeft: "2vh" }}
                      >
                        {getAvis(e.avis) > 0 && (
                          <img src={require("../Styles/images/star.svg")} />
                        )}
                        {getAvis(e.avis) > 1 && (
                          <img
                            style={{ marginLeft: "2px" }}
                            src={require("../Styles/images/star.svg")}
                          />
                        )}
                        {getAvis(e.avis) > 2 && (
                          <img
                            style={{ marginLeft: "2px" }}
                            src={require("../Styles/images/star.svg")}
                          />
                        )}
                        {getAvis(e.avis) > 3 && (
                          <img
                            style={{ marginLeft: "2px" }}
                            src={require("../Styles/images/star.svg")}
                          />
                        )}
                        {getAvis(e.avis) > 4 && (
                          <img
                            style={{ marginLeft: "2px" }}
                            src={require("../Styles/images/star.svg")}
                          />
                        )}
                      </span>
                      <span
                        className="bam--results-description"
                        style={{
                          marginLeft: "1vh",
                          verticalAlign: "text-bottom",
                        }}
                      >
                        <b>({e.avis.length + "  avis"})</b>
                      </span>
                    </p>
                  )}

                  <p className="bam--results-description"></p>
                </Col>
              </Row>
              {e.prestations.map(
                (p, i) =>
                  i < 3 && (
                    <Row
                      className="bam--item-result"
                      style={{ marginLeft: "2vh" }}
                    >
                      <Col span="17">
                        {" "}
                        <p className="bam--results-description">
                          <b>{p.name}</b>
                        </p>
                      </Col>
                      <Col span="7">
                        <p
                          className="bam--results-price"
                          style={{ float: "right", marginRight: "5px" }}
                        >
                          <b>{"à partir de " + p.price + "€"}</b>
                        </p>
                      </Col>
                    </Row>
                  )
              )}
            </div>
          ))}
        </Col>
        <Col xl={1} md={1} xs={1} />
        {!mobile && (
          <Col xl={8} md={8} xs={24}>
            {openMap && (
              <div
                style={{
                  height: "80vh",
                  borderRadius: "28px",
                }}
              >
                {!loading && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0",
                    }}
                    defaultZoom={12}
                    defaultCenter={{
                      lat: latLong.lat,
                      lng: latLong.lng,
                    }}
                    onGoogleApiLoaded={({ map, maps }) =>
                      renderMarkers(map, maps)
                    }
                  ></GoogleMapReact>
                )}
              </div>
            )}
          </Col>
        )}{" "}
      </Row>
    </LoadingScreen>
  );
};

export default Results;
