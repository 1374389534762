import {
  Button,
  Col,
  Input,
  List,
  Row,
  Popconfirm,
  Skeleton,
  Switch,
  Tabs,
} from "antd";

import React from "react";
import {
  changeStatusCategory,
  getPrestationsAll,
  savePrestations,
  getDetails,
  savePrestationsDetail,
  changeStatusPrestations,
  addCategoryAPI,
  toDeletePrestation,
} from "../ProfBeaute/APIs";
import AddCategory from "./AddCategory";
import AddPrestation from "./AddPrestation";
import { DeleteOutlined } from "@ant-design/icons";

export default class Prestations extends React.Component {
  state = {
    initLoading: true,
    loading: false,
    data: [],
    list: [],
    details: [],
    selected: [],
    showPrestations: false,
    isModalVisible: false,
    selectedId: -1,
  };

  componentDidMount() {
    this.getAllPrestations();
    this.getAllDetails();
  }
  getAllPrestations() {
    getPrestationsAll().then((res) => {
      this.setState({
        data: res,
        initLoading: false,
      });
    });
  }
  getAllDetails() {
    getDetails().then((res) => {
      this.setState({ details: res, selected: res });
      return true;
    });
  }
  addPrestations() {
    this.setState({
      showPrestations: true,
    });
  }
  changeStatusCategory(val) {
    this.setState({
      initLoading: true,
    });
    changeStatusCategory({
      id: val.id,
      status: val.active ? false : true,
    }).then((data) => {
      this.getAllPrestations();
      this.setState({
        initLoading: true,
      });
    });
  }

  changeStatusPrestation(val) {
    this.setState({
      initLoading: true,
    });
    changeStatusPrestations({
      id: val.id,
      status: val.active ? false : true,
    }).then((data) => {
      getDetails().then((res) => {
        this.setState({ details: res }, () => {
          this.onChange(this.state.selectedId);
          this.setState({
            initLoading: false,
          });
        });
      });
    });
  }

  save(value) {
    addCategoryAPI(value).then((data) => {
      this.getAllPrestations();
      this.setState({
        showPrestations: false,
      });
    });
  }
  savePrestation(value) {
    savePrestationsDetail({
      id: this.state.selectedId,
      name: value.name,
      category: value.categoryId,
    }).then((res) => {
      this.setState({
        isModalVisible: false,
      });
    });
  }

  onChange(e) {
    // this.setState({
    //   selected: this.state.details.filter((el) => el.categoryId == e),
    //   selectedId: e,
    // });
  }
  deletePrestation(item) {
    toDeletePrestation(item.id).then(() => {
      this.getAllPrestations();
      this.getAllDetails();
    });
  }
  render() {
    const { initLoading, loading, list } = this.state;

    return (
      <>
        <Tabs>
          <Tabs.TabPane key="1" tab="Catégories">
            <Row>
              <Col span="24">
                <Button
                  style={{
                    marginTop: "2vh",
                    float: "right",
                    marginRight: "",
                  }}
                  className="bam--button-carrousel-text"
                  htmlType="submit"
                  onClick={() => this.addPrestations()}
                >
                  {" "}
                  Ajouter
                </Button>
              </Col>
            </Row>
            <List
              className="demo-loadmore-list"
              loading={initLoading}
              itemLayout="horizontal"
              style={{
                marginTop: "3vh",
              }}
              dataSource={this.state.data}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Switch
                      htmlType="submit"
                      onClick={() => this.changeStatusCategory(item)}
                      checked={item.active}
                    />,
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={item.name}
                      description={
                        item.active === false
                          ? "Status : inactif"
                          : "Status : actif"
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Prestations">
            <Row>
              <Col span="12">
                {/* <Select
                  style={{ width: "60%" }}
                  onChange={(e) => this.onChange(e)}
                  placeholder="Choisir catégorie"
                >
                  {this.state.data.map((p, i) => (
                    <Select.Option value={p.id}>{p.name}</Select.Option>
                  ))}
                </Select> */}
              </Col>
              <Col span="12">
                <Button
                  // disabled={this.state.selectedId == -1}
                  style={{
                    marginTop: "2vh",
                    float: "right",
                  }}
                  className="bam--button-carrousel-text"
                  htmlType="submit"
                  onClick={() => this.setState({ isModalVisible: true })}
                >
                  {" "}
                  Ajouter
                </Button>
              </Col>
            </Row>
            <List
              className="demo-loadmore-list"
              loading={initLoading}
              itemLayout="horizontal"
              style={{
                marginTop: "3vh",
              }}
              dataSource={this.state.selected}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Switch
                      htmlType="submit"
                      onClick={() => this.changeStatusPrestation(item)}
                      checked={item.active}
                    />,
                    <Popconfirm
                      title={`Voulez vous vraiment supprimer cette prestation?`}
                      onConfirm={() => this.deletePrestation(item)}
                      okText="Oui"
                      cancelText="Non"
                      placement="bottom"
                    >
                      <Button danger icon={<DeleteOutlined />}></Button>
                    </Popconfirm>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={
                        <>
                          <span
                            style={{
                              fontSize: "1.2rem",
                              textDecoration: "underline",
                            }}
                          >
                            {item.categoryName + ":"}
                          </span>{" "}
                          {" " + item.name}
                        </>
                      }
                      description={
                        item.active === false
                          ? "Status : inactif"
                          : "Status : actif"
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>

        <AddCategory
          isModalVisible={this.state.showPrestations}
          cancel={() =>
            this.setState({
              showPrestations: false,
            })
          }
          save={(value) => this.save(value)}
        />

        <AddPrestation
          isModalVisible={this.state.isModalVisible}
          cancelPrestation={() =>
            this.setState({
              isModalVisible: false,
            })
          }
          savePrestation={(value) => this.savePrestation(value)}
        />
      </>
    );
  }
}
