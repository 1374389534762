import { Button, Row, Modal, TreeSelect, Form, Input, Col } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactAgenda, guid } from "react-agenda";
import "react-agenda/build/styles.css";
import "react-datetime/css/react-datetime.css";
import HourMinute from "../../components/HourMinute";
import { errorNotification, openNotification } from "../../utils/Utils";
import { EditOutlined } from "@ant-design/icons";
import {
  getActifPrestations,
  toAddReservation,
  toUpdateReservation,
  toGetReservations,
  toDelete,
  getActifPrestationsBeautyPro,
} from "../ProfBeaute/APIs";
import ReactGA from "react-ga";
const { TreeNode } = TreeSelect;

require("moment/locale/fr.js");

var colors = {
  "color-1": "rgba(248,125,107 , 0.2)",
  "color-2": "rgba(248,125,107, 0.2)",
  "color-3": "rgba(248,125,107, 0.2)",
};
var now = new Date();

const Agenda = () => {
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [selectedItem, setSelectedItem] = useState(null);
  const [state, setState] = useState({
    items: items,
    selected: [],
    cellHeight: 20,
    showModal: true,
    locale: "fr",
    rowsPerHour: 4,
    minDate: new Date(now.getFullYear(), 1, 1, 22, 0),
    numberOfDays: 7,
    startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0),
  });
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [prestations, setPrestations] = useState([]);
  const [selectedId, setSelectedId] = useState(-1);
  const [category, setCategory] = useState(false);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const getListOfPrestations = () => {
    getActifPrestationsBeautyPro().then((res) => {
      setPrestations(res);
    });
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getListOfPrestations();
    toGetReservations().then((res) => {
      var l__items = toFormatList(res);
      setState({
        items: l__items,
        selected: [],
        cellHeight: 20,
        showModal: true,
        locale: "fr",
        rowsPerHour: 4,
        numberOfDays: 7,
        minDate: new Date(now.getFullYear(), 0, 1, 22, 0),
        startDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          8,
          0
        ),
      });
    });
  }, []);

  const toFormatList = (res) => {
    var l__items = [];

    res.forEach((element) => {
      var year = moment(element.date).year();
      var month = moment(element.date).month();
      var day = moment(element.date).date();
      var hour = moment(element.date).hour();
      var minute = moment(element.date).minutes();
      var date = moment(element.date).add(element.hour, "hour");

      date = moment(date).add(element.minute, "minutes");
      var endyear = moment(date).year();
      var endmonth = moment(date).month();
      var endday = moment(date).date();
      var endhour = moment(date).hour();
      var endminute = moment(date).minutes();
      l__items.push({
        classes: element.status ? "color-1" : "color-2 color-3",

        _id: element.id,
        name: element.prestation != "" ? element.prestation : element.info,
        startDateTime: new Date(year, month, day, hour, minute),
        endDateTime: new Date(endyear, endmonth, endday, endhour, endminute),
        id: element.id,
        userId: element.userId,
        prestationId: element.prestationId,
        status: element.status,
        prestation: element.prestation,
        clientName: element.name,
        clientPhone: element.phone,
        hour: element.hour,
        minute: element.minute,
        info: element.info,
      });
    });
    return l__items;
  };
  var items = [
    {
      _id: guid(),
      name: "Soins visage",
      startDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        10,
        0
      ),
      endDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        12,
        0
      ),
      classes: "color-1",
    },
    {
      _id: guid(),
      name: "Soins visage",
      startDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        14,
        0
      ),
      endDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        17,
        0
      ),
      classes: "color-1",
    },
    {
      _id: guid(),
      name: "Pédicure!",
      startDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        11,
        0
      ),
      endDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        13,
        0
      ),
      classes: "color-2 color-3",
    },
    {
      _id: guid(),
      name: "Pédicure!",
      startDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2,
        15,
        0
      ),
      endDateTime: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2,
        17,
        0
      ),
      classes: "color-2 color-3",
    },
  ];

  const handleCellSelection = (item) => {
    setDate(item.replace("T", " "));
    setIsOpen(true);
    form.resetFields();
  };
  const handleItemEdit = (item) => {
    setSelectedId(item.prestationId == "" ? 0 : item.prestationId);
    setSelectedItem(item);
    setHour(item.hour);
    setMinute(item.minute);

    formEdit.setFieldsValue({
      prestation: item.prestationId == "" ? "Autre" : item.prestation,
      name: item.clientName,
      infos: item.info,
      phone: item.clientPhone,
    });
    setOpenModal(true);
  };
  const handleRangeSelection = (item) => {
    console.log("handleRangeSelection", item);
  };

  const onChange = (e) => {
    var index = 0;
    if (e.includes("child")) {
      var i = e.indexOf("$") + 1;
      index = e.substring(i);
      setCategory(false);
      setSelectedId(index);
    } else {
      var i = e.indexOf("$") + 1;
      index = e.substring(i);
      if (index == 0) {
        setSelectedId(index);
      }
    }
  };

  const save = () => {
    if (selectedId == -1) {
      errorNotification("Merci de séléctionner une prestation!");
      return;
    }
    var json = form.getFieldsValue();
    json.hour = hour;
    json.minute = minute;
    json.selectedId = parseInt(selectedId);
    json.date = date;
    var l__items = [];

    toAddReservation(json).then(() => {
      toGetReservations().then((res) => {
        var l__items = toFormatList(res);

        setState({
          items: l__items,
          selected: [],
          cellHeight: 30,
          showModal: true,
          locale: "fr",
          rowsPerHour: 2,
          numberOfDays: 7,
          startDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            8,
            0
          ),
          endDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            22,
            0
          ),
        });
      });
      openNotification("Succès!", "Modifications enregistrées avec succès !");
      setIsOpen(false);
    });
  };

  const deleteReservation = () => {
    toDelete(selectedItem.id).then(() => {
      toGetReservations().then((res) => {
        var l__items = toFormatList(res);

        setState({
          items: l__items,
          selected: [],
          cellHeight: 30,
          showModal: true,
          locale: "fr",
          rowsPerHour: 2,
          numberOfDays: 7,
          startDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            8,
            0
          ),
          endDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            22,
            0
          ),
        });
      });
      openNotification("Succès!", "Réservation supprimée avec succès !");
      setIsOpen(false);
      setOpenModal(false);
    });
  };

  const update = () => {
    if (selectedId == -1) {
      errorNotification("Merci de séléctionner une prestation!");
      return;
    }
    var json = formEdit.getFieldsValue();
    json.hour = hour;
    json.id = selectedItem.id;
    json.minute = minute;
    json.selectedId = parseInt(selectedId);
    json.date = selectedItem.startDateTime;
    json.userId = selectedItem.userId;
    toUpdateReservation(json).then(() => {
      toGetReservations().then((res) => {
        var l__items = toFormatList(res);

        setState({
          items: l__items,
          selected: [],
          cellHeight: 30,
          showModal: true,
          locale: "fr",
          rowsPerHour: 2,
          numberOfDays: 7,
          startDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            8,
            0
          ),
          endDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            22,
            0
          ),
        });
      });
      openNotification("Succès!", "Modifications enregistrées avec succès !");
      setIsOpen(false);
      setOpenModal(false);
    });
  };
  const AgendaItem = (props) => {
    return (
      <Row>
        <Col span="22">
          <span className="bam--agenda-item-name"> {props.item.name}</span>
        </Col>
        <Col span="2">
          <Button
            onClick={() => props.edit(props.item)}
            icon={<EditOutlined />}
            style={{
              backgroundColor: "#52c99f",
              color: "#fff",
              borderRadius: "6px",
              marginLeft: "15px",
              float: "right",
            }}
          ></Button>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <ReactAgenda
        disablePrevButton={false}
        cellHeight={state.cellHeight}
        locale={state.locale}
        items={state.items}
        numberOfDays={state.numberOfDays}
        rowsPerHour={state.rowsPerHour}
        itemColors={colors}
        autoScale={false}
        fixedHeader={true}
        minDate={state.minDate}
        onItemEdit={handleItemEdit}
        onCellSelect={handleCellSelection}
        onRangeSelection={handleRangeSelection}
        itemComponent={AgendaItem}
      />
      <Modal
        visible={isOpen}
        title="Ajouter un RDV"
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <Form form={form} layout={"vertical"}>
          <Form.Item
            className="bam--label-form"
            name="prestation"
            label="Prestation"
            rules={[
              {
                required: true,
                message: "merci d'entrer la prestation",
              },
            ]}
          >
            <TreeSelect
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              allowClear
              onChange={onChange}
            >
              {prestations.map((p, i) => (
                <TreeNode value={p.name + "parent$" + p.id} title={p.name}>
                  {p.list.map((el) => (
                    <TreeNode
                      value={el.name + "child$" + el.id}
                      title={el.name}
                    />
                  ))}
                </TreeNode>
              ))}
              <TreeNode value={"autreparent$0"} title="Autre" />
            </TreeSelect>
          </Form.Item>
          {selectedId == 0 && (
            <Form.Item label="Durée" name="duree" className="bam--label-form">
              <HourMinute
                open={true}
                updateHourMinute={(h, m) => {
                  setHour(h);
                  setMinute(m);
                }}
                m={minute}
                h={hour}
              />
            </Form.Item>
          )}
          <Form.Item
            className="bam--label-form"
            name="name"
            label="Nom du client"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="bam--label-form"
            name="phone"
            label="Numéro téléphone du client"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="bam--label-form"
            name="infos"
            label="Informations complémentaires"
          >
            <Input.TextArea />
          </Form.Item>
          <Button
            style={{
              textAlign: "center",
            }}
            className="bam--button-carrousel-text"
            htmlType="submit"
            onClick={save}
          >
            Enregistrer
          </Button>
        </Form>
        <Row style={{ minHeight: "40px" }}></Row>
      </Modal>
      <Modal
        visible={openModal}
        title="Modifier un RDV"
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <Form form={formEdit} layout={"vertical"}>
          <Form.Item
            className="bam--label-form"
            name="prestation"
            label="Prestation"
            rules={[
              {
                required: true,
                message: "merci d'entrer la prestation",
              },
            ]}
          >
            <TreeSelect
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              allowClear
              onChange={onChange}
            >
              {prestations.map((p, i) => (
                <TreeNode value={p.name + "parent$" + p.id} title={p.name}>
                  {p.list.map((el) => (
                    <TreeNode
                      value={el.name + "child$" + el.id}
                      title={el.name}
                    />
                  ))}
                </TreeNode>
              ))}
              <TreeNode value={"autreparent$0"} title="Autre" />
            </TreeSelect>
          </Form.Item>
          {selectedId == 0 && (
            <Form.Item label="Durée" name="duree" className="bam--label-form">
              <HourMinute
                open={true}
                updateHourMinute={(h, m) => {
                  setHour(h);
                  setMinute(m);
                }}
                m={minute}
                h={hour}
              />
            </Form.Item>
          )}
          <Form.Item
            className="bam--label-form"
            name="name"
            label="Nom du client"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="bam--label-form"
            name="phone"
            label="Numéro téléphone du client"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="bam--label-form"
            name="infos"
            label="Informations complémentaires"
          >
            <Input.TextArea />
          </Form.Item>
          <Button
            style={{
              textAlign: "center",
            }}
            className="bam--button-carrousel-text"
            htmlType="submit"
            onClick={update}
          >
            Enregistrer
          </Button>
          <Button
            style={{
              textAlign: "center",
              marginLeft: "1vh",
            }}
            className="bam--button-danger"
            danger
            htmlType="submit"
            onClick={() => deleteReservation()}
          >
            Supprimer
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Agenda;
