import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Col,
  DatePicker,
  InputNumber,
} from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import cookie from "react-cookies";
import history from "../../utils/History";

const Paiement = ({ isModalVisible, cancel, checkout }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("m");

  return (
    <Modal
      width="800px"
      visible={isModalVisible}
      onCancel={() => cancel()}
      footer={null}
      closable={false}
    >
      <Row style={{ marginTop: "2vh" }} justify="center">
        <span className="bam--home-title" style={{ textAlign: "center" }}>
          Votre mois d’essai est arrivé a expiration. <br />
        </span>
      </Row>
      <Row style={{ marginTop: "2vh" }} justify="center">
        <span className="bam--home-title" style={{ fontWeight: "400" }}>
          Afin de continuer à utiliser BAM, veuillez selectionner un abonnement
          :
        </span>
      </Row>
      <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
        <Form.Item name="type" label="" className="bam--label-form">
          <Row>
            <Col span="3"></Col>
            <Col span="9" style={{ textAlign: "center" }}>
              <Radio
                value={"m"}
                checked={value == "m"}
                onClick={() => setValue("m")}
              >
                <span className="bam--paiement-radio"> Mensuel</span>
              </Radio>
            </Col>
            <Col span="9" style={{ textAlign: "center" }}>
              <Radio
                value={"a"}
                checked={value == "a"}
                className="bam--paiement-radio"
                onClick={() => setValue("a")}
              >
                <span className="bam--paiement-radio"> Annuel</span>
              </Radio>
            </Col>

            <Col span="3"></Col>
          </Row>
          <Row style={{ marginTop: "1vh" }}>
            <Col span="3"></Col>
            <Col span="9" style={{ textAlign: "center" }}>
              <span
                className="bam--paiement-price"
                onClick={() => setValue("m")}
              >
                29,99€ TTC / mois
              </span>
              <div style={{ marginTop: "2px", textAlign: "center" }}>
                <span
                  className="bam--paiement-price-second"
                  onClick={() => setValue("m")}
                >
                  sans engagement
                </span>
              </div>
            </Col>
            <Col span="9" style={{ textAlign: "center" }}>
              <span
                className="bam--paiement-price"
                onClick={() => setValue("a")}
              >
                239,99€ TTC / an
              </span>
              <div style={{ marginTop: "2px", textAlign: "center" }}>
                <span
                  className="bam--paiement-price-second"
                  onClick={() => setValue("a")}
                >
                  engagement 1 an
                </span>
              </div>
            </Col>

            <Col span="3"></Col>
          </Row>
        </Form.Item>
        <Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
          <span className="bam--paiement-select">Mode de paiement</span>
        </Row>
        <Form.Item
          name="cardName"
          label="Nom figurant sur la carte"
          className="bam--label-form"
          rules={[{ required: true, message: "Champ obligatoire" }]}
        >
          <Input
            className="bam--input"
            placeholder="Nom figurant sur la carte"
          />
        </Form.Item>
        <Form.Item
          name="cardNumber"
          label="Numéro de carte"
          className="bam--label-form"
          rules={[{ required: true, message: "Champ obligatoire" }]}
        >
          <Input
            controls={false}
            type="number"
            className="bam--input"
            placeholder="Numero de carte"
          />
        </Form.Item>

        <Row>
          <Col span="12">
            <Form.Item
              name="expireDate"
              label="Date de validité"
              className="bam--label-form"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Row>
                <Col span="10">
                  <Form.Item name="mm" label="">
                    <InputNumber
                      placeholder="MM"
                      className="bam--input-number"
                      picker="month"
                      min={1}
                      controls={false}
                      max={12}
                    />
                  </Form.Item>
                </Col>
                <Col span="2" />
                <Col span="10">
                  <Form.Item name="aa" label="">
                    <span className="bam--reservation-description">
                      <InputNumber
                        controls="false"
                        placeholder="AA"
                        className="bam--input-number"
                        picker="month"
                        min={21}
                      />
                    </span>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="cvc"
              label="CVC"
              className="bam--label-form"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Input type="number" className="bam--input" placeholder="CVC" />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "2vh", textAlign: "center" }}
            className="bam--button-carrousel-text"
            htmlType="submit"
            onClick={() => checkout(form.getFieldsValue(), value)}
          >
            Confirmer le paiement
          </Button>
          <Button
            style={{ marginTop: "2vh", textAlign: "center", marginLeft: "2vh" }}
            className="bam--button-logout "
            danger
            htmlType="submit"
            onClick={() => {
              cookie.remove("token", { path: "/" });
              history.push("/");
            }}
          >
            Déconnexion
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Paiement;
