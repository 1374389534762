import moment from "moment";
import localization from "moment/locale/fr";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getClients } from "../ProfBeaute/APIs";
import ReactGA from "react-ga";

moment.updateLocale("fr", localization);

const MesClients = ({}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getListClients();
  }, []);
  const getListClients = () => {
    getClients().then((res) => {
      setData(res);
    });
  };
  const columns = [
    {
      name: "Nom du client",
      selector: "firstName",
      sortable: true,
      format: (row) => row.firstName + " " + row.lastName,
    },

    {
      name: "Téléphone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Nombre de réservations",
      selector: "number",
      sortable: true,
    },
    {
      name: "Date dernière réservation",
      selector: "lastReservation",
      sortable: true,
      format: (row) => moment(row.lastReservation).format("D MMMM YYYY"),
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    cells: {
      style: {
        fontSize: "1rem",
      },
    },
  };
  return (
    <DataTable
      striped
      customStyles={customStyles}
      title="Liste des clients"
      columns={columns}
      data={data}
      fixedHeader
      noDataComponent={<span>Pas de clients trouvés</span>}
    />
  );
};
export default MesClients;
