import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import history from "../../utils/History";
import LeftNavUser from "./LeftNavUser";
import Reservations from "./Reservations";

const EspaceUser = ({ location, ...props }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/espaceuser/**"
          exact={true}
          render={({ location }) => (
            <LeftNavUser>
              <PrivateRoute exact path="/espaceuser/">
                <Reservations />
              </PrivateRoute>
            </LeftNavUser>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default EspaceUser;
