import React, { useEffect, useState } from "react";
import { Row, Col, BackTop } from "antd";
import up from "../Styles/images/up.svg";
import CGUClient from "./CGUClient";
import CGUProf from "./CGUProf";
import ReactGA from "react-ga";

const style = {
  height: 44,
  width: 44,
  lineHeight: "40px",
  borderRadius: 6,
  backgroundColor: "#52C99F",
  textAlign: "center",
};
const CGU = () => {
  const [cgClient, setCGUClient] = useState(true);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.scroll(0, 0);
  }, []);
  return (
    <>
      {/* <Row style={{ marginTop: "2vh" }}>
        <Col xs={24} md={7} xl={7}>
        </Col>
      </Row> */}

      <div
        style={{ cursor: "pointer", textAlign: "center", marginTop: "2vh" }}
        onClick={() => setCGUClient(true)}
      >
        <span
          className="bam--section-title-first"
          style={{ marginLeft: "2vh" }}
        >
          Pour les clients:
        </span>

        <span
          className="bam--section-title"
          style={{ marginLeft: "10px", textDecoration: "underline" }}
        >
          Conditions Générales d'Utilisations
        </span>
      </div>

      <br />

      <div
        style={{ cursor: "pointer", textAlign: "center" }}
        onClick={() => setCGUClient(false)}
      >
        <span
          className="bam--section-title-first"
          style={{ marginLeft: "2vh" }}
        >
          Pour les professionnels:
        </span>
        <span
          className="bam--section-title"
          style={{ marginLeft: "10px", textDecoration: "underline" }}
        >
          Conditions Générales de Partenariat
        </span>
      </div>
      <hr style={{ marginTop: "6vh" }} />
      <div style={{ marginTop: "4vh" }}>
        {cgClient ? <CGUClient /> : <CGUProf />}
      </div>

      <BackTop>
        <div style={style}>
          <img src={up} />
        </div>
      </BackTop>
    </>
  );
};

export default CGU;
