import { Button, Switch } from "antd";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { changeStatusUser, getAllBeautyPro } from "../ProfBeaute/APIs";

const count = 3;
const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat&noinfo`;

const MesClients = ({}) => {
  const changeStatus = (id, active) => {
    changeStatusUser({
      id: id,
      active: active == 0 ? 1 : 0,
    }).then((res) => {
      getListBeautyPro();
    });
  };
  const columns = [
    {
      name: "Nom de l'entreprise",
      selector: "companyName",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: "adress",
      sortable: true,
    },
    {
      name: "Responsable",
      selector: "responsable",
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: "tel",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },

    {
      name: "Dernier paiement",
      selector: "lastPaiement",
      sortable: true,
    },
    {
      name: "Type d'abonnement",
      selector: "abonement",
      sortable: true,
      format: (row) =>
        `${
          row.abonnement == "m"
            ? "mensuel"
            : row.abonnement == "a"
            ? "annuel"
            : ""
        }`,
    },
    {
      name: "Date de création",
      selector: "createdAt",
      format: (row) => `${row.createdAt}`,
    },
    {
      cell: (p) => (
        <Switch
          primary={!p.active}
          onClick={() => {
            changeStatus(p.id, p.active);
          }}
          danger={p.active}
          checked={p.active}
        ></Switch>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
    },
    cells: {
      style: {
        fontSize: "1rem",
      },
    },
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    getListBeautyPro();
  }, []);
  const getListBeautyPro = () => {
    getAllBeautyPro().then((res) => {
      setData(res);
    });
  };

  return (
    <DataTable
      striped
      customStyles={customStyles}
      title="Liste des professionnels de beauté"
      columns={columns}
      data={data}
      fixedHeader
      noDataComponent={<span>Pas des professionnels de beauté inscrits</span>}
    />
  );
};
export default MesClients;
