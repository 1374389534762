import { Card, Col, DatePicker, Input, Row, Select, TreeSelect } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import coiffure from "../Styles/images/coiffure.svg";
import elle from "../Styles/images/elle.svg";
import icon1 from "../Styles/images/icon1.svg";
import icon2 from "../Styles/images/icon2.svg";
import icon3 from "../Styles/images/icon3.svg";
import icon4 from "../Styles/images/icon4.svg";
import insta1 from "../Styles/images/insta1.svg";
import insta2 from "../Styles/images/insta2.svg";
import insta3 from "../Styles/images/insta3.svg";
import luxury from "../Styles/images/luxury.svg";
import maquillage from "../Styles/images/maquillage.svg";
import massage from "../Styles/images/massage.svg";
import ongles from "../Styles/images/ongles.svg";
import parisien from "../Styles/images/parisien.svg";
import soins from "../Styles/images/soins.svg";
import vogue from "../Styles/images/vogue.svg";
import search from "../Styles/images/search.svg";
import { getActifPrestations } from "./ProfBeaute/APIs";
import { errorNotification } from "../utils/Utils";
import history from "../utils/History";
import Autocomplete from "react-google-autocomplete";
import ReactGA from "react-ga";

const { TreeNode } = TreeSelect;

const Home = ({ UserStore }) => {
  const [prestations, setPrestations] = useState([]);
  const [date, setDate] = useState(-1);
  const [department, setDepartment] = useState(-1);
  const [selectedId, setSelectedId] = useState(-1);
  const [category, setCategory] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getListOfPrestations();
  }, []);
  const getListOfPrestations = () => {
    getActifPrestations().then((res) => {
      setPrestations(res);
    });
  };

  const comment = (
    <div style={{ marginTop: "4vh" }}>
      <Row style={{ textAlign: "center" }}>
        <Col span="24">
          <span className="bam--home-title"> Comment ça marche ?</span>
        </Col>
      </Row>

      <Row style={{ marginTop: "2vh", textAlign: "center" }}>
        <Col xs={2} xl={3} />

        <Col xs={20} xl={8}>
          <Card className="bam--card-comment" style={{ marginTop: "2vh" }}>
            <div style={{ textAlign: "center" }}>
              <div>
                <img src={icon1} />
              </div>
              <p className="bam--title-comment">
                {" "}
                1. Choississez votre prestation
              </p>
              <p className="bam--description-comment">
                Choisissez votre manucure, soin, coiffure à domicile !
              </p>
            </div>
          </Card>
        </Col>
        <Col xs={2} xl={2}></Col>
        <Col xs={2} xl={0}></Col>
        <Col xs={20} xl={8}>
          <Card className="bam--card-comment" style={{ marginTop: "2vh" }}>
            <div style={{ textAlign: "center" }}>
              <div>
                <img src={icon2} />
              </div>
              <p className="bam--title-comment">
                {" "}
                2. Sélectionnez une date et un créneau
              </p>
              <p className="bam--description-comment">
                Indiquez un créneau et nous vous proposerons les professionnels
                disponibles.
              </p>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "1vh" }}>
        <Col xs={2} xl={3} />
        <Col xs={20} xl={8}>
          <Card className="bam--card-comment" style={{ marginTop: "2vh" }}>
            <div style={{ textAlign: "center" }}>
              <div>
                <img src={icon3} />
              </div>
              <p className="bam--title-comment">
                {" "}
                3. Choisissez un professionnel
              </p>
              <p className="bam--description-comment">
                Les professionnels confirment en quelques heures ou nous vous
                proposerons des alternatives.
              </p>
            </div>
          </Card>
        </Col>
        <Col xs={2} xl={0}></Col>
        <Col xs={2} xl={2}></Col>{" "}
        <Col xs={20} xl={8}>
          <Card className="bam--card-comment" style={{ marginTop: "2vh" }}>
            <div style={{ textAlign: "center" }}>
              <div>
                <img src={icon4} />
              </div>
              <p className="bam--title-comment">4. Soyez chouchouté</p>
              <p className="bam--description-comment">
                Recevez votre confirmation de réservation par mail. <br />
                Le paiement est géré en ligne !
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );

  const parles = (
    <div style={{ marginTop: "4vh" }}>
      <div className="bam--parles">
        <Row style={{ textAlign: "center", paddingTop: "2vh" }}>
          <Col span="24">
            <span className="bam--home-title" style={{ color: "#fff" }}>
              {" "}
              Ils parlent de nous
            </span>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5vh", textAlign: "center" }}>
          <Col xl={6} xs={10} style={{ marginTop: "5vh" }}>
            <img style={{ width: "90%" }} src={elle} />
          </Col>
          <Col xl={0} xs={2} />

          <Col xl={6} xs={10} style={{ marginTop: "5vh" }}>
            <img style={{ width: "90%" }} src={parisien} />
          </Col>

          <Col xl={6} xs={10} style={{ marginTop: "5vh" }}>
            <img style={{ width: "90%" }} src={luxury} />
          </Col>
          <Col xl={0} xs={1} />

          <Col
            style={{ width: "90%" }}
            xl={6}
            xs={10}
            style={{ marginTop: "5vh" }}
          >
            <img src={vogue} />
          </Col>
        </Row>
      </div>
    </div>
  );
  const instagram = (
    <a target={"_blank"} href="https://www.instagram.com/bam.france/">
      <div style={{ marginTop: "4vh" }}>
        <Row style={{ textAlign: "center", marginTop: "5vh" }}>
          <Col span="24">
            <span className="bam--home-title"> Suivez nous sur Instagram</span>
          </Col>
        </Row>
        <Row style={{ paddingTop: "2vh", textAlign: "center" }}>
          <Col xs={2} xl={2}></Col>
          <Col xl={6} xs={10} style={{ marginTop: "2vh" }}>
            <img style={{ width: "100%", height: "100%" }} src={insta1} />
          </Col>
          <Col xs={2} xl={1}></Col>

          <Col xl={6} xs={10} style={{ marginTop: "2vh" }}>
            <img style={{ width: "100%", height: "100%" }} src={insta2} />
          </Col>
          <Col xs={2} xl={1}></Col>

          <Col xl={6} xs={10} style={{ marginTop: "2vh" }}>
            <img style={{ width: "100%", height: "100%" }} src={insta3} />
          </Col>
          <Col xs={12} xl={2}></Col>
        </Row>
      </div>
    </a>
  );
  const services = (
    <div style={{ marginTop: "4vh" }}>
      <Row style={{ textAlign: "center", marginTop: "5vh" }}>
        <Col span="24">
          <span className="bam--home-title">Les services BAM</span>
        </Col>
      </Row>
      <Row
        style={{
          textAlign: "center",
          marginTop: "2vh",
        }}
      >
        <Col span="2" />
        <Col span="20">
          {" "}
          <Slider {...settings}>
            <div>
              <div>
                <img className="bam--img-service" src={ongles} />
              </div>
              <div style={{ marginTop: "2vh" }}>
                <span className="bam--title-services">Ongles</span>
              </div>
            </div>

            <div>
              <div>
                <img src={maquillage} />
              </div>
              <div style={{ marginTop: "2vh" }}>
                <span className="bam--title-services">Maquillage</span>
              </div>
            </div>
            <div>
              <div>
                <img src={coiffure} />
              </div>
              <div style={{ marginTop: "2vh" }}>
                <span className="bam--title-services">Coiffure</span>
              </div>
            </div>
            <div>
              <div>
                <img src={massage} />
              </div>
              <div style={{ marginTop: "2vh" }}>
                <span className="bam--title-services">Massage</span>
              </div>
            </div>
            <div>
              <div>
                <img src={soins} />
              </div>
              <div style={{ marginTop: "2vh" }}>
                <span className="bam--title-services">Soins du corps</span>
              </div>
            </div>
          </Slider>
        </Col>
        <Col span="2" />
      </Row>{" "}
    </div>
  );
  const onChange = (e) => {
    var index = 0;
    if (e.includes("child")) {
      var i = e.indexOf("$") + 1;
      index = e.substring(i);
      setCategory(false);
    } else {
      var i = e.indexOf("$") + 1;
      index = e.substring(i);
      setCategory(true);
    }
    setSelectedId(index);
  };
  const toGetResults = () => {
    if (selectedId == -1 || date == -1 || department == -1) {
      errorNotification("Erreur", "merci de remplir tout les champs!");
      return;
    }
    history.push({
      pathname: "/results",
      state: {
        selectedId: selectedId,
        date: date,
        department: department,
        category: category,
      },
    });
  };
  return (
    <>
      <div className="bam--slider">
        <Row style={{ paddingTop: "6vh", marginLeft: "0vh" }}>
          <Col xs={1} xl={1} />

          <Col xs={23} xl={12}>
            <span className="bam--slider-title">
              Réservez votre prestation beauté à domicile
            </span>
          </Col>
        </Row>
        <Row style={{ paddingTop: "4vh", marginLeft: "0vh" }}>
          <Col xs={1} xl={1} />
          <Col xs={22} xl={14}>
            <Card className="bam--card-home">
              <Row>
                <Col xl={7} md={7} xs={24} className="bam--border-right">
                  <span className="bam--reservation-title">Prestations</span>

                  <p>
                    <span className="bam--reservation-description">
                      <TreeSelect
                        showSearch
                        style={{ width: "95%" }}
                        // value={value}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Que cherchez vous?"
                        allowClear
                        onChange={onChange}
                      >
                        {prestations.map((p, i) => (
                          <TreeNode
                            value={p.name + "parent$" + p.id}
                            title={p.name}
                          >
                            {p.list.map((el) => (
                              <TreeNode
                                value={el.name + "child$" + el.id}
                                title={el.name}
                              />
                            ))}
                          </TreeNode>
                        ))}
                      </TreeSelect>
                    </span>
                  </p>
                </Col>
                <Col xl={7} md={7} xs={24} className="bam--border-right">
                  <span className="bam--home-left-margin">
                    <span className="bam--reservation-title">Lieu</span>
                  </span>
                  <p className="bam--home-left-margin">
                    <Autocomplete
                      apiKey={"AIzaSyAJoTMHZJj9lbSPYNBL7UgxeNlVpgjDvc0"}
                      placeholder="A quelle adresse ?"
                      className="ant-input"
                      options={{ types: ["establishment", "geocode"] }}
                      style={{ width: "95%" }}
                      onPlaceSelected={(place) => {
                        setDepartment(place.formatted_address);
                      }}
                    />
                  </p>
                </Col>

                <Col xl={7} md={7} xs={24}>
                  <span className="bam--home-left-margin">
                    <span className="bam--reservation-title">Date</span>
                  </span>
                  <p className="bam--home-left-margin">
                    <span className="bam--reservation-description">
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        suffixIcon={null}
                        onChange={(e, date) => setDate(date)}
                        style={{ width: "95%" }}
                        placeholder="Choisissez votre date"
                      />
                    </span>
                  </p>
                </Col>
                <Col xl={3} md={3} xs={24} style={{ textAlign: "right" }}>
                  <p style={{ width: "95%", paddingTop: "-10px" }}>
                    <img
                      src={search}
                      className="bam-img-search"
                      onClick={() => toGetResults()}
                    />
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      {comment}
      {services}
      {/* {parles} */}
      {instagram}
    </>
  );
};

export default inject("UserStore")(observer(Home));
