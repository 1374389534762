import { Col, Layout, Row } from "antd";
import React from "react";
import Navigation from "../components/Navigation";
import bamBig from "../Styles/images/bam_big.svg";
import footerPoints from "../Styles/images/footer-points.svg";
import history from "../utils/History";

const { Header, Content, Footer } = Layout;

const customFooter = (
  <>
    <Row>
      <Col xl={12} md={12} xs={24}>
        <div className="bam--copyright-div">
          <img className="bam--copyright-img" src={bamBig} />

          <span className="bam--copyright">
            Copyright © 2021 Bam, tous droits réservés.
          </span>
        </div>
      </Col>
      <Col xl={6} style={{ display: "flex" }}></Col>
    </Row>
    {/* <Row>
      <Col xl={12} md={12} xs={24} style={{ marginLeft: "2vh" }}>
        <span className="bam--text-footer"> beauté à la maison</span>
      </Col>
      <Col xl={6} style={{ display: "flex" }}></Col>
    </Row> */}
    <Row style={{ marginTop: "2vh" }}>
      <Col xs={24} xl={16} md={16}>
        <div>
          <span
            className="bam--title-footer"
            onClick={() => history.push("/about")}
          >
            À propos de Bam
          </span>
        </div>
        <div>
          <span
            className="bam--title-footer"
            onClick={() => history.push("/faq")}
          >
            FAQ
          </span>
        </div>
        <div>
          <span
            className="bam--title-footer"
            onClick={() => history.push("/cgu")}
          >
            Mentions Légales
          </span>
        </div>
      </Col>

      <Col xs={0} xl={6} md={6} style={{ textAlign: "right" }}>
        <img src={footerPoints} />
      </Col>
    </Row>
    {/* <Row>
      <Col xl={22}>
        <span className="bam--mini-title" onClick={() => history.push("/faq")}>
          FAQ |
        </span>
        <span className="bam--mini-title" onClick={() => history.push("/cgu")}>
          {" "}
          Conditions générales d'utilisations
        </span>
        {/* <span className="bam--mini-title"> Cookies</span> *
      </Col>
    </Row> */}
  </>
);

const TopNavLayout = ({ location, children, props }) => {
  return (
    <Layout>
      {location.pathname.includes("/espacepro/") ||
      location.pathname.includes("/espaceadmin/") ||
      location.pathname.includes("/espaceuser/") ? (
        <></>
      ) : (
        <Header style={{ padding: 0, backgroundColor: "#FFF" }}>
          <Navigation
            isProfile={location.pathname.includes("/profil")}
            mode="horizontal"
          />
        </Header>
      )}
      <Layout style={{ flex: 1 }}>
        <Content>{children}</Content>
      </Layout>
      {location.pathname.includes("/espacepro/") ||
      location.pathname.includes("/espaceadmin/") ||
      location.pathname.includes("/espaceuser/") ? (
        <></>
      ) : (
        <Footer style={{ marginTop: "3vh" }}>{customFooter}</Footer>
      )}
    </Layout>
  );
};

export default TopNavLayout;
