import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/fr_FR";
import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import Entreprise from "./Entreprise";
import HistoryPaiement from "./HistoryPaiement";
import Home from "./Home";
import MesAvis from "./MesAvis";
import MesClients from "./MesClients";
import MesPrestations from "./MesPrestations";
import MesReservations from "./MesReservations";
import Agenda from "./MonAgenda";
import Photo from "./Photos";
const Nav = ({ location, ...props }) => {
  return (
    <Switch>
      <ConfigProvider locale={locale}>
        <PrivateRoute exact path="/espacepro/">
          <Home />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/entreprise">
          <Entreprise />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/mes-prestations">
          <MesPrestations />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/clients">
          <MesClients />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/reservations">
          <MesReservations />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/agenda">
          <Agenda />
        </PrivateRoute>

        <PrivateRoute exact path="/espacepro/avis">
          <MesAvis />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/photo">
          <Photo />
        </PrivateRoute>
        <PrivateRoute exact path="/espacepro/paiement">
          <HistoryPaiement />
        </PrivateRoute>
      </ConfigProvider>
    </Switch>
  );
};
export default Nav;
