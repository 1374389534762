import { notification } from "antd";
import moment from "moment";

const key = "updatable";

export const openNotification = (message, description) => {
  notification.success({
    key,
    message: message,
    description: description,
  });
};

export const errorNotification = (message, description) => {
  notification.error({
    key,
    message: message,
    description: description,
  });
};

export const getDayNameAndDay = (date) => {
  moment.updateLocale("fr", "localization_fr");
  var date = moment(date);
  return date.format("Do MMMM Y");
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
