import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import history from "../../utils/History";
import LeftNavAdmin from "./LeftNavLayout";
import NavAdmin from "./NavAdmin";

const EspaceAdmin = ({ location, ...props }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/espaceadmin/**"
          exact={true}
          render={({ location }) => (
            <LeftNavAdmin>
              <NavAdmin location={location} />
            </LeftNavAdmin>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default EspaceAdmin;
